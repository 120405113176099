import React, { useContext, useEffect, useState } from 'react';
import { ButtonSpinner } from '../../components/Elements';
import { validateForm, toastNotify, passwordValidate, intlTel_phone, initialFormState } from '../../components/Helper';
import { fetchData, UPDATE_ADMIN_PROFILE, ADMIN_CHANGE_PASSWORD } from '../../components/Service';
import { now } from "lodash";
import { Context } from "../../components/Context"

function AccountSetting() {
    window.document.title = "User Account";
    const [refresh, setRefresh] = useState(now())
    const [context] = useContext(Context)
    const [tinyloader, setTinyloader] = useState(false);
    const [strongPassword, setStrongPassword] = useState(false);
    const [intlTel, setIntlTel] = useState()
    const [data, setData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
    })

    const updateProfile = (e) => {
        let form = document.getElementById('profileForm')
        let data = new FormData(form);
        if (validateForm(e, 'profileForm')) {
            setTinyloader(true);
            fetchData(UPDATE_ADMIN_PROFILE, 'POST', data, true, true, (res) => {
                setTinyloader(false);
                if (res.success) {
                    form.classList.remove('was-validated')
                }
            });
        };
    }

    useEffect(() => {
        setIntlTel(intlTel_phone('#phone'))
        fetchData(UPDATE_ADMIN_PROFILE, 'GET', '', true, false, (res) => {
            if (res.records) {
                setData({
                    first_name: res.records.first_name,
                    last_name: res.records.last_name,
                    email: res.records.email,
                    phone: res.records.phone,
                })
            }
        });

    }, [refresh])

    const changePassword = (e) => {
        e.preventDefault();
        if (validateForm(e, "changepasswordForm")) {
            let form = document.getElementById('changepasswordForm')
            let data = new FormData(form);
            if (strongPassword) {
                let new_password = document.getElementById('new_password').value
                let confirm_password = document.getElementById('confirm_password').value
                if (new_password === confirm_password) {
                    setTinyloader(true);
                    fetchData(ADMIN_CHANGE_PASSWORD, 'POST', data, true, true, (res) => {
                        setTinyloader(false);
                        if (res.success) {
                            form.classList.remove('was-validated')
                            initialFormState('changepasswordForm', data)
                            setRefresh(now);
                        }
                    });
                } else {
                    let msg = "Password And Confirm Password Does Not Match";
                    toastNotify({ 'error': msg })
                }
            } else {
                let msg = "Your password should be minimum 8 characters and includes at least a number, uppercase and lower case letter and 1 symbol";
                toastNotify({ 'error': msg })
            }
        };
    }


    return (
        <section className="admin-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col mb-2 text-center text-md-start ms-xl-0 ms-md-4">
                        <h4 className="page-heading">Account Setting</h4>
                    </div>
                </div>
                <div className="row justify-content-center mt-3">
                    <div className="col-md-12 col-sm-11 col-lg-12 col-xl-12">
                        <ul className="nav nav-pills tabs-common" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="active btn" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Profile Update</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="btn" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Password Update</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="btn" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Active Plan</button>
                            </li>
                        </ul>
                        <div className="tab-content border p-4 rounded mt-2" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                <form className="row needs-validation" id="profileForm" noValidate>
                                    <div className="row mb-3">
                                        <label htmlFor="first_name" className="col-md-4 col-xxl-3 col-form-label text-start text-md-end">First Name
                                            <small className="text-danger">*</small>:
                                        </label>
                                        <div className="col-md-6">
                                            <input type="text" className="form-control" minLength={2} name="first_name" id="first_name" placeholder="First Name" autoComplete="Off" spellCheck={false} defaultValue={data.first_name} required />
                                            <div className="invalid-feedback">The first name field is required.</div>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label htmlFor="last_name" className="col-md-4 col-xxl-3 col-form-label text-start text-md-end">Last Name
                                            <small className="text-danger">*</small>:
                                        </label>
                                        <div className="col-md-6">
                                            <input type="text" className="form-control" minLength={2} name="last_name" id="last_name" placeholder="Last Name" autoComplete="Off" spellCheck={false} defaultValue={data.last_name} required />
                                            <div className="invalid-feedback">The last name field is required.</div>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label htmlFor="email_address" className="col-md-4 col-xxl-3 col-form-label text-start text-md-end">Email Address
                                            <small className="text-danger">*</small>:
                                        </label>
                                        <div className="col-md-6">
                                            <input type="email" className="form-control" name="email_address*" id="email_address" placeholder="Email Address" readOnly autoComplete="Off" defaultValue={data.email} spellCheck={false} />
                                            <div className="invalid-feedback">The email field is required.</div>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label htmlFor="phone" className="col-md-4 col-xxl-3 col-form-label text-start text-md-end">Phone
                                            <small className="text-danger">*</small>:
                                        </label>
                                        <div className="col-md-6 intl_phone">
                                            <input type="phone" className="form-control" name="phone" placeholder="Phone" id="phone" autoComplete="Off" spellCheck={false} defaultValue={data.phone ? data.phone : ''} required />
                                            <div className="invalid-feedback">The phone field is required.</div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 offset-md-4 offset-xxl-3">
                                            {context && context.auth && (context.auth.user_group_id === 3 || (context.auth.user_group_id === 1 && context.auth.super_admin === 'no')) && context.auth.permission ? context.auth.permission.map((ele, num) => {
                                                return (ele.permission === 'Profile' && ele.edit === 'yes' &&
                                                    <button type="button" key={num} className="btn btn-dark px-4" onClick={(e) => updateProfile(e)} disabled={tinyloader}>
                                                        <ButtonSpinner load={tinyloader} btnName="Save" />
                                                    </button>
                                                )
                                            })
                                                : <button type="button" className="btn btn-dark px-4" onClick={(e) => updateProfile(e)} disabled={tinyloader}>
                                                    <ButtonSpinner load={tinyloader} btnName="Save" />
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </form>
                                <div className="alert alert-info mt-3"><strong>Note:</strong> Please call your account representative at <strong>972-351-4200</strong> or email at <strong>info@amazio.com</strong> to change your email address.</div>
                                
                            </div>

                            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                <form className="needs-validation" id="changepasswordForm" noValidate>
                                    <div className="row mb-3">
                                        <label htmlFor="current_password" className="col-md-4 col-xxl-3 col-form-label text-start text-md-end">Current Password
                                            <small className="text-danger">*</small>:
                                        </label>
                                        <div className="col-sm-6">
                                            <input type="password" className="form-control" minLength={8} maxLength={16} name="current_password" id="current_password" placeholder="Current Password" autoComplete="Current Password" spellCheck={false} required />
                                            <div className="invalid-feedback">The current password field is required.</div>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label htmlFor="new_password" className="col-md-4 col-xxl-3 col-form-label text-start text-md-end">New Password
                                            <small className="text-danger">*</small>:
                                        </label>
                                        <div className="col-sm-6">
                                            <input type="password" className="form-control" minLength={8} maxLength={16} onKeyUp={(e) => passwordValidate(e, setStrongPassword)} name="new_password" id='new_password' placeholder="New Password" autoComplete="New Password" spellCheck={false} required />
                                            <ul className="passhints">
                                                <li>Be at least 6 character.</li>
                                                <li>At least 1 small letter.</li>
                                                <li>At least 1 capital letter.</li>
                                                <li>At least 1 number.</li>
                                                <li>At least 1 special character.</li>
                                            </ul>
                                            <div className="invalid-feedback">The new password field is required.</div>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label htmlFor="confirm_password" className="col-md-4 col-xxl-3 col-form-label text-start text-md-end">New Confirm Password
                                            <small className="text-danger">*</small>:
                                        </label>
                                        <div className="col-sm-6">
                                            <input type="password" className="form-control" minLength={8} maxLength={16} name="confirm_password" id="confirm_password" placeholder="New Confirm Password" autoComplete="New Confirm Password" spellCheck={false} required />
                                            <div className="invalid-feedback">The confirm password field is required.</div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 offset-md-4 offset-xxl-3">
                                            {context && context.auth && (context.auth.user_group_id === 3) && context.auth.permission ? context.auth.permission.map((ele, num) => {
                                                return (ele.permission === 'Profile' && ele.edit === 'yes' &&
                                                    <button type="button" key={num} className="btn btn-dark px-4" onClick={(e) => changePassword(e)} disabled={tinyloader}>
                                                        <ButtonSpinner load={tinyloader} btnName="Update" />
                                                    </button>
                                                )
                                            })
                                                : <button type="button" className="btn btn-dark px-4" onClick={(e) => changePassword(e)} disabled={tinyloader}>
                                                    <ButtonSpinner load={tinyloader} btnName="Update" />
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                <div className="row">
                                    <div className="alert alert-secondary pb-4 col-sm-9" role="alert">
                                        <i className="bi bi-arrow-right pe-2"></i>Business Digital Listing
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AccountSetting