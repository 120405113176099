import * as Elements from "../../components/Elements";

function Websites() {
    window.document.title = "Amazio | App Development";

    return (
        <>
            <Elements.PageTitleSection title="Web & App Development with Maintenance"/>

            <section className="py-5 container">
                <div className="row">
                    <div className="col-sm-12">
                        <p className="lh-md mb-4">
                            We organically & strategically design and develop websites that elevate your brand and communicate your intent effectively. We go a step further without stopping at just building a website. We coordinate with you to continuously evolve your online presence on both web and mobile apps.
                        </p>
                        <h4 className="fw-bolder mb-4">Individual Websites</h4>
                        <div className="row g-4">
                            <div className="col-12 col-sm-6 col-md-4">
                                <a href="https://amazio.live/CEDtemplates/template-v1/" rel="noopener noreferrer" target="_blank" className="text-decoration-none">
                                    <div className="web-template-ds01">
                                        <img src="images/templates/template-new-1.png" alt="template-1" style={{bottom:"-817px"}} />
                                    </div>
                                    <h6 className="py-3 text-center fs-5 fw-bolder">Template - 1</h6>
                                </a>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <a href="https://amazio.live/CEDtemplates/template-v2/" rel="noopener noreferrer" target="_blank" className="text-decoration-none">
                                    <div className="web-template-ds01">
                                        <img src="images/templates/template-new-2.png" alt="template-2" style={{bottom:"-1210px"}} />
                                    </div>
                                    <h6 className="py-3 text-center fs-5 fw-bolder">Template - 2</h6>
                                </a>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <a href="https://amazio.live/CEDtemplates/template-v3/" rel="noopener noreferrer" target="_blank" className="text-decoration-none">
                                    <div className="web-template-ds01">
                                        <img src="images/templates/template-new-3.png"  alt="template-3" style={{bottom:"-772px"}} />
                                    </div>
                                    <h6 className="py-3 text-center fs-5 fw-bolder">Template - 3</h6>
                                </a>
                            </div>


                            

                            <div className="col-12 col-sm-6 col-md-4">
                                <a href="https://amazio.live/CEDtemplates/template-v6" rel="noopener noreferrer" target="_blank" className="text-decoration-none">
                                    <div className="web-template-ds01">
                                        <img src="images/templates/template-new-6.png" alt="template-4" style={{bottom:"-1050px"}} />
                                    </div>
                                    <h6 className="py-3 text-center fs-5 fw-bolder">Template - 4</h6>
                                </a>
                            </div>
                            
                            <div className="col-12 col-sm-6 col-md-4">
                                <a href="https://amazio.live/CEDtemplates/template-v7" rel="noopener noreferrer" target="_blank" className="text-decoration-none">
                                    <div className="web-template-ds01">
                                        <img src="images/templates/template-new-7.png" alt="template-5" style={{bottom:"-715px"}} />
                                    </div>
                                    <h6 className="py-3 text-center fs-5 fw-bolder">Template - 5</h6>
                                </a>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <a href="https://amazio.live/CEDtemplates/template1" rel="noopener noreferrer" target="_blank" className="text-decoration-none">
                                    <div className="web-template-ds01">
                                        <img src="images/templates/template-old-1.png" alt="template-6" style={{bottom:"-568px"}} />
                                    </div>
                                    <h6 className="py-3 text-center fs-5 fw-bolder">Template - 6</h6>
                                </a>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <a href="https://amazio.live/CEDtemplates/template2" rel="noopener noreferrer" target="_blank" className="text-decoration-none">
                                    <div className="web-template-ds01">
                                        <img src="images/templates/template-old-2.png" alt="template-7" style={{bottom:"-452px"}} />
                                    </div>
                                    <h6 className="py-3 text-center fs-5 fw-bolder">Template - 7</h6>
                                </a>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <a href="https://amazio.live/CEDtemplates/template3" rel="noopener noreferrer" target="_blank" className="text-decoration-none">
                                    <div className="web-template-ds01">
                                        <img src="images/templates/template-old-3.png" alt="template-8" style={{bottom:"-505px"}} />
                                    </div>
                                    <h6 className="py-3 text-center fs-5 fw-bolder">Template - 8</h6>
                                </a>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <a href="https://amazio.live/CEDtemplates/template5" rel="noopener noreferrer" target="_blank" className="text-decoration-none">
                                    <div className="web-template-ds01">
                                        <img src="images/templates/template-old-5.png" alt="template-9" style={{bottom:"-653px"}} />
                                    </div>
                                    <h6 className="py-3 text-center fs-5 fw-bolder">Template - 9</h6>
                                </a>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <a href="https://amazio.live/CEDtemplates/template4/" rel="noopener noreferrer" target="_blank" className="text-decoration-none">
                                    <div className="web-template-ds01">
                                        <img src="images/templates/template-old-4.png" alt="template-10" style={{bottom:"-445px"}} />
                                    </div>
                                    <h6 className="py-3 text-center fs-5 fw-bolder">Template - 10</h6>
                                </a>
                            </div>

                            <div className="col-12 col-sm-6 col-md-4">
                                <a href="https://amazio.live/CEDtemplates/template11/" rel="noopener noreferrer" target="_blank" className="text-decoration-none">
                                    <div className="web-template-ds01">
                                        <img src="images/templates/template-new-8.png" alt="template-11" style={{bottom:"-566px"}} />
                                    </div>
                                    <h6 className="py-3 text-center fs-5 fw-bolder">Template - 11</h6>
                                </a>
                            </div>

                           

                        </div>
                        <hr className="opacity-10 my-4"/>
                        <h4 className="fw-bolder pt-2 mb-4">Hub Websites</h4>
                        <div className="row g-4">
                            <div className="col-12 col-sm-6 col-md-4">
                                <a href="https://amazio.live/CEDtemplates/template-v4" rel="noopener noreferrer" target="_blank" className="text-decoration-none">
                                    <div className="web-template-ds01">
                                        <img src="images/templates/template-new-4.png" alt="template-12" style={{bottom:"-1088px"}} />
                                    </div>
                                    <h6 className="py-3 text-center fs-5 fw-bolder">Template - 12</h6>
                                </a>
                            </div>

                            <div className="col-12 col-sm-6 col-md-4">
                                <a href="https://amazio.live/CEDtemplates/template-v5" rel="noopener noreferrer" target="_blank" className="text-decoration-none">
                                    <div className="web-template-ds01">
                                        <img src="images/templates/template-new-5.png" alt="template-13" style={{bottom:"-778px"}} />
                                    </div>
                                    <h6 className="py-3 text-center fs-5 fw-bolder">Template - 13</h6>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Websites