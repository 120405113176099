import React, { useState, useEffect, useContext } from "react";
import { fetchData, GET_LOCATION_UNIQUE_URL, GET_REVIEW_COUNT, GET_SEARCH_REPORT } from "../../components/Service";
import { Link } from "react-router-dom";
import ChooseLocation from "../../components/ChooseLocation";
import ReviewPieChart from "../../components/ReviewPieChart"
import RatingBackDrops from "../../components/RatingBackDrops"
import { Context } from '../../components/Context';

function ReputationDashboard() {
    window.document.title = "Dashboard Admin";
    const [context] = useContext(Context)
    const [location, setLocation] = useState();
    const [records, setRecords] = useState(0)
    const [report, setReport] = useState(0)
    const [reviewList] = useState(GET_REVIEW_COUNT)
    const [searchReport] = useState(GET_SEARCH_REPORT)
    const [formfield, setFormfield] = useState({
        type: 'thirtDay',
        startDate: '',
        endDate: '',
    })

    useEffect(() => {
        fetchData(GET_LOCATION_UNIQUE_URL, 'GET', '', true, false, (res) => {
            if (res.records) {
                setLocation(res.records)
            }
        });
    }, [])

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2 text-center text-md-start ms-xl-0 ms-md-4">
                            <h4 className="page-heading">Admin Dashboard</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-auto dashboard-link">
                            {context && context.auth && context.auth.user_group_id === 1 && context.auth.super_admin === "yes" &&
                                <>
                                    <Link to="../admin-dashboard" className="admin-notification me-2 me-md-3 d-none d-sm-inline-flex">Overview</Link>
                                    <Link to="../dashboard-listing" className="admin-notification me-2 me-md-3 d-none d-sm-inline-flex"> Listings </Link>
                                    <Link to="../soci-dashboard" className="admin-notification me-2 me-md-3 d-none d-sm-inline-flex">Social insights</Link>
                                    <Link to="../reputation-dashboard" className="admin-notification me-2 me-md-3 main-link active" >Reputation</Link>
                                </>
                            }
                            {context && context.auth && (context.auth.user_group_id === 3 || (context.auth.user_group_id === 1 && context.auth.super_admin === "no")) && context.auth.permission ? context.auth.permission.map((ele, num) => {
                                return (<>
                                    {ele.permission === 'Common Dashboard' && ele.view === 'yes' ? <Link to="../admin-dashboard" className="admin-notification me-2 me-md-3 d-none d-sm-inline-flex">Overview</Link> : ''}
                                    {ele.permission === 'Listing Dashboard' && ele.view === 'yes' ? <Link to="../dashboard-listing" className="admin-notification me-2 me-md-3 d-none d-sm-inline-flex"> Listings </Link> : ''}
                                    {ele.permission === 'Social Media Dashboard' && ele.view === 'yes' ? <Link to="../soci-dashboard" className="admin-notification me-2 me-md-3 d-none d-sm-inline-flex">Social insights</Link> : ''}
                                    {ele.permission === 'Reputation Management Reviews' && ele.view === 'yes' ? <Link to="../reputation-dashboard" className="admin-notification me-2 me-md-3 d-none d-sm-inline-flex main-link active">Reputation</Link> : ''}
                                </>)
                            }) : ''
                            }
                        </div>
                    </div>
                    <div id="generatePDF">
                        <ChooseLocation
                            location={location}
                            setFormfield={setFormfield}
                            formfield={formfield}
                            single={false}
                            setRecords={setRecords}
                            setReport={setReport}
                            reviewList={reviewList}
                            searchReport={searchReport}
                        />

                        <div className="row g-4">
                            <div className="col">
                                <div className="card border-light shadow-sm border-0 h-100">
                                    <h5 className="card-header fw-bold">Your Share of Intelligent search is <strong id="card_header_per">{report && report.intelligent_list_per && report.intelligent_list_per.percentage ? report.intelligent_list_per.percentage : '0'} %</strong></h5>
                                    <div className="card-body">
                                        <div className="circle-data-graph">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-4 col-lg-3">
                                                    <div className="container-box p-5">
                                                        <svg viewBox="0 0 36 36" id="viewboxsvg">
                                                            <path style={{ fill: 'none', stroke: '#eee', strokeWidth: '3' }} d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                            <path style={{ fill: 'none', strokeWidth: '2', stroke: '#1591dd', strokeLinecap: 'round', transition: 'all 1s ease-out' }} id="fillStroke" strokeDasharray="0, 100" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                            <text x="18" y="20.35" style={{
                                                                fill: '#666', fontFamily: 'sans-serif', fontSize: '0.45em', textAlign: 'center',
                                                                textAnchor: 'middle'
                                                            }} id="percentageText">{report && report.intelligent_list_per && report.intelligent_list_per.percentage ? report.intelligent_list_per.percentage : '0'}%</text>
                                                        </svg>
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-md-8 col-lg-9">
                                                    <div className="data-graph p-3">
                                                        <div className="graph-box mt-4 mb-4 border-0 h-100">
                                                            <div className="percentage-bar" id="percentage_bar_list">
                                                                {report && report.intelligent_list && report.intelligent_list.length > 0 ? report.intelligent_list.map((ele, num) => {
                                                                    return (<React.Fragment key={num}>
                                                                        {ele.match_type === 'Listings' ?
                                                                            < div className="bar-green" data-hover="line-green" style={{ height: `${ele.total_match}%` }} data-percentage={`${ele.total_match}%`}></div>
                                                                            : ''}

                                                                        {ele.match_type === 'Local Pack' ?
                                                                            <div className="bar-perpol" data-hover="line-perpol" style={{ height: `${ele.total_match}%` }} data-percentage={`${ele.total_match}%`}></div>
                                                                            : ''}

                                                                        {ele.match_type === 'Location Pages' ?
                                                                            <div className="bar-gray" data-hover="line-gray" style={{ height: `${ele.total_match}%` }} data-percentage={`${ele.total_match}%`}></div>
                                                                            : ''}

                                                                        {ele.match_type === 'Corporate Website' ?
                                                                            <div className="bar-blue" data-hover="line-blue" style={{ height: `${ele.total_match}%` }} data-percentage={`${ele.total_match}%`}></div>
                                                                            : ''}
                                                                    </React.Fragment>)
                                                                }) : ''}
                                                            </div>

                                                            <ul className="graph-listing" id="listing_inte">
                                                                {
                                                                    report && report.intelligent_list && report.intelligent_list.length > 0 ? report.intelligent_list.map((ele, num) => {
                                                                        return (<>

                                                                            {ele.match_type === 'Listings' ?
                                                                                <li className="line-green">
                                                                                    <p data-percentage={`${ele.total_match}%`}>
                                                                                        <strong>Listing <small>Powered By Amazio Listings</small></strong>
                                                                                        Search results from your locations' listings
                                                                                    </p>
                                                                                </li>
                                                                                : ''}

                                                                            {ele.match_type === 'Local Pack' ?
                                                                                <li className="line-perpol">
                                                                                    <p data-percentage={`${ele.total_match}%`}>
                                                                                        <strong>Local Pack and Knowledge Card<small>Powered by Company</small></strong>
                                                                                        Search results that match your location in the Local Pack or in the Knowledge Card, including paid results
                                                                                    </p>
                                                                                </li>
                                                                                : ''}

                                                                            {ele.match_type === 'Location Pages' ?
                                                                                <li className="line-gray">
                                                                                    <p data-percentage={`${ele.total_match}%`}>
                                                                                        <strong>Location Pages</strong>
                                                                                        Search results that match your location website URL
                                                                                    </p>
                                                                                </li>
                                                                                : ''}

                                                                            {ele.match_type === 'Corporate Website' ?
                                                                                <li className="line-blue">
                                                                                    <p data-percentage={`${ele.total_match}%`}>
                                                                                        <strong>Corporate Website</strong>
                                                                                        Search results that match your corporate website domain
                                                                                    </p>
                                                                                </li>
                                                                                : ''}
                                                                        </>)
                                                                    })
                                                                        :
                                                                        <div className="p-2 text-secondary fs-6 fst-italic">There is no data in the range you have selected.<br />If your account is new, wait a few weeks for data to become available.<br />Otherwise, try changing your selections above.</div>
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row g-4 mt-2">
                            <div className="col">
                                <div className="card border-light shadow-sm border-0 h-100">
                                    <div className="card-header fw-bold">Review</div>
                                    <div className="card-body text-center">
                                        Number of total Reviews: <h2 className="fw-bold">{records && records.total_review ? records.total_review : 0}</h2>
                                        Average Overall Rating: <h2 className="fw-bold">{records && records.total_average_rating ? records.total_average_rating : 0}</h2><br />
                                        <div className="star-rating mx-3"><span id="" style={{ width: records && records.total_average_rating ? Number(records.total_average_rating * 20) + '%' : Number(0 * 20) + '%' }}></span></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card border-light shadow-sm border-0 h-100">
                                    <div className="card-header fw-bold">Recommended via Facebook</div>
                                    <div className="card-body">
                                        <ReviewPieChart facebook={records && records.facebook_chart ? records.facebook_chart : 0} id="facebook" />
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card border-light shadow-sm border-0 h-100">
                                    <div className="card-header fw-bold">Rating Backdrops</div>
                                    <div className="card-body">
                                        <RatingBackDrops data={records && records.rating_backdrop ? records.rating_backdrop : 0} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row g-4 mt-2">
                            <div className="col-4">
                                <div className="card border-light shadow-sm border-0 h-100">
                                    <div className="card-header fw-bold">Listing Device Usage by Weekday</div>
                                    <div className="card-body">
                                        <ReviewPieChart data={records && records.chart_data ? records.chart_data : 0} id="listing" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row g-4 mt-2">
                            <div className="col">
                                <div className="card border-light shadow-sm border-0 h-100">
                                    <div className="card-header fw-bold">Competitors' Average Rating</div>
                                    <div className="card-body">
                                        <div className="row">
                                            {
                                                records && records.competitors_percentage && records.competitors_percentage ? records.competitors_percentage.map((ele, num) => {
                                                    return (
                                                        <div className="col-sm-2 rounded border py-4 text-center m-3" key={num}>
                                                            <h6 className="text-info fw-bold">{ele.name}</h6>
                                                            <h6 className="fw-bold">{ele.rating}</h6>
                                                            <div className="star-rating mx-3"><span id={`sr${num + 1}`} style={{ width: Number(ele.rating * 20) + '%' }}></span></div>
                                                        </div>
                                                    )
                                                }) : <div className="col-sm-2">No Results</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

export default ReputationDashboard
