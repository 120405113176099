import { createRoot } from 'react-dom/client'
import { VIEW_MAPPING } from "../../components/Service";
import { toastNotify } from "../../components/Helper";
import { fetchData, UPDATE_MAPPING } from "../../components/Service";
import * as Elements from "../../components/Elements";
import { useContext, useEffect, useState } from 'react';
import DataTables, { redrawDataTable } from '../../components/Datatables';
import { now } from "lodash";
import $ from 'jquery'
import { Context } from '../../components/Context';

function ViewMapping() {
    window.document.title = "View Mapping";
    const [refresh, setRefresh] = useState(now)
    const [context] = useContext(Context)

    const clearInput = (e) => {
        var element = e.target.parentElement.children[0]
        if (element.dataset.edit === 'false' && element.value !== '') {
            element.dataset.edit = true
        } else if (element.dataset.edit_url === 'false' && element.value !== '') {
            element.dataset.edit_url = true
        } else if (element.dataset.edit_image === 'false' && element.value !== '') {
            element.dataset.edit_image = true
        }
        element.value = ''
    }

    const editData = (e) => {
        var element = e.target.dataset
        if (element.edit === 'false') {
            element.edit = true
        } else if (element.edit_url === 'false') {
            element.edit_url = true
        } else if (element.edit_image === 'false') {
            element.edit_image = true
        }
    }

    window.updateRecord = (e) => {
        let custom_location_id = {};
        var input_data = document.querySelectorAll('#wt_datatable_ssoTable input[data-edit=true]')
        input_data.forEach(function (element) {
            custom_location_id[element.name] = element.value;
        })

        let business_length = Object.keys(custom_location_id).length

        let customer_portal = {};
        var input_data_url = document.querySelectorAll('#wt_datatable_ssoTable input[data-edit_url=true]');
        input_data_url.forEach(function (element) {
            customer_portal[element.name] = element.value;
        })
        let customer_length = Object.keys(customer_portal).length

        let mapping_image = {};
        var input_data_image = document.querySelectorAll('#wt_datatable_ssoTable input[data-edit_image=true]');
        input_data_image.forEach(function (element) {
            mapping_image[element.name] = element.value;
        })
        let mapping_length = Object.keys(mapping_image).length

        if (business_length <= '25' && customer_length <= '25' && mapping_length <= '25') {
            let formdata = { business: custom_location_id, customer: customer_portal, image: mapping_image }
            fetchData(UPDATE_MAPPING, 'POST', formdata, true, false, (res) => {
                if (res.success) {
                    setRefresh(now())
                }
            });
        } else {
            toastNotify({ error: 'You can not edit or modify more than 25 records.' })
        }
    }

    const [dt] = useState({
        dt_url: VIEW_MAPPING,
        dt_name: 'ssoTable',
        dt_export: true,
        dt_column: [
            { data: 'amazio_id', name: 'locations.location_id', title: 'Amazio Id' },
            { data: 'custom_location_id', name: 'map_locations.custom_location_id', title: 'Business Alias' },
            { data: 'customer_portal_url', name: 'map_locations.customer_portal_url', title: 'Customer Portal Url' },
            { data: 'mapping_image', name: 'map_locations.mapping_image', title: 'Mapping Image' },
        ],
        dt_column_defs: [
            {
                targets: 1,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <div className="input-group input-group-sm">
                            <input type="text" className="form-control" name={records.amazio_id} defaultValue={records.custom_location_id ? records.custom_location_id : records.location_alias} data-edit="false" onInput={(e) => editData(e)} />
                            <button className="btn btn-outline-secondary" type="button" onClick={(e) => clearInput(e)} >
                                <i className="bi bi-x pe-none"></i>
                            </button>
                        </div>
                    )
                },
            },
            {
                targets: 2,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <div className="input-group input-group-sm">
                            <input type="text" className="form-control" name={records.amazio_id} readOnly={records.custom_location_id || records.location_alias ? false : true} disabled={records.custom_location_id ? false : true} defaultValue={records.customer_portal_url} data-edit_url="false" onInput={(e) => editData(e)} />
                            <button className="btn btn-outline-secondary" type="button" onClick={(e) => clearInput(e)}>
                                <i className="bi bi-x pe-none"></i>
                            </button>
                        </div>
                    )
                },
            },
            {
                targets: 3,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <div className="input-group input-group-sm">
                            <input type="text" className="form-control" name={records.amazio_id} readOnly={records.custom_location_id || records.location_alias ? false : true} disabled={records.custom_location_id ? false : true} defaultValue={records.mapping_image} data-edit_image="false" onInput={(e) => editData(e)} />
                            <button className="btn btn-outline-secondary" type="button" onClick={(e) => clearInput(e)}>
                                <i className="bi bi-x pe-none"></i>
                            </button>
                        </div>
                    )
                },
            }
        ],
        dt_order: [[0, 'asc']]
    });

    useEffect(() => {
        if (dt) {
            redrawDataTable(dt);
        }

        if (!$('.dt-custom-filter button').hasClass('add-new')) {
            {context && context.auth && context.auth.user_group_id === 1 && context.auth.super_admin === "no" ? 
                context.auth.permission && context.auth.permission.map((item, key) => {
                    return(
                        <div key={key}>
                            {item.permission === "CED Mapping" &&
                                <>
                                    {item.edit === "yes" ? 
                                        $('.dt-custom-filter').append(`<button type="button" class="btn btn-dark px-3 text-nowrap me-2 rounded-0 add-new" onclick="updateRecord()">
                                            <svg class="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg>Update
                                        </button>`)
                                    : ''}
                                </>
                            }
                        </div>
                    )
                })
            : 
                $('.dt-custom-filter').append(`<button type="button" class="btn btn-dark px-3 text-nowrap me-2 rounded-0 add-new" onclick="updateRecord()">
                    <svg class="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg>Update
                </button>`)
            }
                    
            
        }
        // eslint-disable-next-line
    }, [dt, refresh])

    return (
        <>
            <Elements.ListSection title='View Mapping'>
                <DataTables dt_name="ssoTable" dataPageLength="100" />
            </Elements.ListSection>
        </>
    )
}
export default ViewMapping
