import * as Elements from "../../components/Elements";

function FacebookSetup() {
    window.document.title = "Amazio | Facebook Setup";
    
    return (
    <>
        <Elements.PageTitleSection title="Facebook Setup"/>

        <section className="py-5">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <h4 className="fw-bold">Add People to Your Business Manager</h4>
                        <h6 className="fw-bold mt-4">If you're an admin:</h6>
                        <ul className="p-0 m-0 ms-3">
                            <li>Click Settings at the top of your <strong>Page</strong>.</li>
                            <li>Click <strong>Page</strong> Roles in the left column.</li>
                            <li>Type a name or email in the box and select the person from the list that appears.</li>
                            <li>Click Editor to select a role from the dropdown menu.</li>
                            <li>Click <strong>Add</strong> and enter your password to confirm.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    </>)
}

export default FacebookSetup