import * as Elements from "../../components/Elements";

function GoogleBusinessVerification() {
    window.document.title = "Amazio | Google My Business Verification";
    
    return (
    <>
        <Elements.PageTitleSection title="Google My Business Verification Process"/>

        <section className="py-5">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <ul className="p-0 m-0 ms-3">
                            <li className="mb-1">
                                View 1 minute instructional video at <a href="https://www.youtube.com/watch?v=FH993b7T7SA" target="_blank" rel="noreferrer" > https://www.youtube.com/watch?v=FH993b7T7SA</a>
                            </li>
                            <li className="mb-1">
                                Log into <a href="https://google.com/mybusiness" target="_blank" rel="noreferrer" >https://google.com/mybusiness</a> if you have already setup your business in Google (see note below if you have not created a login)
                            </li>
                            <li className="mb-1">
                                Add <a href="mailto:info@amazio.com">info@amazio.com</a> as a manager of your GMB account per instructions in video
                            </li>
                            <li className="mb-1">
                                Please let us know once Amazio has been added by emailing <a href="mailto:info@amazio.com">info@amazio.com</a> confirming the step is complete
                            </li>
                        </ul>


                        <h4 className="fw-bold mt-3">Note:</h4>
                        <p className="pb-2 text-common">If you have never setup GMB and claimed your business listing, please do the following first:</p>
                        <ul className="p-0 m-0 ms-3">
                            <li className="mb-1">Go to <a href="https://google.com/mybusiness" target="_blank" rel="noreferrer" >https://google.com/mybusiness</a></li>
                            <li className="mb-1">Enter business name to claim</li>
                            <li className="mb-1">Follow instructions for first time setup</li>
                            <li className="mb-1">Return to process above to now add Amazio(info@amazio.com) as a manager of your GMB account
                            </li>
                        </ul>
                        <p className="text-listing pt-3 text-common">
                        Once the process is complete, you will see an improved perspective of your business profile within the Amazio platform. 
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </>)
}

export default GoogleBusinessVerification