import React from 'react'
import {fetchData,Add_FAQS} from "../../components/Service"
import { Link, useNavigate } from "react-router-dom";
import { validateForm } from "../../components/Helper"

function AddFaq() {
    window.document.title = "Add FAQs";

    const navigate = useNavigate();
    
    const saveData = (e) => {
        let formData = new FormData(document.getElementById('addFaqForm'));
        if (validateForm(e)) {
            fetchData(Add_FAQS, 'POST', formData, true, true, (res) => {
                if(res.success){
                    navigate(`/admin/faqs-list`)
                }
            })
        }
    }

    return (
        <section className="admin-wrapper">
            <div className="container-fluid">
                <div className="row align-items-center justify-content-between">
                    <div className="col mb-2 text-center text-md-start ms-xl-0 ms-md-4">
                        <h4 className="page-heading">Add FAQs</h4>
                    </div>
                </div>
                <div className="row justify-content-center pt-4">
                    <div className="col-md-8">
                        <div className="card">
                            <div className="card-body p-4">
                                <form className="needs-validation" method="post" id="addFaqForm" noValidate>
                                    <div className="mb-3 row">
                                        <label htmlFor="question" className="col-sm-3 col-form-label">Question<span className="text-danger">*</span> :</label>
                                        <div className="col-sm-9">
                                            <input type="text" className="form-control" id="question" name="question" defaultValue="" placeholder="Enter Question" required/>
                                            <div className="invalid-feedback">
                                                This question field is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label htmlFor="answer" className="col-sm-3 col-form-label">Answer<span className="text-danger">*</span> :</label>
                                        <div className="col-sm-9">
                                            <textarea className="form-control" name="answer" id="answer" cols="10" defaultValue="" rows="4" placeholder="Enter Answer" required></textarea>
                                            <div className="invalid-feedback">
                                                This answer field is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-10 offset-sm-3">
                                            <button type="button" className="btn btn-primary minw-100" onClick={saveData}>Add</button>
                                            <Link  to={`/admin/faqs-list`} className="btn btn-outline-danger ms-2">Back</Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AddFaq
