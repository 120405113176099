import * as Elements from "../../components/Elements";

function ManageServices() {
    window.document.title = "Amazio | Manage Services";
    
    return (
    <>
        <Elements.PageTitleSection title="Managed Services"/>
        
        <section className="py-3">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-sm-7">
                        <p>
                            Your digital presence stretches farther than you might think. It starts at your website and extends to social media, product reviews, and even unrelated chats and forums where people discuss your products and your company. Do you know what they're saying? Are you delivering a clear, consistent message?
                        </p>
                        <p>
                            Do you know your customers?
                        </p>
                        <p>
                            Our managed services take control of all of that. Amazing control. Not only can we help you create content, develop strategies for sharing that content, respond to customer questions and reviews, and evolve your business presence on your app and website, we also continually analyse the effectiveness of those efforts. We get to know your competitors and how you measure up—and where you can improve.
                        </p>
                        <p>
                            Those personalized insights and tangible outcomes give you the opportunity to grow, no matter your industry or where you're located.
                        </p>
                        <p>
                            Here's a taste of what we offer:
                        </p>
                        <p>
                            <strong>Digital Listings and Branding:</strong> Make sure you have a presence on Google Maps, Yahoo, Yelp, Amazon Alexa, and many more, while making sure that presence paints an accurate picture of what your company does.
                        </p>
                        <p>
                            <strong>Reviews and Social Reputation:</strong> The vast majority of consumers (86 percent in total, including 95 percent of those ages 18-34) read reviews, and "...online reviews impact 67.7% of respondents' purchasing decisions." We help you respond to reviews, both good and bad, to uphold a positive reputation.
                        </p>
                        <p>
                            <strong>Social Media Management and Content Creation:</strong> Content and strategy, including ads, for your website and across social channels.
                        </p>
                        <p>
                            <strong>Analysis:</strong> We make sure that what we're doing is working. We stay up to date with your customers and their preferences.
                        </p>
                        <p>
                            <strong>Print Media:</strong> Yes, it still matters—it's still effective. We work with top-notch designers to offer amazing print media services.
                        </p>
                        <p>
                            Between our technological innovations and partnerships with leading online brands, we're equipped to bring next-level value to your organization.
                        </p>
                    </div>
                    <div className="col-sm-5 ">
                        <img className="w-100" src="images/managed-services.gif" alt="animation" />
                    </div>
                </div>
            </div>
        </section>
    </>
    );
}
export default ManageServices