import React, { useEffect, useState } from "react";
import 'intl-tel-input/build/css/intlTelInput.css';
import { fetchData, ADD_REQUEST_DEMO } from "../../components/Service"
import { validateForm, intlTel_phone, validatePhone, initialFormState } from "../../components/Helper"

function RequestDemo() {
    window.document.title = "Amazio | Request Demo";
    const [intlTel, setIntlTel] = useState()
    const [tinyloader, setTinyloader] = useState(false);
    

    const saveData = (e) => {
        let formData = new FormData(document.getElementById('requestDemoForm'));
        var msg_error = document.getElementById('msg-error');
        formData.append('business_phone', intlTel.getNumber())
        if (validateForm(e, 'requestDemoForm') && validatePhone(intlTel, '#business_phone')) {
            if(formData.get('g-recaptcha-response')){
                msg_error.classList.add('d-none');
                setTinyloader(true)
                fetchData(ADD_REQUEST_DEMO, 'POST', formData, true, true, (res) => {
                    if (res.success) {
                        initialFormState('requestDemoForm')
                        setTinyloader(false)
                    }
                })
            }else{
                msg_error.classList.remove('d-none');
            }
        }

    }

    useEffect(() => {
        document.querySelector('#video-section').innerHTML = '<video width="100%" autoPlay={true} muted="" loop={true}><source src="images/request-demo.mp4" type="video/mp4" /></video>'
        setIntlTel(intlTel_phone('#business_phone'))

        const script = document.createElement("script"); script.async = true;
        script.src = 'https://www.google.com/recaptcha/api.js';
        document.body.appendChild(script);
    }, [])

    return (
        <>
            <style>{`
                .form-floating>.label {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    padding: 1.5rem .4rem 0.4rem .4rem;
                    pointer-events: none;
                    border: 1px solid transparent;
                    transform-origin: 0 0;
                    opacity: .55;
                    transition: opacity .1s ease-in-out, transform .1s ease-in-out;
                }    
                .form-floating>.form-control:focus~.label, .form-floating>.form-control:not(:placeholder-shown)~.label, .form-floating>.form-select~.label {
                    opacity: .65;
                    transform: scale(.85) translateY(-1.3rem) translateX(.125rem);
                }   
            
            `}</style>


            <section className="py-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-8 col-lg-6 py-3 order-md-last bg-light">
                            <div className="col-md-12 pb-2">
                                <h2 className="common-heading fw-bolder">Request Demo</h2>
                            </div>
                            <form className="needs-validation" id="requestDemoForm" method="post" noValidate>
                                <div className="row">
                                    <div className="col">
                                        <input type="text" name="first_name" id="first_name" className="form-control form-bottomline" placeholder="First Name*" required />
                                        <div className="invalid-feedback">The first name is required.</div>
                                    </div>
                                    <div className="col">
                                        <input type="text" name="last_name" id="last_name" className="form-control form-bottomline" placeholder="Last Name*" required />
                                        <div className="invalid-feedback">The last name is required.</div>
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col">
                                        <input type="email" className="form-control form-bottomline" placeholder="Business Email" aria-label="Business Email" defaultValue="" name="business_email" id="business_email" required />
                                        <div className="invalid-feedback">The email is required.</div>
                                    </div>
                                    <div className="col">
                                        <input type="text" className="form-control form-bottomline" placeholder="PC Number (Business Alias)" aria-label="PC Number" defaultValue="" name="pc_number" id="pc_number"/>
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col">
                                        <input type="tel" className="form-control form-bottomline" placeholder="Business Phone" aria-label="Business Name" defaultValue="" id="business_phone" name="business_phone" data-intl-tel-input-id="0" required />
                                        <div className="invalid-feedback">The phone number is required</div>
                                    </div>
                                    <div className="col">
                                        <input type="text" className="form-control form-bottomline" placeholder="Business Name" aria-label="Business Name" defaultValue="" name="business_name" id="business_name"/>
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col">
                                        <input type="search" className="form-control form-bottomline" placeholder="Select Your Timezone" list="yourtimezone" defaultValue="" name="time_zone" id="time_zone" />
                                        <datalist id="yourtimezone">
                                            <option>EST</option>
                                            <option>CST</option>
                                            <option>MST</option>
                                            <option>PST</option>
                                        </datalist>
                                    </div>
                                    <div className="col">
                                        <input type="datetime-local" title="Select date and time" className="form-control form-bottomline" placeholder="Select Date and time" aria-label="Business Email" name="requested_date" id="requested_date" defaultValue="" />
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col form-floating">
                                        <textarea className="form-control form-bottomline" rows="5" placeholder="Notes" name="notes" id="notes" defaultValue="" ></textarea>
                                        <label htmlFor="floatingTextarea">Notes</label>
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-md-8">
                                        <div className="captcha_wrapper">
                                                <div className="g-recaptcha" id="g_recaptcha_contact" data-sitekey="6Lexnr8UAAAAAApT7UhSkNFBKTbkya4gnijVi9jb"> 
                                                </div>
                                                <span className="msg-error text-danger d-none" id="msg-error">Robot verification failed, please try again.</span>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                    <button type="button" className=" btn btn-primary fw-bold px-4 px-md-5 d-inline" onClick={(e) => saveData(e)} disabled={tinyloader} id="submit-request">
                                        {
                                            !tinyloader ? 'Submit'
                                                :
                                                <div className="spinner-border spinner-border-sm mx-3" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                        }
                                    </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-4 col-lg-6 text-center" id="video-section">
                            {/* Video Will Play Here */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default RequestDemo