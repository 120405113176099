import { useState, useEffect, useContext } from 'react'
import { Link, useNavigate } from "react-router-dom";
import Select from 'react-select'
import { fetchData, GET_LOCATION_UNIQUE_URL, ADD_USER_SUBSCRIBE } from "../../components/Service"
import { toastNotify, validateForm, initialFormState } from "../../components/Helper";
import { Context } from "../../components/Context"

function SelectLocation() {
    window.document.title = "Select Location";
    const navigate = useNavigate();
    const [tinyloader, setTinyloader] = useState(false);
    const [context] = useContext(Context);

    const [location, setLocation] = useState();
    const [selectedlocation, setSelectedLocation] = useState(false);
    let applyButton = 0;
    context && context.auth && context.auth.permission && context.auth.permission.map((ele, num) => {
        return (ele.permission === 'Common Dashboard' && ele.view === 'yes') || (ele.permission === 'Listing Dashboard' && ele.view === 'yes') ? (++applyButton) : ''
    })

    const selectLocation = (e) => {
        if (e.length > 10) {
            toastNotify({ error: 'You can\'t select more than 10 location at a time.' })
            return
        }
        setSelectedLocation(e ?? false)
    }

    useEffect(() => {
        fetchData(GET_LOCATION_UNIQUE_URL, 'GET', '', true, false, (res) => {
            if (res.records) {
                setLocation(res.records)
            }
        });
    }, []);

    const getData = (e) => {
        if (selectedlocation.length > 0) {
            localStorage.setItem('selected_location_dashboard', JSON.stringify(selectedlocation))
            context && context.auth && context.auth.permission && context.auth.permission.map((ele, num) => {
                return (<>
                    {ele.permission === 'Common Dashboard' && ele.view === 'yes' ? navigate('/users/admin-dashboard') : ''}
                    {ele.permission === 'Listing Dashboard' && ele.view === 'yes' ? navigate('/users/dashboard-listing') : ''}
                </>)
            })

        } else {
            toastNotify({ error: 'Please select atleast one location' })
        }
    }

    const submitSubscribe = (e) => {
        let formData = new FormData(document.getElementById('subscribeForm'));
        if (validateForm(e, 'subscribeForm')) {
            setTinyloader(true)
            fetchData(ADD_USER_SUBSCRIBE, 'POST', formData, true, true, (res) => {
                setTinyloader(false)
                if (res.success) {
                    initialFormState('subscribeForm', formData)
                }
            })
        }
    }

    return (
        <section className="admin-wrapper">
            <div className="container-fluid">
                <div className="row g-4 mt-1">
                    <div className="col-md-8 col-lg-9">
                        <div className="row justify-content-center">
                            <div className="col-md-12 col-xl-7">
                                <div className="rounded bg-white border text-center p-5">
                                    <h3 className="fw-bold pb-3">Welcome {context.auth.first_name} {context.auth.last_name}</h3>
                                    <img className="mmw-150 mb-4 opacity-75" src="images/select-location.svg" alt="location" />
                                    <div className="row mb-3 text-start">
                                        <label htmlFor="location" className="col-form-label fw-bold">Select Location</label>
                                        <div className="ps-3 w-100 ">

                                            <div className="row">
                                                <div className="col-8">
                                                    <Select
                                                        value={selectedlocation}
                                                        name="location[]"
                                                        isClearable={true}
                                                        closeMenuOnSelect={false}
                                                        options={location ?? []}
                                                        isMulti={true}
                                                        className="basic-multi-select react-select"
                                                        onChange={(e) => selectLocation(e)}
                                                        id="location_id"
                                                        required
                                                    />
                                                </div>
                                                {applyButton > 0 ? <div className="col-4 text-center"><button className="btn btn-primary " type="button" onClick={(e) => getData(e)} >Apply</button></div> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-3">
                        <div className="list-group">
                            <div className="list-group-item bg-dark text-white h4 px-3 py-2 m-0">
                                Quick Links
                            </div>
                            <Link to='/knowledge-base' target='_blank' className="list-group-item bg-secondary-light-10 fw-semibold">
                                <img className="mmw-50" src="images/knowledge-base.svg" alt="knowledge-base" />
                                <span className="ms-3">Knowledge Base</span>
                            </Link>
                            <Link to="/" className="list-group-item bg-secondary-light-10 fw-semibold">
                                <img className="mmw-50" src="images/scan-my-business.svg" alt="scan" />
                                <span className="ms-3">Scan My Business</span>
                            </Link>
                            {context && context.auth && context.auth.permission && context.auth.permission.map((ele, num) => {
                                return (ele.permission === "Supports") && (ele.add === "yes") && <Link to="../create-ticket" className="list-group-item bg-secondary-light-10 fw-semibold" key={num}>
                                    <img className="mmw-50" src="images/subscription.svg" alt="scan" />
                                    <span className="ms-3">Create Ticket</span>
                                </Link>
                            })}
                            <Link to="/pricing#faq-section" className="list-group-item bg-secondary-light-10 fw-semibold">
                                <img className="mmw-50" src="images/faq.svg" alt="scan" />
                                <span className="ms-3">FAQs</span>
                            </Link>
                            <div className="list-group-item bg-dark text-white h4 px-3 py-2 m-0">
                                Newsletter
                            </div>
                            <form className="list-group-item py-3 needs-validation" id="subscribeForm" method="post" noValidate>
                                <input type="hidden" name="business_name" id="business_name" defaultValue={`${context.auth.first_name} ${context.auth.last_name}`} className="form-control mb-3" placeholder="Name" required />
                                <input type="email" name="business_email" id="business_email" className="form-control mb-3" placeholder="Email Address" required />
                                <button type="button" id="subscribe-newsletter" className="btn bg-dark-blue w-100 text-white" onClick={(e) => submitSubscribe(e)} disabled={tinyloader}>
                                    {
                                        !tinyloader ? ' Sign Me Up! '
                                            :
                                            <div className="spinner-border spinner-border-sm mx-3" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                    }
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SelectLocation