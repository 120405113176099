import React, { useState, useEffect, useContext } from "react";
import {useNavigate} from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import { __echoText, dateFormat } from "../../components/Helper";
import { fetchData, VIEW_TICKET, DELETE_TICKET, UPDATE_TICKET, GET_TICKET_STAFF, ADMIN_TICKET_REPLY } from "../../components/Service";
import _, { now } from "lodash";
import { Context } from "../../components/Context"
import * as Elements from "../../components/Elements";
import DataTables, { redrawDataTable, reloadUrlDataTable } from "../../components/Datatables";
import $ from 'jquery'

function Support() {
    window.document.title = "Ticket List";
    const navigate = useNavigate()
    const [context] = useContext(Context)
    const [pageLoader, setPageLoader] = useState(false);
    const [statusValue, setStatusValue] = useState();
    const [priorityValue, setPriorityValue] = useState();
    const [tinyloader, setTinyloader] = useState(false);
    const [staffId, setStaffId] = useState();
    const [reply, setReply] = useState('')
    const [refresh, setRefresh] = useState(now())
    const [staff, setStaff] = useState()
    const [formField, setFormField] = useState({
        email: '',
        first_name: '',
        id: '',
        message: '',
        pc_number: '',
        priority: '',
        staff_id: '',
        status: '',
        subject: '',
        ticket_replies: '',
        track_id: '',

    })

    const deleteRecords = (id) => {
        fetchData(DELETE_TICKET + '?id=' + id, 'POST', '', true, false, (res) => {
            document.querySelector('#confModal [data-bs-dismiss="modal"]').click()
            if (res.success) {
                setRefresh(now())
            }
        });
    }

    const viewTicket = (ele) => {
        let id = ele.id;

        setPageLoader(true)
        fetchData(GET_TICKET_STAFF, 'GET', '', true, false, (res) => {
            setPageLoader(false)
            if (res.records) {
                setStaff(res.records)
            }
        });

        setPageLoader(true)
        fetchData(UPDATE_TICKET + '?id=' + id, 'GET', '', true, false, (res) => {
            setPageLoader(false)
            if (res.records) {
                setFormField(res.records)
                setStatusValue(res.records.status)
                setPriorityValue(res.records.priority)
               setTimeout(() => {
                scrollbarSetToBottom()
               }, 200);
            }           
        }, false, false, false);
    }


     const scrollbarSetToBottom = () => {
       
        let element = document.querySelector('.direct-chat-messages');
        element.scrollTop = element.scrollHeight;
    }


    const submitReply = (id, email) => {
        setTinyloader(true)
        fetchData(ADMIN_TICKET_REPLY, 'POST', { 
            'id': id,
            'message': reply,
            'email': email,
            'priority': priorityValue,
            'status': statusValue,
            

        }, true, false, (res) => {
            setTinyloader(false)
            if (res.success) {
                //append reply to ticket_replies
                setReply('')
                document.querySelector('#message').value = ''
                let ticket_replies = formField.ticket_replies;                
                ticket_replies= res.records.ticket_replies;
                setFormField(prevState => ({ ...prevState, ticket_replies: ticket_replies }))               
                //scroll to bottom
                setTimeout(() => {
                    scrollbarSetToBottom()
                   }, 100);

                setRefresh(now())
            }
    
        });
    }

    const [dt] = useState({
        dt_url: VIEW_TICKET,
        dt_name: 'supportTable',
        dt_export: true,
        dt_column: [
            { data: 'track_id', name: 'track_id', title: 'Ticket Id', width: 100 },
            { data: 'name', name: 'name', title: 'Name' },
            { data: 'pc_number', name: 'pc_number', title: 'PC Number' },
            { data: 'subject', name: 'subject', title: 'Subject' },
            { data: 'priority', name: 'priority', title: 'Priority' },
            { data: 'created_at', name: 'created_at', title: 'Created Date', width:100 },
            { data: 'classification', name: 'classification', title: 'State' },
            { data: 'status', name: 'status', title: 'Status' },
            { data: 'action', name: 'action', title: 'Action', class: "text-nowrap text-center noExport", sortable: false, searchable: false, orderable: false }
        ],
        dt_column_defs: [
            {
                targets: 0,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <>
                            {context && context.auth && context.auth.user_group_id === 1 && context.auth.super_admin === "no" ?
                                context.auth.permission && context.auth.permission.map((item, key) => {
                                    return (
                                        <div key={key}>
                                            {item.permission === "Supports" &&
                                                <>
                                                    {item.add === "yes" ?
                                                        <span className="text-primary" role="button" data-bs-target="#ticketModal" data-bs-toggle="modal" style={{ textDecoration: "underline" }} onClick={() => viewTicket(records)}>{__echoText(records.track_id)}</span>
                                                        :
                                                        <span className="text-primary">{__echoText(records.track_id)}</span>
                                                    }
                                                </>
                                            }
                                        </div>
                                    )
                                })
                                :
                                <span className="text-primary" role="button" data-bs-target="#ticketModal" data-bs-toggle="modal" style={{ textDecoration: "underline" }} onClick={() => viewTicket(records)}>{__echoText(records.track_id)}</span>
                            }
                        </>
                    )
                },
            },
            {
                targets: 2,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <>
                            {records.pc_number ?? 'N/A'}
                        </>
                    )
                },
            },
            {
                targets: 4,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<span>{__echoText(_.upperFirst(records.priority))}</span>)
                },
            },
            {
                targets: 5,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>{dateFormat(records.created_at)}</>)
                },
            },
            {
                targets: 6,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <>
                            {records.classification ?? 'N/A'}
                        </>
                    )
                },
            },
            {
                targets: 7,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>{records.status === "new" ? <span className="badge  bg-danger rounded-pill fw-medium minw-70">New</span> :
                        records.status === "waiting" ? <span className="badge bg-warning rounded-pill fw-medium minw-70">In Progress</span> :
                            records.status === "reply" ? <span className="badge bg-dark rounded-pill fw-medium minw-70">On Hold</span> :
                                records.status === "replied" ? <span className="badge bg-info rounded-pill fw-medium minw-70">Replied</span> :
                                    <span className="badge bg-success rounded-pill fw-medium minw-70">Resolved</span>}</>)
                },
            },
            {   
                targets: 8,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <div className="d-flex text-nowrap">
                            {context && context.auth && context.auth.user_group_id === 1 && context.auth.super_admin === "no" ?
                                context.auth.permission && context.auth.permission.map((item, key) => {
                                    return (
                                        <div key={key}>
                                            {item.permission === "Supports" &&
                                                <>
                                                    {item.delete === "yes" ?
                                                        <Elements.DeleteButton tid="confModal" func={() => setFormField(prevState => ({ ...prevState, id: records.id }))} />
                                                        :
                                                        <Elements.DisableButton btnType="danger" icon="#icon_trash" />
                                                    }
                                                </>
                                            }
                                        </div>
                                    )
                                })
                                :
                                <Elements.DeleteButton tid="confModal" func={() => setFormField(prevState => ({ ...prevState, id: records.id }))} />
                            }
                        </div>
                    )
                },
            }
        ]
    });

    window.statusFilterHandler = (selectedOption) => {
        reloadUrlDataTable(dt, `${VIEW_TICKET}?f_type=${selectedOption}`);
    }

    window.addNewTicketPage = () => {
        navigate('/users/create-ticket')
    }

    useEffect(() => {
        if (dt) {
            redrawDataTable(dt);
        }

        if (!$('.dt-custom-filter select').hasClass('filter-option')) {
            $('.dt-custom-filter').html(`<select className="btn border-1 border-secondary filter-option" id="filtertenderstatus" onChange="statusFilterHandler(event.target.value)" style="padding:7px !important;">
                <option value="" >All</option>
                <option value="low">Low</option>
                <option value="high">High</option>
                <option value="medium">Medium</option>
                <option value="critical">Critical</option>
            </select>`);
        }

        //append button for client and admin to create ticket
        if (context && context.auth && context.auth.super_admin === "no") {
            if (!$('.dt-custom-filter button').hasClass('add-new-ticket')) {
                $('.dt-custom-filter').append(`<button type="button" class="btn btn-dark px-3 text-nowrap me-2 rounded-0 add-new-ticket ms-2" onClick={addNewTicketPage()}>
                <svg class="icon tscale-1p3 me-1" role="img"><use href="#icon_plus"></use></svg> Add New
            </button>`);
            }
        }

    }, 
    [dt, refresh])

   
    return (
        <>
            <Elements.ListSection title="All Tickets">
                <DataTables dt_name="supportTable" dataPageLength="15" />
            </Elements.ListSection>

            <Elements.ModalSection title="Ticket Reply" modal_id="ticketModal" size="xl" page_loader={pageLoader}>

                <div className="row">
                    <div className="col-md-4 col-sm-12 border-partition ">
                        <label htmlFor="Ticket" className="col-form-label text-blue fw-medium w-100 bg-dark rounded px-2 text-white mb-1"><span className="fw-bold">Ticket-ID:</span> {formField.track_id}</label> <br />
                        <label htmlFor="Email" className="col-form-label text-blue fw-medium w-100 bg-dark rounded px-2 text-white mb-1"><span className="fw-bold">Email:</span> {formField.email}</label> <br />
                        <label htmlFor="PCNumber" className="col-form-label text-blue fw-medium w-100 bg-dark rounded px-2 text-white mb-1"><span className="fw-bold">PC Number:</span> {formField.pc_number ?? 'N/A'}</label> <br />
                        <label htmlFor="Subject" className="col-form-label text-blue fw-medium w-100 bg-dark rounded px-2 text-white mb-1"><span className="fw-bold">Subject:</span> {formField.subject}</label> <br />
                        {/* loop attachmentUrl in ul li */}
                        {formField && formField.attachments && formField.attachments.length > 0 && <><label htmlFor="Subject" className="col-form-label text-blue fw-medium w-100 bg-dark rounded px-2 text-white mb-1"><span className="fw-bold">Attachment:</span> </label> <br />

                            <ul className="list-group list-group-flush attachment-list-box">
                                {formField.attachments.map((ele, key) => {
                                    return (
                                        <li className="list-group-item" key={key}>
                                            <a href={(process.env.REACT_APP_ASSET_ENDPOINT+ele.attachments)} target="_blank" rel="noreferrer" className="attachment-list">
                                                <svg className="icon" role="img" aria-label="more">
                                                    <use href="#icon_pdf" /> 
                                                </svg> Attachment {key+1}
                                            </a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </>}
                    
                        <label htmlFor="Message" className="col-form-label text-blue fw-medium w-100 bg-dark rounded px-2 text-white mb-1"><span className="fw-bold">Message:</span>
                            <p>{formField.message}</p>
                        </label>

                    </div>
                    <div className="col-md-8 col-sm-12">
                        <form className="needs-validation p-3" id="ticketForm" method="post">
                            <div className="col-md-12 col-sm-12">
                                <div className="">
                                    <div className="card-body">
                                        <div className="direct-chat-messages">
                                            {/* loop ticket_replies */}
                                            {formField && formField.ticket_replies && formField.ticket_replies.length > 0 ? formField.ticket_replies.map((ele, key) => {    
                                                return (
                                                    <div className={ele?.reply_by?.id != context?.auth?.id ? 'direct-chat-msg mb-4 right' : 'direct-chat-msg mb-4' } key={key} >
                                                        <div className="direct-chat-infos clearfix">
                                                            <span className={ele?.reply_by?.id != context?.auth?.id ? 'direct-chat-name float-right' : 'direct-chat-name float-left' }>{ele?.reply_by?.first_name ?? "unknown"}</span>
                                                            <span className={ele?.reply_by?.id != context?.auth?.id ? 'direct-chat-timestamp  float-left' : 'direct-chat-timestamp  float-right' }>{(ele.created_at)}</span>
                                                        </div>
                                                        <img className="direct-chat-img" src="images/user.jpg" alt="message user image" />
                                                        
                                                        <div className="direct-chat-text">
                                                            {/* render html message */}
                                                            <p dangerouslySetInnerHTML={{ __html: ele.message }}></p>
                                                        </div>
                                                    </div>
                                                )
                                            }) : 
                                                <div className="col d-flex justify-content-center align-items-center" style={{height: "350px"}}>
                                                    <h2 className="fw-bold">No activity found</h2>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="Message" className="col-form-label"></label>
                                <div className="">
                                    <textarea type="text" className="form-control" id="message" placeholder="Reply" rows="4" name="message" defaultValue={reply} onChange={(e) => setReply(e.target.value)}></textarea>
                                </div>
                            </div>

                            <div className="alert alert-info my-4">
                                Maximum limit is 1000 characters.
                            </div>

                            <div className="row">
                                <div className="col-md-4 col-sm-4">
                                    <div className="form-group row">
                                        <label htmlFor="staticEmail" className="col-sm-3 col-form-label ">Priority:</label>
                                        <div className="col-sm-9">
                                            <div className="">
                                                <select className="form-select" id="priority" name="priority" value={priorityValue} onChange={(e) => setPriorityValue(e.target.value)} >
                                                    <option value="low">Low</option>
                                                    <option value="medium">Medium</option>
                                                    <option value="high">High</option>
                                                    <option value="critical">Critical</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-4">

                                    <div className="form-group row">
                                        <label htmlFor="staticEmail" className="col-sm-3 col-form-label ">Status:</label>
                                        <div className="col-sm-9">
                                            <div className="">
                                                <select className="form-select" name="status" id="status" value={statusValue} onChange={(e) => setStatusValue(e.target.value)}>
                                                    <option value="resolved">Resolved</option>
                                                    <option value="waiting">In Progress</option>
                                                    <option value="reply">On Hold</option>
                                                    <option value="replied">Replied</option>
                                                    <option value="new">New</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4 col-sm-4 text-end">
                                    {!tinyloader ?
                                        <button type="button" className="btn btn-primary px-4 ms-2" onClick={() => submitReply(formField.id, formField.email)} >
                                            Submit Reply
                                        </button>
                                    : 
                                        <button type="button" className="btn btn-primary px-4 ms-2" disabled>
                                            <div className="spinner-border spinner-border-sm mx-3" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </button>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </Elements.ModalSection>
            <Elements.ConfirmationModal msg="Are you sure to delete this record?" method={() => deleteRecords(formField.id)} />
        </>
    )
}

export default Support
