import React, { useState, useEffect } from "react";
import Select from "react-select"
import { downloadPdf, toastNotify } from "../../components/Helper";
import { fetchData, GET_LOCATION_UNIQUE_URL, GET_SENTIMENT } from "../../components/Service";
import DateRangeFilter from "../../components/DateRangeFilter";

function SegmentAnalysis() {
    window.document.title = "Sentiment Analysis";
    const [location, setLocation] = useState();
    const [records, setRecords] = useState('0');
    const [formfield, setFormfield] = useState({
        type: 'thirtDay',
        startDate: '',
        endDate: '',
    })

    useEffect(() => {
        fetchData(GET_LOCATION_UNIQUE_URL, 'GET', '', true, false, (res) => {
            if (res.records) {
                setLocation(res.records)
            }
        });
    }, [])

    const getDateRange = (startDate, endDate) => {
        document.getElementById("show_list").classList.add("d-none")
        document.getElementById("date_filter").value = `${startDate} - ${endDate}`
        setFormfield(prevState => ({
            ...prevState, type: '', startDate: startDate, endDate: endDate
        }))
    }

    const refreshData = () => {
        document.querySelector('input[name="daterange"]').value = 'MM DD YYYY - MM DD YYYY'
        document.getElementById("show_list").classList.add("d-none")
        document.getElementById("date_filter").value = 'Last 30 Days'
        setFormfield(prevState => ({
            ...prevState, type: 'thirtDay', startDate: '', endDate: ''
        }))
    }

    const getDateFilterData = (e, type) => {
        document.getElementById("show_list").classList.add("d-none")
        document.getElementById("date_filter").value = e.target.innerText
        setFormfield(prevState => ({
            ...prevState, type: type, startDate: '', endDate: ''
        }))
    }

    const getData = (e) => {
        let formdata = new FormData(document.getElementById('searchForm'));
        let location_selected = formdata.getAll('locations[]')
        if (location_selected.length <= 10) {
            if (location_selected.length > 0 && location_selected[0] !== '') {
                document.querySelectorAll(".shadow-sm").forEach(element => element.classList.add('card-loader'));
                document.querySelectorAll(".disabled-button").forEach(element => element.classList.add('disabled'));
                fetchData(`${GET_SENTIMENT}?location=${location_selected}&type=${formfield.type}&startDate=${formfield.startDate}&endDate=${formfield.endDate}`, 'GET', '', true, false, (res) => {
                    document.querySelectorAll(".disabled-button").forEach(element => element.classList.remove('disabled'));
                    document.querySelectorAll(".shadow-sm").forEach(element => element.classList.remove('card-loader'));
                    if (res.records.sentiment) {
                        setRecords(res.records)
                    } else {
                        setRecords('0')
                    }
                }, false, false, false);
            } else {
                toastNotify({ error: 'Please select atleast one location' })
                setRecords('0')
            }
        } else {
            toastNotify({ error: 'You can\'t select more than 10 location at a time.' })
        }
    }

    return (
        <section className="admin-wrapper">
            <div className="container-fluid">
                <div className="row align-items-center justify-content-between">
                    <div className="col-sm-12 text-center text-md-start ms-xl-0 ms-md-4">
                        <h4 className="page-heading">Sentiment Analysis</h4>
                    </div>
                    <div className="col-sm-12 my-3">
                        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4">
                            <form className="col" id="searchForm">
                                <Select
                                    name="locations[]"
                                    isClearable={true}
                                    closeMenuOnSelect={false}
                                    options={location}
                                    isMulti={true}
                                    className="basic-multi-select react-select"
                                    id="location_id"
                                    required
                                />
                            </form>
                            <div className="col position-relative">
                                <input type="text" id="date_filter" readOnly className="form-control bg-white shadow-none pointer-event-active" defaultValue="Last 30 Days" onFocus={() => document.getElementById("show_list").classList.remove("d-none")} />
                                <ul className="list-group show_list position-absolute bg-white shadow rounded d-none" id="show_list" style={{ listStyle: "none", width: '384px', zIndex: 2 }}>
                                    <li className="list-group-item list-group-item-action">
                                        <DateRangeFilter
                                            daterange="MM DD YYYY - MM DD YYYY"
                                            className="form-control"
                                            maxDate={new Date()}
                                            opens="left"
                                            name='daterange'
                                            reload={refreshData}
                                            disableUpdateInput={false}
                                            getDateRange={getDateRange}
                                        />
                                    </li>
                                    <li role="button" className="list-group-item list-group-item-action" onClick={(e) => getDateFilterData(e, "thirtDay")}>
                                        Last 30 Days
                                    </li>
                                    <li role="button" className="list-group-item list-group-item-action" onClick={(e) => getDateFilterData(e, "lastNinDay")}>
                                        Last 90 Days
                                    </li>
                                    <li role="button" className="list-group-item list-group-item-action" onClick={(e) => getDateFilterData(e, "lastTwelWeek")}>
                                        Last 12 Week
                                    </li>
                                    <li role="button" className="list-group-item list-group-item-action" onClick={(e) => getDateFilterData(e, "lastSixtWeek")}>
                                        Last 16 Week
                                    </li>
                                </ul>
                            </div>
                            <div className="col">
                                <button className="btn btn-primary disabled-button" type="button" onClick={(e) => getData(e)}>Submit</button>
                            </div>
                            <div className="col text-end">
                                <button type="button" className="btn btn-primary disabled-button" onClick={() => downloadPdf('showData', 'My_PDF_')}>Generate PDF Report</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12" id="showData">
                        <div className="row g-2 g-sm-3 g-lg-4">
                            <div className="col-sm-12">
                                <div className="card border-0 shadow-sm rounded-10">
                                    <div className="card-body table-spinner active">
                                        <div className="table-responsive">
                                            <table className="table-common">
                                                <colgroup>
                                                    <col></col>
                                                    <col></col>
                                                    <col></col>
                                                    <col width="220"></col>
                                                </colgroup>
                                                <thead>
                                                    <tr>
                                                        <th>Key</th>
                                                        <th>Sentiment</th>
                                                        <th>Mentions</th>
                                                        <th>AVG. Rating</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {records && records.sentiment ? records.sentiment.map((ele, num) => {
                                                        return (<tr role="row" key={num}>
                                                            <td>{ele.review_keywords}</td>
                                                            <td>
                                                                <svg viewBox="0 0 36 36" style={{ maxWidth: '70px' }}>
                                                                    <path style={{ fill: 'none', stroke: '#eee', strokeWidth: 4 }} d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                                                                    <path style={{ fill: 'none', strokeWidth: 3, stroke: ele.keyword_sentiment.toFixed(0) >= 0 ? '#1591dd' : '#FF0000', strokeLinecap: 'round', transition: 'all 3s ease-out' }} id="fillStroke" strokeDasharray={`${ele.keyword_sentiment.toFixed(0) >= 0 ? ele.keyword_sentiment.toFixed(0) : ele.keyword_sentiment.toFixed(0) * (-1)}, 100`} d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                                                                    <text x="18" y="20.35" style={{ fill: '#666', fontFamily: 'sans-serif', fontSize: '0.60em', textAlign: 'center', textAnchor: 'middle' }} id="percentageText">{ele.keyword_sentiment.toFixed(0)}</text>
                                                                </svg>
                                                            </td>
                                                            <td>{ele.keyword_mentions}</td>
                                                            <td width="500">
                                                                <div className="star-rating mx-3"><span id={`sr${num + 1}`} style={{ width: Number(ele.average_rating * 20) + '%' }}></span></div>
                                                            </td>
                                                        </tr>)
                                                    })
                                                        :
                                                        <tr>
                                                            <td colSpan="4" className="rounded text-center">
                                                                <div className="d-inline-block w-100 fs-6 py-5" style={{ maxWidth: '560px' }}>
                                                                    <img src="images/share.svg" alt="keywords.jpg" className="my-3" style={{ maxWidth: '130px' }} />
                                                                    <h1 className="fw-bold text-secondary">NO KEYWORDS</h1>
                                                                    <p className="mb-1 fst-italic">There is no data in the range you have selected.</p>
                                                                    <p className="mb-1 fst-italic">If your Account is new, wait a few weeks for data to become available</p>
                                                                    <p className="mb-1 fst-italic">Otherwise, try Changing your selections above</p>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SegmentAnalysis