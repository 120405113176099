import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchData, GET_BLOG, GET_URL, BUSINESS_SCAN } from "../../components/Service"
import { validateForm, initialFormState, intlTel_phone, state } from "../../components/Helper"
import $ from "jquery";
import { ModalSection } from '../../components/Elements';
import { Context } from '../../components/Context';
window.jQuery = $;
require('owl.carousel/dist/assets/owl.carousel.min.css');
require('owl.carousel');

function Home() {
    window.document.title = "Amazio";
    const [data, setData] = useState();
    const [context] = useContext(Context)
    const [caseUrl, setCaseUrl] = useState();
    const [businessUrl, setBusinessUrl] = useState();
    const [tinyloader, setTinyloader] = useState(false);
    const [pageLoader, setPageLoader] = useState(true);
    const [newURL, setNewURL] = useState();
    const [intlTel, setIntlTel] = useState()
    const [existLocation, setExistLocation] = useState(false)
    
    const scanNow = (e) => {
        let formData = new FormData(document.getElementById('scanForm'))
        if (validateForm(e, 'scanForm')) {
            setTinyloader(true)
            setPageLoader(true);
            fetchData(BUSINESS_SCAN, 'POST', formData, true, true, (res) => {
                setTinyloader(false)
                if (res.success) {
                    let values = `?name=${formData.get('name')}&address=${formData.get('address')}&phone=${formData.get('phone')}&city=${formData.get('city')}&zip=${formData.get('zip')}`;
                    var mainUrl = businessUrl;

                    setNewURL(mainUrl + values)

                    document.getElementById('showScanNowModal').click()
                    initialFormState('scanForm')
                }else{
                    setPageLoader(false)
                    setExistLocation(true)
                    document.getElementById('showScanNowModal').click()
                    initialFormState('scanForm')
                }
            })
        }
    }

    useEffect(() => {
        setIntlTel(intlTel_phone('#phone'))
        $('#testimonials').owlCarousel({
            loop: true,
            margin: 1,
            nav: false,
            responsive: {
                0: {
                    items: 1
                },
                600: {
                    items: 2
                },
                1000: {
                    items: 3
                }
            }
        })
        fetchData(GET_BLOG + '?f_type=cases', 'GET', '', true, false, (res) => {
            if (res.records) {
                setData(res.records.case)
            }
        }, (err) => { })

        fetchData(GET_URL + '?type=cases', 'GET', '', true, false, (res) => {
            if (res.records) {
                setCaseUrl(res.records)
            }
        }, (err) => { })

        fetchData(GET_URL + '?type=scanBusiness', 'GET', '', true, false, (res) => {
            if (res.records) {
                setBusinessUrl(res.records)
            }
        }, (err) => { })

        setInterval(() => {
            $('.counter-value').each(function () {
                console.log('Evwent Fired');
                var $this = $(this), countTo = $this.attr('data-count');
                $this.removeClass('glow');
                $({ countNum: 0 }).animate({
                    countNum: countTo
                }, {
                    duration: 3500,
                    easing: 'linear',
                    step: function () {
                        $this.text(Math.floor(this.countNum));
                    },
                    complete: function () {
                        $this.text(this.countNum);
                        $this.addClass('glow');
                    }
                });
            });
        }, 6000);
    }, [])


    return (
        <>
            <section className="container py-5">
                <div className="row align-items-center g-4">
                    <div className="col-lg-5 order-lg-1">
                        <img className="w-100" src="images/amazio-gif.gif" alt="animation" />
                    </div>
                    <div className="col-lg-7 pe-lg-5">
                        <h2 className="fw-bolder lh-base pb-3">
                            Manage your Digital Listings, Social Engagement & Online Reputation - all in one AMAZING platform.
                        </h2>
                        <a href="#let-us-amaze-you" className="btn btn-primary px-4">Let Us Amaze You</a>
                    </div>
                </div>
            </section>

            <section className="container py-5">
                <div className="row pb-3">
                    <div className="col-sm-12">
                        <h5 className="fw-bold pb-4">Find out how your business appears online</h5>
                    </div>
                    <div className="col-12">
                        <form className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-7 gy-4 gx-3 scan-form needs-validation" action="#" id="scanForm" method="post" autoComplete="off" noValidate>
                            <div className="col">
                                <input type="text" name="name" className="form-control" id="name" defaultValue="" placeholder="Business Name" required />
                                <div className="invalid-feedback">The name field is required</div>
                            </div>
                            <div className="col">
                                <input type="text" name="address" minLength={5} className="form-control" id="address" defaultValue="" placeholder="Address" required />
                                <div className="invalid-feedback">The address is required</div>
                            </div>
                            <div className="col">
                                <input type="tel" name="phone" id="phone" className="form-control" defaultValue="" placeholder="Phone No." required />
                                <div className="invalid-feedback">The phone field is required</div>
                            </div>
                            <div className="col">
                                <input type="text" name="city" className="form-control" id="city" defaultValue="" placeholder="City" required />
                                <div className="invalid-feedback">The city field is required</div>
                            </div>
                            <div className="col">
                                <select className="form-select scan-select px-2" name="state" id="state" defaultValue="" placeholder="State" required>
                                    <option value="">--Select--</option>
                                    { 
                                        Object.keys(state).map((ele, num) => <><option value={ele} key={num}>{state[ele]}</option></>)
                                    }
                                </select>
                                <div className="invalid-feedback">The state field is required</div>
                            </div>
                            <div className="col">
                                <input type="text" name="zip" id="zip" minLength={4} maxLength={9} className="form-control" placeholder="Zip Code" required />
                                <div className="invalid-feedback">The zip code field is required</div>
                            </div>
                            <div className="col-auto">
                                <button type="button" id="scanNow" className="btn btn-primary btn-block" onClick={(e) => scanNow(e)} disabled={tinyloader}>
                                    {
                                        !tinyloader ? 'Scan Now'
                                            :
                                            <div className="spinner-border spinner-border-sm mx-3" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                    }
                                </button>
                                <button type="button" id="showScanNowModal" className="btn btn-primary btn-block d-none" data-bs-toggle="modal" data-bs-target="#scanModal"></button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>

            <section className="py-5 border-top">
                <div className="container pt-sm-4">
                    <div className="row justify-content-center">
                        <div className="col-sm-6 col-lg-3 pt-lg-5">
                            <span className="ourservices-box">
                                <img src="images/icons/digital-business0listing.svg" alt="" />
                                <h2>Digital Business Listings</h2>
                                <p>
                                    No matter what type of business you run, your online presence is an important way to show current and potential clients who you are and what you can do.
                                </p>
                            </span>
                            <span className="ourservices-box">
                                <img src="images/icons/promotional-product.svg" alt="" />
                                <h2>Promotional Products</h2>
                                <p>
                                    Amazio provides a full range of products branded with a logo or slogan to promote a brand, corporate identity, or event. Request a free sample today.
                                </p>
                            </span>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <span className="ourservices-box">
                                <img src="images/icons/social-media-engagement.svg" alt="" />
                                <h2>Social Media Engagement</h2>
                                <p>
                                    Social media has made a big transition over the last few years from a place where people connected personally to friends and families.
                                </p>
                            </span>
                            <span className="ourservices-box">
                                <img src="images/icons/ads-survey.svg" alt="" />
                                <h2>Ads &amp; Surveys</h2>
                                <p>
                                    Localized &amp; Targeted Ads, Budget distribution &amp; Ad Templates. Monitor customer satisfaction &amp; drive brand loyalty with local surveys.
                                </p>
                            </span>
                        </div>
                        <div className="col-sm-6 col-lg-3 pt-lg-5">
                            <span className="ourservices-box">
                                <img src="images/icons/reputation-management.svg" alt="" />
                                <h2>Reputation Management</h2>
                                <p className="pb-sm-5">
                                    As a business, your reputation is everything. If your online reputation is less than stellar, you could be losing business.
                                </p>
                            </span>
                            <span className="ourservices-box">
                                <img src="images/icons/websites-apps.svg" alt="" />
                                <h2>Websites &amp; Apps</h2>
                                <p>
                                    Fully customizable, responsive &amp; search optimized websites and mobile apps with maintenance
                                    and real-time updates.
                                </p>
                            </span>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <span className="ourservices-box">
                                <img src="images/icons/competitive-intelligence.svg" alt="" />
                                <h2>Competitive Intelligence </h2>
                                <p>
                                    We specialize in bringing focus to your customer engagement continuously everywhere. With information about your brand scattered across the digital ecosystem.
                                </p>
                            </span>
                            <span className="ourservices-box">
                                <img src="images/icons/software-design.svg" alt="" />
                                <h2>Software &amp; Design</h2>
                                <p>
                                    In-house custom solutions for clients providing end to end services and solutions like SSO, Whitelabel, APIs, etc.
                                </p>
                            </span>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5 border-top" id="let-us-amaze-you">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 py-3">
                            <div className="infovideo-block">
                                <h2 className="fw-bold w-100 pb-2">What Is Amazio?</h2>
                                <figure className="wrapping-box" role="button">
                                    <video controls preload="auto" poster="images/about-us-video.jpg">
                                        <source src="images/videos/about-us.mp4" type="video/mp4" />
                                        <source src="images/videos/about-us.webm" type="video/webm" />
                                    </video>
                                </figure>
                                <div className="d-flex flex-wrap mb-auto">
                                    <p>
                                        Amazio is a platform that provides a single pane of glass(dashboard) for our clients &amp;
                                        partners to manage their digital presence, social engagement and online reputation. In
                                        addition, Amazio provides insights and intelligence into your competition and competitors.
                                        At Amazio, our content experts provide customized marketing strategy, creative assistance
                                        and other related managed services. Our array of services also includes websites, mobile
                                        apps, promotional products etc. to empower your brand &amp; your business.
                                    </p>
                                </div>
                                <div className="text-center mt-10">
                                    <Link to="/request-demo" className="btn btn-primary px-3">Learn More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 py-3">
                            <div className="infovideo-block">
                                <h2 className="fw-bold w-100 pb-2">Why Managed Services?</h2>
                                <figure className="wrapping-box" role="button">
                                    <video controls preload="auto" poster="images/manage-service-poster.jpg">
                                        <source src="images/videos/managed-services-home.mp4" type="video/mp4" />
                                        <source src="images/videos/managed-services-home.webm" type="video/webm" />
                                    </video>
                                </figure>
                                <div className="d-flex flex-wrap mb-auto">
                                    <p className="text-common">
                                        Come see what amazing reputation management can do for you.&nbsp;
                                        Our managed services help you maintain a strong digital presence in today's digital world.
                                        We
                                        can help ensure your business is listed everywhere people are looking for it, complete with
                                        the right branding. From content creation to social engagement, reputation management to
                                        web development, we make it as easy as possible for your business to find, connect with, and
                                        retain customers.
                                    </p>
                                </div>
                                <div className="text-center mt-10">
                                    <a href="/manage-services" className="btn btn-primary px-3">Learn More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container-fluid bg-white py-5 a-gif">
                <div className="container">
                    <div className="row align-items-center g-4">
                        <div className="col-12 col-md-4">
                            <div className="text-center">
                                <h2 className="fw-bold pb-4">
                                    Take control of your online brand across 100+ digital services.
                                </h2>
                                <Link to="/request-demo" className="btn btn-primary px-3">Get Started</Link>
                            </div>
                        </div>
                        <div className="col-12 col-md-8">
                            <img className="w-100 d-block" src="images/network.gif" alt="Network" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="container py-5 case-study">
                <div className="row">
                    <div>
                        <h3 className="fw-bolder">Case Studies</h3>
                        <p>Learn more about business case studies.</p>
                    </div>
                    {data && data.map((ele, i) => {
                        return (
                            <div className="d-flex py-3" key={i}>
                                <a className="case-image me-3" target="_blank" href={ele.link} rel="noreferrer">
                                    {ele.images ? <img src={ele.images} data-src={ele.images} alt="case-study" />
                                        :
                                        <img src="images/lazy-loader.svg" data-src="images/lazy-loader.svg" alt="case-study" />
                                    }
                                </a>
                                <div className="case-content">
                                    <a href={ele.link} target="_blank" className="heading" rel="noreferrer">{ele.title}</a>
                                    {<div dangerouslySetInnerHTML={{ __html: ele.content }} />}
                                    <a className="read-more py-2" target="_blank" href={ele.link} rel="noreferrer">Continue reading </a>
                                </div>
                            </div>

                        )
                    })}

                    <div>
                        <div className="case-study mt-4">
                            <a href={caseUrl} target="_blank" className="read-more" rel="noreferrer">All Case Studies</a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container-fluid py-5 bg-white">
                <div className="container pt-5">
                    <div className="row">
                        <div className="col-sm-12">
                            <h3 className="main-heading fw-bolder">Reviews</h3>
                            <p>See what our customers have to say about Amazio.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="owl-carousel owl-theme" id="testimonials">
                                <div className="item mt-5 p-3 h-100">
                                    <div className="reviewer-box">
                                        <img src="images/reviews/digital-tandem.jpg" alt="digital tandem" />
                                        <h5 className="fw-bold mb-1 text-center">Digital Tandem</h5>
                                        <h6 className="m-0 text-muted text-center">&nbsp;</h6>
                                        <p className="mt-3 px-1">
                                            <strong>Amazio</strong> helped grow our customer engagement by managing our social content & reviews while being brand concious. Our local marketing has improved and so has our customer base. Thank you for the guidance & insights based on true analytics. Highly recommened their managed services!
                                        </p>
                                    </div>
                                </div>
                                <div className="item mt-5 p-3 h-100">
                                    <div className="reviewer-box">
                                        <img src="images/reviews/mega-marketing-group.jpg" alt="i3 Mega Marketing Group" />
                                        <h5 className="fw-bold mb-1 text-center">i3 Mega Marketing Group</h5>
                                        <h6 className="m-0 text-muted text-center">&nbsp;</h6>
                                        <p className="mt-3 px-1">
                                            <strong>Amazio has been a great partner to work with.</strong> The dashboard has helped us manage our client’s online presence and scale our agency’s services.
                                        </p>
                                    </div>
                                </div>
                                <div className="item mt-5 p-3 h-100">
                                    <div className="reviewer-box">
                                        <img src="images/reviews/cara-leising.jpg" alt="Cara Leising" />
                                        <h5 className="fw-bold mb-1 text-center">Cara Leising</h5>
                                        <h6 className="m-0 text-muted text-center">Liberty Hotel</h6>
                                        <p className="mt-3 px-1">
                                            <strong>Amazio helps the Liberty Hotel spread the word of what is happening at our hotel but also in travel.</strong> Since we became a client, Amazio has made a post to our facebook page 5 dates a month since June and has had a reach up to 295 with Engagements up to 136. Their understanding of the digital world gives us the ability to share more than just a photo and a thought. If I need to reach someone from Amazio, they are a quick phone call away. You will find everyone in that company to be professional and quick to respond.
                                        </p>
                                    </div>
                                </div>
                                <div className="item mt-5 p-3 h-100">
                                    <div className="reviewer-box">
                                        <img src="images/reviews/nicol.jpg" alt="Nicole Arend" />
                                        <h5 className="fw-bold mb-1 text-center">Nicole Arend</h5>
                                        <h6 className="m-0 text-muted text-center">Senior Manager, SOCi</h6>
                                        <p className="mt-3 px-1">
                                            <strong>AMAZIO</strong> has created a robust platform that now manages clients digital presence, social media and reputation management on both a self-serve and full-service experience. By partnering with SOCi, AMAZIO has grown rapidly in the short months we came together to provide customers an integrated experience with listings management, social media publishing, and review management. I recommend AMAZIO to any SMB company looking for a single platform that is easy to use and manage their digital presence and care.
                                        </p>
                                    </div>
                                </div>
                                <div className="item mt-5 p-3 h-100">
                                    <div className="reviewer-box">
                                        <img src="images/reviews/samaj-saves.jpg" alt="Samaj Saves Lives" />
                                        <h5 className="fw-bold mb-1 text-center">Bhupen Amin</h5>
                                        <h6 className="m-0 text-muted text-center">Samaj Saves Lives</h6>
                                        <p className="mt-3 px-1">
                                            <strong>Amazio has been a wonderful partner and support for our program.</strong><br />
                                            They have been proactive and helped us establish as online social media presence. The staff are proffessional who genuinely care about your goals and performance.<br />
                                            Thank you to the entire Amazio team!
                                        </p>
                                    </div>
                                </div>
                                <div className="item mt-5 p-3 h-100">
                                    <div className="reviewer-box">
                                        <img src="images/reviews/pfp.jpg" alt="Perfect Promo" />
                                        <h5 className="fw-bold mb-1 text-center">Perfect Promo</h5>
                                        <h6 className="m-0 text-muted text-center">&nbsp;</h6>
                                        <p className="mt-3 px-1">
                                            <strong>Great service!</strong><br />
                                            Just the few weeks they started managing my online presence my sales greatly improved. I didn't know how important social media could be for a growing business until the team at Amazio explained it. Awesome people to work with, very understanding and Knowledgeable.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container-fluid py-5 border-top numbers">
                <div className="container">
                    <div className="main-heading2 text-center mb-5">
                        <h3 className="main-heading fw-bolder">Our 2020 Numbers</h3>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-4 mb-2">
                            <div className="bg-white m-2 number-box p-3 rounded-3 shadow-sm text-center">
                                <h3 className="counter-value glow fw-bold py-2" data-count="49771">49771</h3>
                                <p className="mb-0">New Listings Created</p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 mb-2">
                            <div className="bg-white m-2 number-box p-3 rounded-3 shadow-sm text-center">
                                <h3 className="counter-value glow fw-bold py-2" data-count="342351">342351</h3>
                                <p className="mb-0">Digital Listings Updated</p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 mb-2">
                            <div className="bg-white m-2 number-box p-3 rounded-3 shadow-sm text-center">
                                <h3 className="counter-value glow fw-bold py-2" data-count="14400000">14400000</h3>
                                <p className="mb-0">Views On Google Map</p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 mb-2">
                            <div className="bg-white m-2 number-box p-3 rounded-3 shadow-sm text-center">
                                <h3 className="counter-value glow fw-bold py-2" data-count="3100000">3100000</h3>
                                <p className="mb-0">Views on Search</p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 mb-2">
                            <div className="bg-white m-2 number-box p-3 rounded-3 shadow-sm text-center">
                                <h3 className="counter-value glow fw-bold py-2" data-count="37000">37000</h3>
                                <p className="mb-0">Instagram Followers</p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 mb-2">
                            <div className="bg-white m-2 number-box p-3 rounded-3 shadow-sm text-center">
                                <h3 className="counter-value glow fw-bold py-2" data-count="19000">19000</h3>
                                <p className="mb-0">Facebook Likes</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ModalSection title="Scan Listing" modal_id="scanModal" size="xl">
                <div className={`text-center py-5 ${pageLoader ? ' d-block' : ' d-none'}`}>
                    <div className="spinner-border" style={{ width: '3rem', height: '3rem' }} role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    <p className="mt-3">Please wait for response. This may take a while...</p>
                </div>
                {!existLocation ?
                    <iframe src={newURL} id="newUrl" className={`border-0 w-100 mb-0 ${pageLoader ? ' d-none' : ' d-block'}`} style={{ height: 'calc(100vh - 3.5rem - 72px)', width: `60rem` }} onLoad={() => setPageLoader(false)} title="Report"></iframe>
                    :
                    !pageLoader &&
                    <div className='col-md-12'>
                        <div className='row mb-3 border-bottom p-3'>
                            <img src={'images/logo.svg'} alt="Amazio-Logo" width="170" height="31" />
                        </div>
                        <div className='row d-flex justify-content-center p-4'>
                            <div className='col-md-8 text-center'>
                                <h3 className='fw-bold text-center my-4'>
                                    This location is already registered with Amazio, Please see details about it on Amazio Dashboard.
                                </h3>
                                {!context?.auth &&
                                    <Link to={'/login'} className='btn btn-primary mt-3' onClick={() => document.querySelector('#scanModal [data-bs-dismiss="close"]').click()}>Login</Link>
                                }
                            </div>
                        </div>
                    </div>
                }
            </ModalSection>

            {/* <div className="modal fade" id="scanModal" tabIndex="-1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title font-wt700">Scan Listing</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-0">
                            <iframe id="newUrl" className="border-0 w-100 mb-0" style={{ height: 'calc(100vh - 3.5rem - 72px)' }} title="Scan Now"></iframe>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default Home