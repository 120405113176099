import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CED_LOGIN_API, fetchData, GET_AUTH_USER_INFO } from "../../components/Service";
import * as Elements from "../../components/Elements";
import { Context } from "../../components/Context";

function CEDLogin() {
    window.document.title = "Authenticating...";
    const [query] = useSearchParams();
    const navigate = useNavigate()
    const [tinyloader, setTinyloader] = useState(false);
    const [context, setContext] = useContext(Context);

    useEffect(() => {
        if (query.get('nav') && query.get('token')) {
            setTinyloader(true)
            fetchData(CED_LOGIN_API + '?nav=' + query.get('nav') + '&token=' + query.get('token'), 'POST', '', true, false, (res) => {
                if (res.records) {
                    localStorage.setItem('amazio-token', res.records.accessToken)

                    fetchData(GET_AUTH_USER_INFO, 'GET', '', true, false, (res1) => {
                        setTinyloader(false);
                        if (res1.records && context) {
                            setContext(prevState => ({
                                ...prevState,
                                auth: res1.records
                            }))

                            navigate('/users/' + res.records.redirectTo)
                        }
                    });
                }
            });
        } else {
            navigate('/')
        }
    }, [query, setContext, navigate, context])

    return (
        <div className="w-100 min-vh-100 d-flex justify-content-center align-items-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-sm-12 text-center">
                        <h1 className="display-1 fw-bold text-black">Authenticating</h1>
                        <h2>Please wait while we authenticate.</h2>
                        <hr className="maxw-180 mx-auto" />
                        <button type="button" className="btn btn-primary px-4 ms-2" onClick={(e) => navigate('/')} disabled={tinyloader}>
                            <Elements.ButtonSpinner load={tinyloader} btnName="Go To Home" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CEDLogin