import React, { useContext, useEffect, useState } from 'react'
import { fetchData, GET_LOCATION_UNIQUE_URL, ADD_DYANMIC_URL } from "../../components/Service";
import { validateForm, initialFormState, reactSelectValidation } from "../../components/Helper"
import Select from "react-select"
import { Context } from '../../components/Context';

function Onboarding() {
    window.document.title = "Onboarding";
    const [location, setLocation] = useState();
    const [context] = useContext(Context)
    const [selectedOption, setSelectedOption] = useState(null);
    const [formField, setFormField] = useState({
        id: "",
        location_id: "",
        unique_url: "",
        facebook_page_url: "",
        google_my_business_url: "",
        posting_approval: "",
        location_name: ""
    });

    useEffect(() => {
        fetchData(GET_LOCATION_UNIQUE_URL, 'GET', '', true, false, (res) => {
            if (res.records) {
                setLocation(res.records)
            }
        });
    }, []);

    const handleInputChange = (inputValue) => {
        setSelectedOption(inputValue ?? []);
        reactSelectValidation(inputValue, 'location_id', false);

        let location_id = (inputValue && inputValue.value) ? inputValue.value : 0;
        if (location_id) {
            fetchData(ADD_DYANMIC_URL + '?location_id=' + location_id, 'GET', '', true, true, (res) => {
                if (res.records) {
                    setFormField(res.records[0])
                }
            });
        }

    };

    const addDyanmicUrl = (e) => {
        let formData = new FormData(document.getElementById('addDyanmicUrlForm'));

        if (validateForm(e, 'addDyanmicUrlForm')) {
            fetchData(ADD_DYANMIC_URL, 'POST', formData, true, true, (res) => {
                if (res.success) {
                    var formId = document.getElementById("addDyanmicUrlForm");
                    formId.classList.remove('was-validated');
                    formId.reset()
                    setFormField('')
                    setSelectedOption('')
                }
            })
        }
    }

    const resetForm = () => {
        initialFormState("addDyanmicUrlForm", setFormField)
        setSelectedOption(null)
    }

    return (
        <section className="admin-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 text-center text-md-start ms-xl-0 ms-md-4">
                        <h4 className="page-heading">Add Dynamic Report URL</h4>
                    </div>
                </div>
                <div className="row  justify-content-center">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-body p-4">
                                <form className="needs-validation" method="post" id="addDyanmicUrlForm" noValidate>
                                    <div className="mb-3 row">
                                        <label htmlFor="question" className="col-sm-3 col-form-label">Location<span className="text-danger">*</span> :</label>
                                        <div className="col-sm-9">
                                            <Select
                                                placeholder={'-- Select Location --'}
                                                name="location_id"
                                                isClearable={true}
                                                closeMenuOnSelect={true}
                                                options={location}
                                                id="location_id"
                                                className="basic-multi-select react-select"
                                                value={selectedOption}
                                                onChange={(e) => handleInputChange(e)}
                                                required
                                            />
                                            <div className="invalid-feedback">The location field is required.</div>
                                        </div>

                                    </div>
                                    <div className="mb-3 row">
                                        <label htmlFor="unique_url" className="col-sm-3 col-form-label">Dynamic Report URL:</label>
                                        <div className="col-sm-9">
                                            <input type="url" className="form-control" id="unique_url" name="unique_url" defaultValue={formField.unique_url} placeholder="Dynamic Report URL" />
                                            <div className="invalid-feedback">The location field is required.</div>
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <label htmlFor="google_my_business_url" className="col-sm-3 col-form-label">Google Business URL:</label>
                                        <div className="col-sm-9">
                                            <input type="url" className="form-control" id="google_my_business_url" name="google_my_business_url" defaultValue={formField.google_my_business_url} placeholder="Google Business URL" />
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <label htmlFor="facebook_page_url" className="col-sm-3 col-form-label">Facebook URL:</label>
                                        <div className="col-sm-9">
                                            <input type="url" className="form-control" id="facebook_page_url" name="facebook_page_url" defaultValue={formField.facebook_page_url} placeholder="Facebook URL" />
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <label htmlFor="posting_approval" className="col-sm-3 col-form-label">Posting Approvals:</label>
                                        <div className="col-sm-9">
                                            <input type="url" className="form-control" id="posting_approval" name="posting_approval" defaultValue={formField.posting_approval} placeholder="Posting Approvals" />
                                        </div>
                                    </div>
                                    <div className="text-end">
                                        <button type="button" className="btn btn-outline-danger px-4 ms-2" onClick={resetForm}>Clear</button>
                                        {context && context.auth && context.auth.user_group_id === 1 && context.auth.super_admin === "no" ? 
                                            context.auth.permission && context.auth.permission.map((item, key) => {
                                                return(
                                                    <span key={key}>
                                                        {item.permission === "Onboarding" &&
                                                            <>
                                                                {item.add === "yes" ? 
                                                                    <button type="button" className="btn btn-primary px-4 ms-2" onClick={(e) => addDyanmicUrl(e)}>
                                                                        Add
                                                                    </button>
                                                                : 
                                                                    <span className="d-inline-block" tabIndex="0" data-toggle="tooltip" title="No permission">
                                                                        <button className="btn btn-primary px-4 ms-2" style={{ pointerEvents: "none"}} type="button" disabled>Add</button>
                                                                    </span>
                                                                }
                                                            </>
                                                        }
                                                    </span>
                                                )
                                            })
                                        : 
                                            <button type="button" className="btn btn-primary px-4 ms-2" onClick={(e) => addDyanmicUrl(e)}>
                                                Add
                                            </button>
                                        }
                                        
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Onboarding
