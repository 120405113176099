import { Link } from "react-router-dom";
import { PageTitleSection } from "../../../components/Elements";

function Industries() {
    window.document.title = "Amazio | Industries";

    return (
        <>
            <PageTitleSection title="Industries"/>

            <section className="pb-5">
                <div className="container">
                    <div className="row align-items-center justify-content-center justify-content-sm-between py-3">
                        <div className="col-7 col-sm-3 order-sm-last py-3">
                            <img src="images/hospitality.gif" className="w-100 rounded-lg" alt="Hospitality"/>
                        </div>
                        <div className="col-sm-7 py-3">
                            <h3 className="fw-bold">Hospitality</h3>
                            <h5 className="fw-bold text-secondary">Guests, visitors, or strangers.</h5>
                            <p className="my-3 lh-md">
                                Today’s travelers are mobile and looking to act quickly. In fact, 65% of today’s same-day bookings are made via smartphone. With the Amazio Knowledge Engine, you deliver digital knowledge listings are always accurate, actionable, and differentiated.
                            </p>
                            <Link to='hospitality' className="read-more">Learn More</Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <hr className="opacity-10"/>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center justify-content-sm-between py-4">
                        <div className="col-7 col-sm-3 py-3">
                            <img src="images/retail.gif" className="w-100 rounded-lg" alt="Retail"/>
                        </div>
                        <div className="col-sm-7 py-3">
                            <h3 className="fw-bold">Retail</h3>
                            <h5 className="fw-bold text-secondary">Jack of All Trades</h5>
                            <p className="my-3 lh-md">
                                Local searchers have the highest intent to visit your store. In fact, 76% of people who use location search on mobile phones visit a business within a day, and 28% of those searches result in a purchase. Maximize your opportunity to turn those searchers into customers with the Amazio Knowledge Engine.
                            </p>
                            <Link to='retail' className="read-more">Learn More</Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <hr className="opacity-10" />
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center justify-content-sm-between py-4">
                        <div className="col-7 col-sm-3 py-3  order-sm-last">
                            <img src="images/food.gif" className="w-100 rounded-lg" alt="Food"/>
                        </div>
                        <div className="col-sm-7 py-3">
                            <h3 className="fw-bold">Food</h3>
                            <h5 className="fw-bold text-secondary">Hungry consumers find you fast</h5>
                            <p className="my-3 text-common">
                                Smart devices, voice assistants, and AI are changing the way people make dining decisions. Consumers search for food more than any other business category, so you need to make sure you show up no matter where, when, or how they're searching.
                            </p>
                            <Link to='food' className="read-more">Learn More</Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <hr className="opacity-10" />
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center justify-content-sm-between py-4">
                        <div className="col-7 col-sm-3 py-3">
                            <img src="images/healthcare.gif" className="w-100 rounded-lg" alt="Healthcare"/>
                        </div>
                        <div className="col-sm-7 py-3">
                            <h3 className="fw-bold">Healthcare</h3>
                            <h5 className="fw-bold text-secondary">Attract more patients</h5>
                            <p className="my-3 text-common">
                                Our Healthcare Knowledge Engine is purpose-built to help health systems of all sizes solve their office, facility, and physician digital knowledge challenges, make their websites more discoverable in search, and ultimately attract new patients in the moments that matter most.
                            </p>
                            <Link to='healthcare' className="read-more">Learn More</Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <hr className="opacity-10" />
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center justify-content-sm-between py-4">
                        <div className="col-7 col-sm-3 order-sm-last py-3">
                            <img src="images/insurance.gif" className="w-100 rounded-lg" alt="Insurance"/>
                        </div>
                        <div className="col-sm-7 py-3">
                            <h3 className="fw-bold">Insurance</h3>
                            <h5 className="fw-bold text-secondary">Accurate, timely information</h5>
                            <p className="my-3 text-common">
                                We value the work provided by public servants at the federal, state, and local levels. We also recognize the challenges presented by today’s ever-evolving technologies and ever-tightening budgets. The Amazio Knowledge Engine, securely hosted through Amazon Web Services (AWS).
                            </p>
                            <Link to='insurance' className="read-more">Learn More</Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <hr className="opacity-10" />
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center justify-content-sm-between py-4">
                        <div className="col-7 col-sm-3 py-3">
                            <img src="images/government.gif" className="w-100 rounded-lg" alt="Government"/>
                        </div>
                        <div className="col-sm-7 py-3">
                            <h3 className="fw-bold">Government</h3>
                            <h5 className="fw-bold text-secondary">Accurate, timely information</h5>
                            <p className="my-3 text-common">
                                At Amazio, we value the work provided by public servants at the federal, state, and local levels. We also recognize the challenges presented by today’s ever-evolving technologies and ever-tightening budgets. The Amazio Knowledge Engine, securely hosted through Amazon Web Services (AWS), helps you deliver perfect information into the palm of every citizen.
                            </p>
                            <Link to='government' className="read-more">Learn More</Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <hr className="opacity-10" />
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center justify-content-sm-between py-4">
                        <div className="col-7 col-sm-3 order-sm-last py-3">
                            <img src="images/franchises.gif" className="w-100 rounded-lg" alt="Franchises"/>
                        </div>
                        <div className="col-sm-7 py-3">
                            <h3 className="fw-bold">Franchises</h3>
                            <h5 className="fw-bold text-secondary">All-in-one enterprise</h5>
                            <p className="my-3 text-common">
                                You don’t have a lot of time; after all, you have a business to run. And yet, you know that you must attract today’s consumers to thrive. Maximize your visibility with the Amazio Knowledge Engine. Read on to learn how we can supercharge your business.
                            </p>
                            <Link to='franchises' className="read-more">Learn More</Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <hr className="opacity-10" />
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center justify-content-sm-between py-4">
                        <div className="col-7 col-sm-3 py-3">
                            <img src="images/automotive.gif" className="w-100 rounded-lg" alt="Automotive"/>
                        </div>
                        <div className="col-sm-7 py-3">
                            <h3 className="fw-bold">Automotive</h3>
                            <h5 className="fw-bold text-secondary">Drive Consumers</h5>
                            <p className="my-3 text-common">
                                Buying and maintaining a car is an intensely local experience. As a manufacturer, dealer or service provider, you aim to be front and center with consumers from their first search to the last mile. Maximize your visibility.
                            </p>
                            <Link to='automotive' className="read-more">Learn More</Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <hr className="opacity-10" />
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center justify-content-sm-between py-4">
                        <div className="col-7 col-sm-3 order-sm-last py-3">
                            <img src="images/small-business.gif" className="w-100 rounded-lg" alt="Small Business"/>
                        </div>
                        <div className="col-sm-7 py-3">
                            <h3 className="fw-bold">Small Business</h3>
                            <h5 className="fw-bold text-secondary">Local search no matter your size.</h5>
                            <p className="my-3 text-common">
                                You don’t have a lot of time; after all, you have a business to run. And yet, you know that you must attract today’s mobile consumers to thrive. With Amazio, it just takes a few minutes a month to update, control, and manage how your business appears online.
                            </p>
                            <Link to='small-business' className="read-more">Learn More</Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Industries