import * as Elements from "../../components/Elements";

function PrintMedia() {
	window.document.title = "Amazio | Print Media";
	return (
		<>
			<Elements.PageTitleSection title="Print Media"/>

			<section className="py-5">
				<div className="container py-md-4">
					<div className="row g-4 justify-content-between">
						<div className="col-md-6 col-lg-5">
							<div className="ratio ratio-80p">
								<img src="images/services/print.jpg" className="objfit-cover objpos-center shadow rounded p-2" alt="Print Media"/>
							</div>
						</div>
						<div className="col-md-6 col-lg-7 ps-lg-4 align-self-center">
							<p className="lh-md">
								Print Media is known to create a lasting impression. Research shows print media leaves a more lasting impression that digital media and that can make a huge difference to your brand. Print Media leads are alternate and innovative solutions to deliver what the market needs. We provide top class print media services that leverage our in-house designers, artists, and content developers.
							</p>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default PrintMedia