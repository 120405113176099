import React, { useContext, useState, useEffect } from "react";
import { createRoot } from 'react-dom/client';
import { downloadOnboarding, toastNotify } from "../../components/Helper";
import Datatables, { redrawDataTable } from '../../components/Datatables';
import * as Elements from "../../components/Elements";
import { now } from "lodash";
import { Context } from "../../components/Context";
import { ONBOARDING_LIST, ONBOARDING_DETAIL, ONBOARDING_DELETE, ONBOARDING_STATUS, fetchData } from "../../components/Service";

function OnBoardingList() {
    window.document.title = "Onboarding Details";
    const [boardingData, setBindingData] = useState(false)
    const [onboardingId, setOnboardingId] = useState(false)
    const [refresh, setRefresh] = useState(now())
    const [tinyloader, setTinyLoader] = useState(false)
    const [context] = useContext(Context)
    const [formField, setFormField] = useState({
        id: "",
        status: "",
        old_status: ""
    });

    const [dt] = useState({
        dt_url: ONBOARDING_LIST,
        dt_name: 'boarding',
        dt_export: true,
        dt_order: [[0, 'desc']],
        dt_column: [
            { data: 'location_name', name: 'location_name', title: 'Location Name' },
            { data: 'address', name: 'address', title: 'Address' },
            { data: 'city', name: 'city', title: 'City' },
            { data: 'state', name: 'state', title: 'State' },
            { data: 'zip_code', name: 'zip_code', title: 'Zip' },
            { data: 'phone', name: 'phone', title: 'Phone' },
            { data: 'contact_email', name: 'contact_email', title: 'Email' },
            { data: 'created_at', name: 'created_at', title: 'Date' },
            { data: 'status', name: 'status', title: 'Status', class: "text-nowrap text-center" },
            { data: 'action', name: 'action', title: 'Action', class: "text-nowrap text-center noExport", sortable: false, searchable: false, orderable: false }
        ],
        dt_column_defs: [
            {
                targets: 8,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <Elements.Badge type={cellData === 'new' ? 'info' : cellData === 'pending' ? 'warning' : cellData === 'processing' ? 'primary' : cellData === 'approved' ? 'secondary' : cellData === 'onboarded' ? 'success' : 'danger'} name={cellData === 'new' ? 'new' : cellData === 'pending' ? 'pending' : cellData === 'processing' ? 'processing' : cellData === 'approved' ? 'approved' : cellData === 'onboarded' ? 'onboarded' : 'rejected'} func={() => setFormField(prevState => ({ ...prevState, id: records.id, status: cellData, old_status: cellData }))} role="button" toggle="modal" target="#statusChange" />,
                    )
                }
            },
            {
                targets: 9,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <div className="d-flex text-nowrap">
                            {context && context.auth && context.auth.user_group_id === 1 && context.auth.super_admin === "no" ?
                                context.auth.permission && context.auth.permission.map((item, key) => {
                                    return (
                                        <div key={key}>
                                            {item.permission === "Onboarding Details" &&
                                                <>
                                                    {item.view === "yes" &&
                                                        <button className="btn btn-sm text-primary mx-1" title="View" data-bs-toggle="modal" data-bs-target="#onBoardingDetail" onClick={() => viewRecords(records.id)} >
                                                            <svg className="icon fs-5" role="img"><use href="#icon_eye" /></svg>
                                                        </button>
                                                    }
                                                    {item.delete === "yes" ?
                                                        <Elements.DeleteButton tid="confModal" func={() => setOnboardingId(records.id)} />
                                                        :
                                                        <Elements.DisableButton title="No Permission" btnType="danger" icon="#icon_trash" />
                                                    }
                                                </>
                                            }
                                        </div>
                                    )
                                })
                                :
                                <>
                                    <button className="btn btn-sm text-primary mx-1" title="View" data-bs-toggle="modal" data-bs-target="#onBoardingDetail" onClick={() => viewRecords(records.id)} >
                                        <svg className="icon fs-5" role="img"><use href="#icon_eye" /></svg>
                                    </button>
                                    <Elements.DeleteButton tid="confModal" func={() => setOnboardingId(records.id)} />
                                </>
                            }
                        </div>
                    )
                }
            }
        ]
    });

    const statusChange = (e) => {
        setTinyLoader(true)
        if (formField.status !== formField.old_status) {
            fetchData(ONBOARDING_STATUS, 'PATCH', formField, true, false, (res) => {
                setTinyLoader(false)
                if (res.success) {
                    setRefresh(now())
                    document.querySelector('#statusChange [data-bs-close="add"]').click()
                }
            }, false, false, false);
        } else {
            setTinyLoader(false)
            toastNotify({ error: 'Nothing to update current status and new status are same.' })
        }
    }

    const viewRecords = (id) => {
        fetchData(`${ONBOARDING_DETAIL}/${id}`, 'GET', '', true, false, (res) => {
            if (res.records) {
                setBindingData(res.records)
            }
        }, false, false, false);
    }

    const deleteRecords = (id) => {
        document.querySelector('#confModal [data-bs-dismiss="modal"]').click()
        fetchData(`${ONBOARDING_DELETE}/${id}`, 'DELETE', '', true, false, (res) => {
            if (res.success) {
                setRefresh(now())
            }
        });
    }

    useEffect(() => {
        if (dt) {
            redrawDataTable(dt);
        }
    }, [dt, refresh])

    return (
        <>
            <Elements.ListSection title='Onboarding Details'>
                <div className="col-sm-12">
                    <div className="card border-0 shadow-sm rounded-10">
                        <div className="card-body">
                            <Datatables dt_name="boarding" dataPageLength="15" />
                        </div>
                    </div>
                </div>
            </Elements.ListSection>

            <Elements.ModalSection title="Onboarding Details" modal_id="onBoardingDetail" size="xl" download={true} page_loader={false}>
                <div className="col-12 d-flex justify-content-end px-4">
                    <span className="btn btn-primary disabled-button w-auto" title="Download Pdf" onClick={() => downloadOnboarding('print-area', 'Onboarding', setTinyLoader)} >
                        {!tinyloader ?
                            <span>
                                <svg className="icon" role="button"><use href="#icon_pdf" /></svg> Download Pdf
                            </span>
                            :
                            <div className="spinner-border spinner-border-sm mx-3" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>}
                    </span>
                </div>
                {boardingData &&
                    <p dangerouslySetInnerHTML={{ __html: boardingData }} id="print-area" className="p-4"></p>
                }
            </Elements.ModalSection>
            <Elements.ModalSection title="Status Change" modal_id="statusChange" size="md" page_loader={false}>
                <form className="needs-validation p-3" id="statusChange" method="post" noValidate>
                    <div className="row">
                        <label htmlFor="onboarding_status" className="col-md-3 text-md-end col-form-label">Status<strong className="text-danger">*</strong>:</label>
                        <div className="col-md-8 mb-3">
                            <select className="form-select" id="onboarding_status" name="onboarding_status" value={formField && formField.status} onChange={(e) => setFormField((prevState) => ({ ...prevState, status: e.target.value }))} required>
                                <option value="new">New</option>
                                <option value="pending">Pending</option>
                                <option value="processing">Processing</option>
                                <option value="approved">Approved</option>
                                <option value="onboarded">Onboarded</option>
                                <option value="rejected">Rejected</option>
                            </select>
                            <div className="invalid-feedback">The select platform field is required</div>
                        </div>
                        <div className="row ms-2">
                            <div className="col-md-11 text-end">

                                <button type="button" className="btn btn-primary minw-100" onClick={(e) => statusChange(e)} disabled={tinyloader}>
                                    <Elements.ButtonSpinner load={tinyloader} btnName="Submit" />
                                </button>
                                <button type="button" className="btn btn-outline-danger px-4 ms-2" data-bs-close="add" data-bs-dismiss="modal" aria-label="Close">Close</button>
                            </div>
                        </div>
                    </div>
                </form>
            </Elements.ModalSection>
            <Elements.ConfirmationModal msg="Are you sure to delete this record?" method={() => deleteRecords(onboardingId)} />
        </>
    )
}
export default OnBoardingList;