import { PageTitleSection } from "../../components/Elements";
import VideoModal from "../../components/VideoModal";

const Resources = () => {

    window.document.title = "Amazio | Resources";

    const videoModalPlay = (video_poster, video_url) => {
        var modal = document.getElementById('video_play_modal');
        var append_video = document.getElementById('video_append_modal');
        var video_module = `<video poster="`+video_poster+`" controls id="`+video_url+`"><source src="`+video_url+`" type="video/mp4"></video>`;
        append_video.innerHTML = video_module;
        modal.classList.add('active');
        document.getElementById(video_url).play()
    }

    return (
        <>
            <PageTitleSection title="Resources"/>

            <section className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-4 col-sm-4">
                            <div className="resource-videos" onClick={() => videoModalPlay('/images/howlogin-poster-1.png', 'images/videos/login.mp4')} title="Play">
                                <h2 className="sub-heading">How to login Amazio Dashboard</h2>
                                <img alt="How to login Amazio Dashboard?" src="/images/howlogin-poster-1.png" className="img-fluid" />
                                <span>
                                    <svg className="icon"><use href="#icon_play"></use></svg>
                                </span>
                            </div>
                        </div>
                        <div className="col-4 col-sm-4">
                            <div className="resource-videos" onClick={() => videoModalPlay('/images/howlogin-poster.png', 'images/videos/dashboard-Overview.mp4')} title="Play">
                                <h2 className="sub-heading">How to Navigate Amazio Dashboard Overview</h2>
                                <img alt="How to Navigate Amazio Dashboard Overview?" src="/images/howlogin-poster.png" className="img-fluid" />
                                <span>
                                    <svg className="icon"><use href="#icon_play"></use></svg>
                                </span>
                            </div>
                        </div>
                        <div className="col-4 col-sm-4">
                            <div className="resource-videos" onClick={() => videoModalPlay('/images/dashboar-listing.png', 'images/videos/dashboard-Overview-listing.mp4')} title="Play">
                                <h2 className="sub-heading">How to Navigate Amazio Dashboard Listing</h2>
                                <img alt="How to Navigate Amazio Dashboard Listing?" src="/images/dashboar-listing.png" className="img-fluid" />
                                <span>
                                    <svg className="icon"><use href="#icon_play"></use></svg>
                                </span>
                            </div>
                        </div>
                        <div className="col-4 col-sm-4">
                            <div className="resource-videos" onClick={() => videoModalPlay('/images/dashboard-overview-2.png', 'images/videos/dashboard-Social-Insight.mp4')} title="Play">
                                <h2 className="sub-heading">How to Navigate Amazio Dashboard-Social Insight</h2>
                                <img alt="How to Navigate Amazio Dashboard-Social Insight?" src="/images/dashboard-overview-2.png" className="img-fluid" />
                                <span>
                                    <svg className="icon"><use href="#icon_play"></use></svg>
                                </span>
                            </div>
                        </div>
                        <div className="col-4 col-sm-4">
                            <div className="resource-videos" onClick={() => videoModalPlay('/images/dashboard-Reputation.png', 'images/videos/dashboard-Reputation.mp4')} title="Play">
                                <h2 className="sub-heading">How to manage your Reputation on Amazio</h2>
                                <img alt="How to manage your Reputation on Amazio?" src="/images/dashboard-Reputation.png" className="img-fluid" />
                                <span>
                                    <svg className="icon"><use href="#icon_play"></use></svg>
                                </span>
                            </div>
                        </div>
                        <div className="col-4 col-sm-4">
                            <div className="resource-videos" onClick={() => videoModalPlay('/images/Digital-Listings.png', 'images/videos/Digital-Listings.mp4')} title="Play">
                                <h2 className="sub-heading">How to View your Digital Listings on Amazio</h2>
                                <img alt="How to View your Digital Listings on Amazio?" src="/images/Digital-Listings.png" className="img-fluid" />
                                <span>
                                    <svg className="icon"><use href="#icon_play"></use></svg>
                                </span>
                            </div>
                        </div>
                        <div className="col-4 col-sm-4">
                            <div className="resource-videos" onClick={() => videoModalPlay('/images/search-engines.png', 'images/videos/search-engines.mp4')} title="Play">
                                <h2 className="sub-heading">How to view location on Search Engine</h2>
                                <img alt="How to view location on Search Engine?" src="/images/search-engines.png" className="img-fluid" />
                                <span>
                                    <svg className="icon"><use href="#icon_play"></use></svg>
                                </span>
                            </div>
                        </div>
                        <div className="col-4 col-sm-4">
                            <div className="resource-videos" onClick={() => videoModalPlay('/images/how-to-share-linkedin.png', 'images/videos/How to share LinkedIn admin access with Amazio United States.mp4')} title="Play">
                                <h2 className="sub-heading">How to share LinkedIn admin access with Amazio United States</h2>
                                <img alt="How to share LinkedIn admin access with Amazio United States?" src="/images/how-to-share-linkedin.png" className="img-fluid" />
                                <span>
                                    <svg className="icon"><use href="#icon_play"></use></svg>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row tab">
                        <div className="col-sm-12">
                            <div className="accordion" id="accordionFlushExample">
                                <div className="accordion-item recources-accordian">
                                    <h2 className="accordion-header" id="flush-headingFive">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                        What are the image guidelines for posting to various social media networks?
                                    </button>
                                    </h2>
                                    <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body bg-white">
                                            <p className="sub-heading py-2"> Instagram/LinkedIn Guidelines: </p>
                                            <ul className="resourse-list">
                                                <li>Instagram images must be within a 4:5 to 1.91:1 aspect ratio range.</li>
                                                <li>Image must be more than 200px wide.</li>
                                            </ul>
                                            <p className="sub-heading py-2"> Google Guidelines: </p>
                                            <ul className="resourse-list">
                                                <li>Photos must be in one of these formats: JPG, GIF, PNG, TIFF, BMP..</li>
                                                <li>Each photo must be smaller than 5MB.</li>
                                                <li>We recommend adding photos that are larger than 720 x 720 pixels and smaller than 3000 x 3000 pixels.</li>
                                                <li>The longer dimension of the photo should be no more than twice the shorter dimension. Landscape photos look better than portrait photos on Google products.</li>
                                                <li>Each location can have no more than 25 additional photos.</li>
                                                <li>The photo should be in focus, well-lit, have no photoshop alterations, and no excessive use of filters. The image should represent reality.</li>
                                                <li>If your photos don't fit these standards, we may not be able to display them on Google products.</li>
                                            </ul>
                                            <p className="sub-heading py-2"> FB/Twitter: </p>
                                            <ul className="resourse-list">
                                                <li>No specifications</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item recources-accordian">
                                    <h2 className="accordion-header" id="flush-headingSix">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                        What are the video guidelines for posting to various social media networks?
                                    </button>
                                    </h2>
                                    <div id="flush-collapseSix" className="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body bg-white">
                                            <p className="sub-heading"> What are the Instagram Video requirements needed to successfully upload video content? </p>
                                            <p className="mt-3 mb-1"><strong>Instagram is strict with its video requirements meaning that some videos that upload for Facebook may not upload for Instagram.</strong></p>
                                            <p><strong>Instagram requires that uploaded content meet these specifications:</strong></p>
                                            <ul className="resourse-list">
                                                <li>Container: MOV or MP4 (MPEG-4 Part 14), no edit lists, moov atom at the front of the file.</li>
                                                <li>Audio codec: AAC, 48khz sample rate maximum, 1 or 2 channels (mono or stereo).</li>
                                                <li>Video codec: HEVC or H264, progressive scan, closed GOP, 4:2:0 chroma subsampling.</li>
                                                <li>Frame rate: 23-60 FPS.</li>
                                                <li>Picture size:</li>
                                                <li>Maximum columns (horizontal pixels): 1920</li>
                                                <li>Minimum aspect ratio [cols / rows]: 4 / 5</li>
                                                <li>Maximum aspect ratio [cols / rows]: 16 / 9</li>
                                                <li>Bitrate: VBR, 5Mbps maximum</li>
                                                <li>Duration: 60 seconds maximum, 3 seconds minimum</li>
                                                <li>File size: 100MB maximum</li>
                                                <li>Videos that don't meet the specifications will result in a validation message</li>
                                            </ul>
                                            <p className="sub-heading py-2"> What are the best Twitter video file formats? </p>

                                            <p><strong>These are the recommended file formats for Twitter videos:</strong></p>
                                            <ul className="resourse-list">
                                                <li>Recommended Video Codec: H264 High Profile</li>
                                                <li>Recommended Frame Rates: 30 FPS, 60 FPS</li>
                                                <li>Recommended Video Resolution: 1280x720 (landscape), 720x1280 (portrait), 720x720 (square)</li>
                                                <li>Recommended Minimum Video Bitrate: 5,000 kbps</li>
                                                <li>Recommended Minimum Audio Bitrate: 128 kbps</li>
                                                <li>Recommended Audio Codec: AAC LC</li>
                                                <li>Recommended Aspect Ratio: 16:9 (landscape or portrait), 1:1 (square)</li>
                                            </ul>
                                            <p><strong>These are the advanced file formats for Twitter videos:</strong></p>
                                            <ul className="resourse-list">
                                                <li>Frame rate must be 60 FPS or less</li>
                                                <li>Dimensions must be between 32x32 and 1280x1024</li>
                                                <li>File size must not exceed 512 MB</li>
                                                <li>Duration must be between 0.5 seconds and 140 seconds</li>
                                                <li>Aspect ratio must be between 1:3 and 3:1</li>
                                                <li>Must have 1:1 pixel aspect ratio</li>
                                                <li>Only YUV 4:2:0 pixel format is supported</li>
                                                <li>Audio must be AAC with Low Complexity profile. High-Efficiency AAC is not supported</li>
                                                <li>Audio must be mono or stereo, not 5.1 or greater</li>
                                                <li>Must not have open GOP</li>
                                                <li>Must use progressive scan</li>
                                            </ul>
                                            <p className="sub-heading py-2">GIF</p>
                                            <ul className="resourse-list" style={{marginTop: "5px"}}>
                                                <li>Only one GIF is allowed per post.</li>
                                                <li>GIFs cannot be combined with photos to post.</li>
                                                <li>GIFs cannot be combined with videos to post.</li>
                                                <li>The GIF dimensions and size are network specific and are covered in the per network recommendation below:</li>
                                                <li>Facebook provides recommended specifications for uploading .gif files.</li>

                                                <li>High quality animations</li>
                                                <li>Links ending with .gif</li>
                                                <li>GIFs with a file size less than 8 MB</li>
                                                <li>GIFs with less than 20 percent text on the image</li>
                                                <li>Avoiding flashy, excessively grainy GIFs (stay compliant with their advertising rules)</li>

                                                <li>The full documentation from Facebook can be found here.</li>

                                                <li>Twitter provides recommended specifications for uploading .gif files.</li>
                                                <li>A GIF may fail during Tweet creation even if it is within the file size limit.</li>
                                            </ul>
                                                <p><strong>Adhere to the following constraints to improve success rates.</strong></p>
                                                <ul className="resourse-list">
                                                <li>Resolution should be &lt;= 1280x1080 (width x height)</li>
                                                <li>Number of frames &lt;= 350</li>
                                                <li>Number of pixels (width * height * num_frames) &lt;= 300 million</li>
                                                <li>File size &lt;= 15Mb</li>
                                            </ul>
                                            <p className="sub-heading py-2"> In general, here are the best sizes for sharing images on social media. </p>
                                            <ul className="resourse-list">
                                                <li>Facebook – 1,200 x 628</li>
                                                <li>Twitter – 1,024 x 512</li>
                                                <li>LinkedIn – 700 x 400</li>
                                                <li>Instagram – 1,080 x 1,080</li>
                                                <li>Google Posts – larger than 720 x 720 pixels and smaller than 3000 x 3000 pixels</li>
                                                <li>Supported image file types are PNG, JPEG, and GIF.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <VideoModal />
        </>
    )
}
export default Resources;
