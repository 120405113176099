import React, { useState, useEffect, useContext } from "react";
import { fetchData, GET_LOCATION_UNIQUE_URL} from "../../components/Service";
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select'
import { Context } from '../../components/Context';
import { toastNotify } from "../../components/Helper";

function Dashboard() {
    window.document.title = "Dashboard";
    const navigate = useNavigate();
    const [location, setLocation] = useState();
    const [selectedlocation, setSelectedLocation] = useState(false);
    const [context] = useContext(Context)

    let addLocationBox = '';
    let searchLocationBox = '';

    {context && context.auth && context.auth.user_group_id === 1 && context.auth.super_admin === "no" ?
        context.auth.permission && context.auth.permission.map((item, key) => {

            if(item.permission === 'Manage Location'){
                if(item.add === 'yes') {
                    addLocationBox = 'd-block'
                }else{
                    addLocationBox = 'd-none'
                }

            }
            
            if(item.permission === 'Common Dashboard'){
                if(item.view === 'yes') {
                    searchLocationBox = 'd-block';
                }else{
                    searchLocationBox = 'd-none'
                }
            }
        })
        : 
        addLocationBox = searchLocationBox = 'd-block'
        
    }


    const selectLocation = (e) => {
        if (e.length > 10) {
            toastNotify({ error: 'You can\'t select more than 10 location at a time.' })
            return
        }
        setSelectedLocation(e ?? false)
    }
    
    useEffect(() => {
        fetchData(GET_LOCATION_UNIQUE_URL, 'GET', '', true, false, (res) => {
            if (res.records) {
                setLocation(res.records)
            }
        });
        
    }, []);

    const getData = (e) => {
        if(selectedlocation.length > 0){
            localStorage.setItem('selected_location_dashboard', JSON.stringify(selectedlocation))
            navigate('/admin/admin-dashboard')
        }else{
            toastNotify({ error: 'Please select atleast one location' })
        }
    }

    

    return(
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="welcome-hero mt-2">
                                <img src="images/network.jpg" alt=""/>
                                <h2>Welcome {context && context.auth && <span>{context.auth.first_name} {context.auth.last_name}</span>}</h2>
                            </div>
                        </div>
                    </div>

                    <div className="row g-4 mt-3">
                        <div className={`col-md-6 col-lg-5 col-xl-4 ${addLocationBox}`}>
                            <div className="bg-white d-flex align-items-center rounded h-100 p-3 p-md-4">
                                <div className="ratio ratio-1x1 mmw-70 rounded-pill bg-success-light-15">
                                    <div className="d-flex align-items-center justify-content-center text-success">
                                        <svg className="icon fs-2" role="img"><use href="#icon_plus"></use></svg>
                                    </div>
                                </div>
                                <div className="ps-3 mmw-220"> 
                                    <Link to='/admin/manage-business-list' state={{ prevPage: 'dashboard'} } className="mb-1 btn btn-dark px-5 w-100">Add Location</Link> 
                                </div>
                            </div>
                        </div>
                        <div className={`col-md-6 col-lg-5 col-xl-4 ${searchLocationBox}`}>
                            <div className="bg-white d-flex align-items-center h-100 p-3 p-md-4 rounded">
                                <div className="ratio ratio-1x1 mmw-70 rounded-pill bg-success-light-15 l1">
                                    <div className="d-flex align-items-center justify-content-center text-success">
                                        <svg className="icon fs-2" role="img"><use href="#icon_map"></use></svg>
                                    </div>
                                </div>
                                    <div className="ps-3 w-100 ">
                                        <h6 className="mb-2">Select Location</h6>
                                        <div className="row">
                                            <div className="col-8">
                                                <Select
                                                    value={selectedlocation}
                                                    name="location[]"
                                                    isClearable={true}
                                                    closeMenuOnSelect={false}
                                                    options={location ?? []}
                                                    isMulti={true}
                                                    className="basic-multi-select react-select"
                                                    onChange={(e) => selectLocation(e)}
                                                    id="location_id"
                                                    required
                                                />
                                            </div>
                                            <div className="col-4 text-center">
                                                <button className="btn btn-primary " type="button" onClick={(e) =>getData(e)} >Apply</button>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )   
}
export default Dashboard