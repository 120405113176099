import React, { useState } from 'react';
import { ButtonSpinner } from '../../components/Elements';
import { toastNotify, validateForm, passwordValidate } from '../../components/Helper';
import { fetchData, ADMIN_CHANGE_PASSWORD } from '../../components/Service';

function ChangePassword(){
    window.document.title = "Change Password";
    const [tinyloader, setTinyloader] = useState(false);
    const [strongPassword, setStrongPassword] = useState(false);

    const changePassword = (e) => {
        
        let form = document.getElementById('changepasswordForm')
        let data = new FormData(form);
        if(validateForm(e)) {
            if(strongPassword){
                setTinyloader(true);
                fetchData(ADMIN_CHANGE_PASSWORD, 'POST', data, true, true, (res) => {
                    setTinyloader(false);
                    if (res.success) {
                        form.classList.remove('was-validated')
                        form.reset()
                    }  
                });
            }else{
                let msg = "Your password should be minimum 8 characters and includes at least a number, uppercase and lower case letter and 1 symbol";
                toastNotify({'error' : msg})
            }
        };
    }

    return(
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2 text-center text-md-start ms-xl-0 ms-md-4">
                            <h4 className="page-heading text-center">Change Password</h4>
                        </div>
                    </div>

                    <form className="row needs-validation" id="changepasswordForm" noValidate>
                        <div className="col-lg-8 offset-lg-2">
                            <div className="row mx-0 bg-white rounded p-4 shadow-sm">
                                <div className="row mb-3">
                                    <label htmlFor="current_password" className="col-md-3 text-md-end col-form-label">Current Password<strong className="text-danger">*</strong>:</label>
                                    <div className="col-md-8">
                                        <input type="password" className="form-control" name="current_password" id="current_password" placeholder="Current Password" autoComplete="Current Password" spellCheck={false} required />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="new_password" className="col-md-3 text-md-end col-form-label">New Password<strong className="text-danger">*</strong>:</label>
                                    <div className="col-md-8 position-relative">
                                        <input type="password" className="form-control" onKeyUp={(e) => passwordValidate(e, setStrongPassword)} name="new_password" id='new_password' placeholder="New Password" autoComplete="New Password" spellCheck={false} required />
                                        <ul className="passhints">
                                            <li>Be at least 6 character.</li>
                                            <li>At least 1 small letter.</li>
                                            <li>At least 1 capital letter.</li>
                                            <li>At least 1 number.</li>
                                            <li>At least 1 special character.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="confirm_password" className="col-md-3 text-md-end col-form-label">New Confirm  Password<strong className="text-danger">*</strong>:</label>
                                    <div className="col-md-8">
                                        <input type="password" className="form-control" name="confirm_password" id="confirm_password" placeholder="New Confirm Password" autoComplete="New Confirm Password" spellCheck={false} required />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-11 text-end">
                                        <button type="button" className="btn btn-primary fw-medium" onClick={(e) => changePassword(e)} disabled={tinyloader}>
                                            <ButtonSpinner load={tinyloader} btnName="Update" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}

export default ChangePassword