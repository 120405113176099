import * as Elements from "../../components/Elements";

function DigitalContent() {
	window.document.title = "Amazio | Digital Content";

	return (
		<>
			<Elements.PageTitleSection title="Digital Content/Imaging Services"/>

			<section className="py-5">
				<div className="container py-md-4">
					<div className="row g-4 justify-content-between">
						<div className="col-md-6 col-lg-5">
							<img src="images/services/digital-content-Imaging-services.jpg" alt="Digital Content" className="w-100 shadow rounded p-2"/>
						</div>
						<div className="col-md-6 col-lg-7 ps-lg-4 align-self-center">
							<p className="lh-md">
								We offer professional content writing services that are customized to your specific requirements. Our dedicated team
								provides solutions in web searching and trending new stories. We also offer image validation, video validation,
								captioning, meta tagging of data relevant to images & videos and build content around the same.
							</p>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default DigitalContent