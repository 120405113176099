import React, {useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toastNotify, validateForm, zohoChat, initialFormState, intlTel_phone, validatePhone} from "../../components/Helper";
import { fetchData, REQUEST_CALL} from "../../components/Service";
import {Context} from "../../components/Context";

function UserSupport() {
    window.document.title = "User Support";
    const [context] = useContext(Context)
    const [intlTel, setIntlTel] = useState()

    let callback = 0;
    context && context.auth && context.auth.permission && context.auth.permission.map((ele, num) => ele.permission === "Supports" && ele.add === 'yes' && ++callback )
    const submitRequest = (e) => {
        let formData = new FormData(document.getElementById('addRequest'));
        if (validateForm(e) && validatePhone(intlTel, '#phone')) {
            let phone = document.getElementById("phone").value
            phone  = phone.replaceAll("-","")
            formData.append('phone', phone)
            fetchData(REQUEST_CALL, 'POST', formData, true, true, (res) => {
                if(res.success){
                    toastNotify('success', res.success)
                    document.querySelector('#request_to_callback [data-bs-dismiss="modal"]').click()  
                     var formId = document.getElementById("addRequest");
                     formId.classList.remove('was-validated');
                     formId.reset()
                    
                } else {
                    if (Object.keys(res.errors).length > 0) {
                        [res.errors].forEach((ele) => {
                            Object.keys(ele).map((v, i) => {
                                return toastNotify('danger', ele[v])
                            });
                        })
                    } else {
                        toastNotify('danger', res.error)
                    }
                }
            })
        }
       
    }

    useEffect(() => {
        setIntlTel(intlTel_phone('#phone'))
        zohoChat();
        return () => {
            document.getElementById('scriptid').remove();
            document.getElementById('zsiqscript').remove();
        }
    },[])

    const showChat = () => {
        document.getElementById('zsiq_float').classList.remove('d-none');
        document.getElementById('zsiq_agtpic').click();
    }

    return (
        <>
        <style>{`
            .zsiq_theme1.zsiq_floatmain {opacity: 0 !important; pointer-events :none; display:none !important;}
            .siqanim{
                transform: scale(1)!important;
            }
        `}
        </style>
        <section className="admin-wrapper">
            <div className="container-fluid">
                <div className="row justify-content-center user-setting">
                    <div className="row justify-content-center pt-4">
                        <div className="col-md-3" onClick={showChat} role="button">
                            <div className="card p-4 text-center shadow mb-5 mx-2 ts-1p1-350ms">
                                <span  className="text-decoration-none text-black">
                                    <img alt="amazio" className="mmw-100" src="images/live-chat.svg" />
                                    <h2 className="fs-5 fw-bold pt-3">Live Chat</h2>
                                </span>
                            </div>
                        </div>
                        {callback !== 0 &&<>
                            <div className="col-md-3">
                                <div className="card p-4 text-center shadow mb-5 mx-2 ts-1p1-350ms">
                                     <button className="text-decoration-none text-black border-0 bg-transparent" data-bs-toggle="modal" data-bs-target="#request_to_callback">
                                        <img alt="amazio" className="mmw-100" src="images/request-call-back.svg" />
                                        <h2 className="fs-5 fw-bold pt-3">Request Callback</h2>
                                        </button>
                                </div>
                        </div>
                        <div className="col-md-3" >
                            <div className="card p-4 text-center shadow mb-5 mx-2 ts-1p1-350ms">
                                <Link to="../view-tickets-list" className="text-decoration-none text-black">
                                            <img alt="amazio" className="mmw-100" src="images/create-ticket.svg" />
                                            <h2 className="fs-5 fw-bold pt-3">Ticket</h2>
                                </Link>
                            </div>
                        </div></>
                        }         
                        <div className="col-md-3">
                            <div className="card p-4 text-center shadow mb-5 mx-2 ts-1p1-350ms">
                                <button className="text-decoration-none text-black border-0 bg-transparent" data-bs-toggle="modal" data-bs-target="#contact_us">
                                    <img alt="amazio" className="mmw-100" src="images/contact-us.svg" />
                                    <h2 className="fs-5 fw-bold pt-3">Contact Us</h2>
                                </button>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card p-4 text-center shadow mb-5 mx-2 ts-1p1-350ms">
                                <a href={`https://desk.zoho.com/portal/amazio7/en/kb`} target="_blank" rel="noopener noreferrer" className="text-decoration-none text-black">
                                    <img alt="amazio" className="mmw-100" src="images/knowledge-base (1).svg" />
                                    <h2 className="fs-5 fw-bold pt-3">Knowledge Base</h2>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Request  CallBack Model */}
            <div className="modal fade" id="request_to_callback" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header bg-light">
                            <h5 className="modal-title" id="exampleModalLabel">Request to Callback</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={(e) => initialFormState('addRequest')}></button>
                        </div>
                        <div className="modal-body">
                            <form className="needs-validation" id="addRequest" method="post"  noValidate>
                                <div className="mb-3">
                                    <input type="text" className="form-control" id="name" name="name" placeholder="Name*" required />
                                    <div className="invalid-feedback">Name field is required.</div>
                                </div>
                                <div className="mb-3">
                                    <input type="email" className="form-control" id="email" name="email" placeholder="Email*" required/>
                                    <div className="invalid-feedback">Email field is required.</div>
                                </div>
                                <div className="mb-3">
                                    <input type="text" className="form-control" id="pc_number" name="pc_number" placeholder="PC number (Business Alias)*" required/>
                                    <div className="invalid-feedback">PC number (Business Alias) field is required.</div>
                                </div>
                                <div className="mb-3 intl_phone">
                                    <input type="tel" className="form-control" id="phone" name="phone" placeholder="Phone Number*" required/>
                                    <div className="invalid-feedback">Phone number field is required.</div>
                                </div>
                                <div className="mb-3">
                                    <textarea rows="5" className="form-control" id="comment" name="comment" maxLength={500} placeholder="Comment*" required/>
                                    <div className="invalid-feedback">Comment field is required.</div>

                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-danger" data-bs-dismiss="modal" onClick={(e) => initialFormState('addRequest')}>Close</button>
                            <button type="button" className="btn btn-primary" onClick={submitRequest}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>

            {/** Contact us Model */}
            <div className="modal fade" id="contact_us" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header bg-light">
                            <h5 className="modal-title" id="exampleModalLabel">Contact Us</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <ul className="list-unstyled m-0 py-4">
                                <li className="pb-4">
                                    <span className="text-black text-decoration-none d-flex align-items-center">
                                        <i className="bi bi-geo-alt-fill bg-light-blue wh-35 rounded-circle text-white d-flex  align-items-center justify-content-center me-2"></i>
                                        1303 W Walnut Hill Ln, Ste 360 Irving TX 75038. USA
                                    </span>
                                </li>
                                <li className="pb-4">
                                    <a href="mailto:info@amazio.com" className="text-primary text-decoration-none d-flex align-items-center">
                                        <i className="bi bi-envelope-fill bg-light-blue wh-35 rounded-circle text-white d-flex  align-items-center justify-content-center me-2"></i>
                                        info@amazio.com
                                    </a>
                                </li>
                                <li className="pb-4">
                                    <a href="tel:972-362-4700" className="text-primary text-decoration-none d-flex align-items-center">
                                        <i className="bi bi-telephone-fill bg-light-blue wh-35 rounded-circle text-white d-flex  align-items-center justify-content-center me-2"></i>
                                        972-362-4700
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default UserSupport
