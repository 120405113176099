import React, { useState, useEffect, useContext } from "react";
import {createRoot} from 'react-dom/client';
import { Context }  from "../../components/Context"
import { SUBSCRIBERS, fetchData, DELETE_SUBSCRIBER } from "../../components/Service";
import Datatables, { redrawDataTable  } from '../../components/Datatables';
import * as Elements from "../../components/Elements";

function ManageSubscriber() {
    window.document.title = "Manage Subscribers";

    const [reload, setReload] = useState(false);
    const [context] = useContext(Context)
    const [deleteId, setDeleteId] = useState(0);

    const deleteRecords = (id) => {
        fetchData(`${DELETE_SUBSCRIBER}?id=${id}`, 'POST', '', true, false, (res) => {
            document.querySelector('#confModal [data-bs-dismiss="modal"]').click()
            if (res.success) {
                reload === true ? setReload(false) : setReload(true);
            }
        });
    }
    const [dt] = useState({
        dt_url: SUBSCRIBERS,
        dt_name: 'subscribers',
        dt_export : true,
        dt_order: [[ 0, 'desc' ]],
        dt_column: [
            { data: 'id', name: 'id', title: 'ID', class:"text-nowrap minw-130px" }, 
            { data: 'name', name: 'name', title: 'Business Name' }, 
            { data: 'email', name: 'email', title: 'Business Email' },
            { data: 'action', name: 'action', title: 'Action', class:"text-nowrap text-center noExport", sortable:false, searchable:false, orderable: false }
        ],
        dt_column_defs: [
            {
                targets: 3,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <div className="d-flex text-nowrap">

                            {context && context.auth && context.auth.user_group_id === 1 && context.auth.super_admin === "no" ? 
                                context.auth.permission && context.auth.permission.map((item, key) => {
                                    return(
                                        <div key={key}>
                                            {item.permission === "Manage Subscribers" &&
                                                <>
                                                {item.delete === "yes" ?
                                                    <Elements.DeleteButton tid="confModal" func={ () => setDeleteId(records.id) }/>
                                                :
                                                    <Elements.DisableButton btnType="danger" icon="#icon_trash" />
                                                }
                                                </>
                                            }
                                        </div>
                                    )
                                })
                            :
                                <Elements.DeleteButton tid="confModal" func={ () => setDeleteId(records.id) }/>
                            }
                            
                        </div>
                    )
                }
            }
        ]
    });

    useEffect(() => {
        if(dt){
            redrawDataTable(dt);
        }
    }, [dt, reload])

    return (
        <>
            <Elements.ListSection title='Manage Subscribers'>
                <div className="col-sm-12">
                    <div className="card border-0 shadow-sm rounded-10">
                        <div className="card-body">
                            <Datatables dt_name="subscribers" dataPageLength="15" />
                        </div>
                    </div>
                </div>
            </Elements.ListSection>
            <Elements.ConfirmationModal msg="Are you sure to delete this record?" method={() => deleteRecords(deleteId)} />
        </>
    )
}
export default ManageSubscriber;