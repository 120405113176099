import * as Elements from "../../components/Elements";

function KnowledgeTags() {
	window.document.title = "Amazio | Knowledge Tags";

	return (
		<>
			<Elements.PageTitleSection title="Knowledge Tags"/>
			
			<section className="py-5">
				<div className="container py-md-4">
					<div className="row g-4 justify-content-between">
						<div className="col-md-6 col-lg-5">
							<img src="images/services/knowledge.jpg" className="w-100 shadow rounded p-2" alt="KnowledgeTags"/>
						</div>
						<div className="col-md-6 col-lg-7 ps-lg-4 align-self-center">
							<p className="lh-md">
								Whether they're looking for your people, places, or products, consumers and intelligent services (like search engines)
								consider your website the authoritative source for facts about your brand. We make your business information Optimized,
								Accurate and Magnetic.
							</p>
						</div>
					</div>
				</div>
			</section>    
		</>
	)
}

export default KnowledgeTags