import React, { useState, useEffect, useContext } from "react";
import { createRoot } from 'react-dom/client'
import Select from 'react-select'
import { validateForm, initialFormState, __echoText, reactSelectValidation } from "../../components/Helper";
import { fetchData, ADD_PRICE_LISTING, PLAN_PRICE_LISTING, UPDATE_PRICE_LISTING, DELETE_PRICE_LISTING, PERMISSION_LISTING } from "../../components/Service";
import * as Elements from "../../components/Elements";
import Datatables, { redrawDataTable } from '../../components/Datatables';
import { Context } from "../../components/Context";
import $ from 'jquery'
import { now } from "lodash";

function ManagePlan() {
    window.document.title = "Plan & Price";

    const [tinyloader, setTinyloader] = useState(false);
    const [context] = useContext(Context)
    const [pageLoader, setPageLoader] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [permissions, setPermissions] = useState(null)
    const [offcanvastitle, setOffcanvastitle] = useState("Add New Plan")
    const [refresh, setRefresh] = useState(now())
    const [formfield, setFormfield] = useState({
        id: "",
        service: "",
        cost: "",
        offer_price: "",
        self_managed_plan: "",
        amazio_managed_plan: "",
        self_managed_desc: "",
        amazio_managed_desc: "",
        permissions: ""
    })

    const addPlanMethod = async (e) => {
        let formdata = new FormData(document.getElementById('addNewPlanForm'));
        let id = document.querySelector('#addNewPlan [name="id"]').value;

        if (validateForm(e, 'addNewPlanForm')) {
            setTinyloader(true);

            fetchData(id ? UPDATE_PRICE_LISTING : ADD_PRICE_LISTING, 'POST', formdata, true, true, (res) => {
                setTinyloader(false);
                if (res.success) {
                    setRefresh(now());
                    document.querySelector('#addNewPlan [data-bs-close="add"]').click()
                }
            });
        }
    }

    const editForm = (getid) => {
        setOffcanvastitle("Update Plan And Price");

        getPermission()

        setPageLoader(true)

        fetchData(UPDATE_PRICE_LISTING + '?id=' + getid, 'GET', '', true, false, (res) => {
            setPageLoader(false)

            if (res.records) {
                setFormfield(prevState => ({
                    ...prevState,
                    ...res.records
                }))
                setSelectedOption(res.records.slugs)
            }
        }, false, false, false);
    }

    const deleteRecords = (id) => {
        fetchData(DELETE_PRICE_LISTING + '?id=' + id, 'POST', '', true, false, (res) => {
            document.querySelector('#confModal [data-bs-dismiss="modal"]').click();
            if (res.success) {
                setRefresh(now());
            }
        });
    }

    const [dt] = useState({
        dt_url: PLAN_PRICE_LISTING,
        dt_name: 'planPriceList',
        dt_export: false,
        dt_column: [
            { data: 'id', name: 'id', title: 'Id' },
            { data: 'service', name: 'name', title: 'Service', width: 250 },
            { data: 'cost', name: 'recurring', title: 'Cost' },
            { data: 'offer_price', name: 'offer_price', title: 'Offer Price' },
            { data: 'self_managed_plan', name: 'simple', title: 'Self Managed', width: 250 },
            { data: 'amazio_managed_plan', name: 'plus', title: 'Amazio Managed', width: 250 },
            { data: 'self_managed_desc', name: 'tooltip', title: 'Self Managed Content', width: 500 },
            { data: 'amazio_managed_desc', name: 'tooltip_1', title: 'Amazio Managed Content', width: 500 },
            { data: 'action', name: 'action', title: 'Action', class: "text-nowrap text-center noExport", sortable: false, searchable: false, orderable: false, }
        ],
        dt_column_defs: [
            {
                targets: 3,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>{__echoText(records.offer_price)}</>)
                }
            },
            {
                targets: 4, createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <>
                            {records.self_managed_plan === 'dash' && <span>&#9747;</span>}
                            {records.self_managed_plan === 'tick' && <span>&#10003;</span>}
                            {records.self_managed_plan === 'checkbox' && <span>&#9744;</span>}
                            {records.self_managed_plan === 'checked' && <span>&#9745;</span>}
                        </>
                    )
                }
            },
            {
                targets: 5, createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <>
                            {records.amazio_managed_plan === 'dash' && <span>&#9747;</span>}
                            {records.amazio_managed_plan === 'tick' && <span>&#10003;</span>}
                            {records.amazio_managed_plan === 'checkbox' && <span>&#9744;</span>}
                            {records.amazio_managed_plan === 'checked' && <span>&#9745;</span>}
                        </>
                    )
                }
            },
            {
                targets: 6,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>{__echoText(records.self_managed_desc)}</>)
                }
            },
            {
                targets: 7,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>{__echoText(records.amazio_managed_desc)}</>)
                }
            },
            {
                targets: 8,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <div className="d-flex">
                            {context && context.auth && context.auth.user_group_id === 1 && context.auth.super_admin === "no" ? 
                                context.auth.permission && context.auth.permission.map((item, key) => {
                                    return(
                                        <div key={key}>
                                            {item.permission === "Manage Plan & Prices" &&
                                                <>
                                                    {item.edit === "yes" ? 
                                                        <Elements.EditButton tid="addNewPlan" func={() => editForm(records.id)} />
                                                    : 
                                                        <Elements.DisableButton btnType="success" icon="#icon_edit" />
                                                    }
                                                    {item.delete === "yes" ? 
                                                        <Elements.DeleteButton tid="confModal" func={() => setFormfield(prevState => ({ ...prevState, id: records.id }))} />
                                                    : 
                                                        <Elements.DisableButton btnType="danger" icon="#icon_trash" />
                                                    }
                                                </>
                                            }
                                        </div>
                                    )
                                })
                            : 
                                <>
                                    <Elements.EditButton tid="addNewPlan" func={() => editForm(records.id)} />
                                    <Elements.DeleteButton tid="confModal" func={() => setFormfield(prevState => ({ ...prevState, id: records.id }))} />
                                </>
                            }
                            
                            
                        </div>

                    )
                }
            }
        ]
    });

    const getPermission = () => {
        if ((!permissions || permissions.length === 0)) {
            setPageLoader(true)
            fetchData(PERMISSION_LISTING, 'GET', '', true, false, (res) => {
                setPageLoader(false)
                if (res.records) {
                    setPermissions(res.records)
                }
            }, false, false, false)
        }
    }

    window.OpenAddNewOpoup = () => {
        getPermission()
    }

    useEffect(() => {
        redrawDataTable(dt);

        if (!$('.dt-custom-filter button').hasClass('add-new')) {
            {context && context.auth && context.auth.user_group_id === 1 && context.auth.super_admin === "no" ? 
                context.auth.permission && context.auth.permission.map((item, key) => {
                    return(
                        <div key={key}>
                            {item.permission === "Manage Plan & Prices" &&
                                <>
                                    {item.add === "yes" &&
                                        $('.dt-custom-filter').append(`<button type="button" class="btn btn-dark px-3 text-nowrap me-2 rounded-0 add-new" data-bs-toggle="modal" data-bs-target="#addNewPlan    " onclick="OpenAddNewOpoup()" >
                                            <svg class="icon tscale-1p3 me-1" role="img"><use href="#icon_plus"></use></svg> Add New
                                        </button>`)
                                    }
                                </>
                            }
                        </div>
                    )
                })
            : 
                $('.dt-custom-filter').append(`<button type="button" class="btn btn-dark px-3 text-nowrap me-2 rounded-0 add-new" data-bs-toggle="modal" data-bs-target="#addNewPlan    " onclick="OpenAddNewOpoup()" >
                    <svg class="icon tscale-1p3 me-1" role="img"><use href="#icon_plus"></use></svg> Add New
                </button>`)
            }
        }

        document.getElementById('addNewPlan').addEventListener('hidden.bs.modal', function (e) {
            initialFormState('addNewPlanForm')
            $(".invalid-custom-feedback").remove();
            $(".react-select").removeClass('is-invalid is-valid');

            setFormfield({
                self_managed_desc: "",
                amazio_managed_desc: ""
            });

            setSelectedOption(null);

            setOffcanvastitle("Add New Plan");
        });

        document.getElementById('confModal').addEventListener('hidden.bs.modal', function (e) {
            setFormfield(prevState => ({ ...prevState, id: '' }))
        });
    }, [dt, refresh])
    return (
        <>
            <Elements.ListSection title='Manage Plans & Pricing'>
                <div className="col-sm-12">
                    <div className="card border-0 shadow-sm rounded-10">
                        <div className="card-body">
                            <Datatables dt_name="planPriceList" dataPageLength="10" />
                        </div>
                    </div>
                </div>
            </Elements.ListSection>


            <Elements.ModalSection title={offcanvastitle} modal_id="addNewPlan" size="lg" page_loader={pageLoader}>
                <form className="needs-validation p-3" id="addNewPlanForm" >
                    <div className="row mb-3">
                        <input type="text" className="d-none" id="id" name="id" minLength={2} defaultValue={formfield.id} />
                        <label htmlFor="service" className="col-sm-3 col-form-label">Services<strong className="text-danger">*</strong>:</label>
                        <div className="col-sm-9">
                            <input type="text" className="form-control" placeholder="Services" id="service" name="service" defaultValue={formfield.service} onKeyUp={(e) => setFormfield(prevState => ({
                                ...prevState, service: e.target.value
                            }))} required />
                            <div className="invalid-feedback">The services field is required.</div>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label htmlFor="permissions" className="col-sm-3 col-form-label">Permission<strong className="text-danger">*</strong>:</label>
                        <div className="col-sm-9">
                            <Select
                                placeholder="-- Select Permission --"
                                value={selectedOption}
                                options={permissions}
                                isMulti={true}
                                isClearable={true}
                                closeMenuOnSelect={false}
                                name="permissions[]"
                                className={`basic-multi-select react-select`}
                                id="permissions"
                                required
                                onChange={(newValues) => { setSelectedOption(newValues ?? []); reactSelectValidation(newValues, 'permissions'); }}
                            />
                            <div className="invalid-feedback">The permission field is required.</div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label htmlFor="cost" className="col-sm-3 col-form-label">Cost<strong className="text-danger">*</strong>:</label>
                        <div className="col-sm-9">
                            <input type="number" className="form-control" placeholder="Cost" step="any" id="cost" min="0" name="cost" defaultValue={formfield.cost} onKeyUp={(e) => setFormfield(prevState => ({
                                ...prevState, cost: e.target.value
                            }))} required />
                            <div className="invalid-feedback">The cost field is required.</div>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label htmlFor="offer_price" className="col-sm-3 col-form-label">Offer Price:</label>
                        <div className="col-sm-9">
                            <input type="number" className="form-control" placeholder="Offer Price" step="any" id="offer_price" min="0" name="offer_price" defaultValue={formfield.offer_price} onKeyUp={(e) => setFormfield(prevState => ({
                                ...prevState, offer_price: e.target.value
                            }))} />
                            <div className="invalid-feedback">The offer price field should be numeric value.</div>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label htmlFor="self_managed_plan" className="col-sm-3 col-form-label">Self Managed Plan<strong className="text-danger">*</strong>:</label>
                        <div className="col-sm-9">
                            <select className="form-select" name='self_managed_plan' id='self_managed_plan' value={formfield.self_managed_plan} onChange={(e) => setFormfield(prevState => ({ ...prevState, self_managed_plan: e.target.value }))} required>
                                <option value=''>Select Self Managed Category</option>
                                <option value='dash'>Cross (&#9747;)</option>
                                <option value='tick'>Tick (&#10003;)</option>
                                <option value='checkbox'>Check (&#9744;)</option>
                                <option value='checked'>Checked (&#9745;)</option>
                            </select>
                            <div className="invalid-feedback">The self managed plan field is required.</div>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label htmlFor="amazio_managed_plan" className="col-sm-3 col-form-label">Amazio Managed Plan<strong className="text-danger">*</strong>:</label>
                        <div className="col-sm-9">
                            <select className="form-select" name='amazio_managed_plan' id='amazio_managed_plan' value={formfield.amazio_managed_plan} onChange={(e) => setFormfield(prevState => ({ ...prevState, amazio_managed_plan: e.target.value }))} required>
                                <option value=''>Select Amazio Managed Category</option>
                                <option value='dash'>Cross (&#9747;)</option>
                                <option value='tick'>Tick (&#10003;)</option>
                                <option value='checkbox'>Check (&#9744;)</option>
                                <option value='checked'>Checked (&#9745;)</option>
                            </select>
                            <div className="invalid-feedback">The amazio managed plan field is required.</div>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label htmlFor="self_managed_desc" className="col-sm-3 col-form-label">Self Managed<strong className="text-danger">*</strong>:</label>
                        <div className="col-sm-9">
                            <textarea className="form-control" maxLength={500} placeholder="Self Managed" name="self_managed_desc" id="self_managed_desc" defaultValue={formfield.self_managed_desc} onKeyUp={(e) => setFormfield(prevState => ({ ...prevState, self_managed_desc: e.target.value }))} required></textarea>
                            <div className="invalid-feedback">The self managed field is required.</div>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label htmlFor="amazio_managed_desc" className="col-sm-3 col-form-label">Amazio Managed<strong className="text-danger">*</strong>:</label>
                        <div className="col-sm-9">
                            <textarea className="form-control" maxLength={500} placeholder="Amazio Managed" name="amazio_managed_desc" id="amazio_managed_desc" defaultValue={formfield.amazio_managed_desc} onKeyUp={(e) => setFormfield(prevState => ({ ...prevState, amazio_managed_desc: e.target.value }))} required></textarea>
                            <div className="invalid-feedback">The amazio managed field is required.</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 text-end">
                            <button type="button" className="btn btn-outline-danger px-4 ms-2" data-bs-close="add" data-bs-dismiss="modal" aria-label="Close">Close</button>
                            <button type="button" className="btn btn-primary px-4 ms-2" onClick={(e) => addPlanMethod(e)} disabled={tinyloader}>
                                <Elements.ButtonSpinner load={tinyloader} btnName="Submit" />
                            </button>
                        </div>
                    </div>
                    <hr />
                    <div className=" d-flex gap-2 main-section">
                        <div className="bg-light p-2">❌<span className="px-1">No Service</span> </div>
                        <div className="bg-light p-2">✔<span className="px-1">Mandatory Services</span> </div>
                        <div className="bg-light p-2">❏<span className="px-1">Services to Subscribe</span> </div>
                        <div className="bg-light p-2">☑<span className="px-1">Subscribed Services</span> </div>
                    </div>
                </form>
            </Elements.ModalSection>

            <Elements.ConfirmationModal msg="Are you sure to delete this record?" method={() => deleteRecords(formfield.id)} />
        </>
    )
}

export default ManagePlan
