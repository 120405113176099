import React, { useState, useEffect, useContext } from "react";
import { createRoot } from 'react-dom/client'
import { useNavigate } from "react-router-dom";
import { ADMIN_LIST, fetchData, GET_LOCATION, DELETE_USER, DIRECT_LOGIN, ADD_USER, EDIT_USER, USER_PERMISSION, GET_AUTH_USER_INFO } from "../../components/Service";
import { Context } from "../../components/Context";
import * as Elements from "../../components/Elements";
import { validateForm, initialFormState, reactSelectValidation, intlTel_phone, validatePhone } from "../../components/Helper";
import Datatables, { redrawDataTable } from '../../components/Datatables';
import Select from 'react-select'
import _, { now } from "lodash";
import $ from 'jquery'

function ManageAdmins() {
    window.document.title = "Manage Super Admins";
    const navigate = useNavigate();
    const [reload, setReload] = useState(now);
    const [refresh, setRefresh] = useState(now);
    const [context, setContext] = useContext(Context);
    const [tinyloader, setTinyloader] = useState(false);
    const [deleteId, setDeleteId] = useState(0);
    const [editId, setEditId] = useState(false);
    const [userId, setUserId] = useState('');
    const [sociLocation, setSociLocation] = useState([]);
    const [selectedSociLocation, setSelectedSociLocation] = useState([]);
    const [yextLocation, setYextLocation] = useState([]);
    const [selectedYextLocation, setSelectedYextLocation] = useState([]);
    const [groupId, setGroupId] = useState([]);
    const [pageLoader, setPageLoader] = useState(false);
    const [pageLoader1, setPageLoader1] = useState(false);
    const [loadLocation, setLoadLocation] = useState(false);
    const [intlTel, setIntlTel] = useState()

    const [permissionData, setPermissionData] = useState([]);
    const [platform, setPlatform] = useState("Yext");
    const [formData, setFormData] = useState({
        id: '',
        plat_form: '',
        first_name: '',
        last_name: '',
        user_alias: '',
        email: '',
        phone: '',
        location_id: '',
        soci_location_id: '',
        user_group_id: '',
        soci_api_token: '',
        status: ''
    });

    const [dt] = useState({
        dt_url: ADMIN_LIST,
        dt_name: 'usersList',
        dt_export: true,
        dt_column: [
            { data: 'id', name: 'id', title: 'Id' },
            { data: 'first_name', name: 'users.first_name', title: 'First Name', class: "text-nowrap minw-130px" },
            { data: 'last_name', name: 'users.last_name', title: 'Last Name', class: "text-nowrap minw-130px" },
            { data: 'email', name: 'users.email', title: 'Email', class: "minw-130px" },
            { data: 'user_alias', name: 'users.user_alias', title: 'User Alias', class: "text-nowrap minw-130px" },
            { data: 'group_name', name: 'user_groups.name', title: 'Group Name', class: "text-nowrap minw-130px", sortable: false, orderable: false },
            { data: 'role_name', name: 'users.role_name', title: 'Role Type', class: "text-nowrap minw-130px" },
            { data: 'location_name', name: 'location_name', title: 'Location', class: "text-truncate minw-150px", sortable: false, searchable: false, orderable: false },
            { data: 'status', name: 'status', title: 'Status', class: "text-nowrap text-center" },
            { data: 'action', name: 'action', title: 'Action', class: "text-nowrap text-center noExport", sortable: false, searchable: false, orderable: false }
        ],
        dt_column_defs: [
            {
                targets: 5,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>{'Super Admin'}</>)
                },
            },
            {
                targets: 6,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>{'Super Admin'}</>)
                },
            },
            {
                targets: 9,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <div className="d-flex text-nowrap">
                            {/* <Elements.EditButton tid="userModal" func={() => { editUser(records.id) }} />
                            <button className="btn btn-sm text-warning mx-1" data-bs-toggle="modal" data-bs-target="#userPermissionModal" onClick={() => editUserPermission(records.id)}>
                                <svg className="icon fs-4" role="img"><use href="#icon_user" /></svg>
                            </button>
                            <button className="btn btn-sm text-danger mx-1" data-bs-toggle="modal" data-bs-target="#confModal" onClick={() => setDeleteId(records.id)}>
                                <svg className="icon fs-5" role="img"><use href="#icon_trash" /></svg>
                            </button> */}
                            <button className="btn btn-sm text-primary mx-1" onClick={() => userLogin(records.id)}>
                                <svg className="icon fs-5" role="img"><use href="#icon_login" /></svg>
                            </button>
                        </div>
                    )
                }
            }
        ]
    });

    const getLocation = (type) => {
        setPageLoader(true)
        fetchData(GET_LOCATION + '?' + type, 'GET', '', true, false, (res) => {
            if (res.records) {
                if (type === 'soci') { setSociLocation(res.records) }
                else if (type === 'yext') { setYextLocation(res.records); setPageLoader(false) }
                else if (type === 'groupId') { setGroupId(res.records) }
            }
        }, false, false, false);
    }

    window.OpenAddNewOpoup = () => {
        setEditId(false);
    }

    useEffect(() => {
        if (dt) {
            redrawDataTable(dt);
        }

        // if (!$('.dt-custom-filter button').hasClass('add-new')) {
        //     $('.dt-custom-filter').append(`<button type="button" class="btn btn-dark px-3 text-nowrap me-2 rounded-0 add-new" data-bs-toggle="modal" data-bs-target="#userModal" onclick="OpenAddNewOpoup()" >
        //         <svg class="icon tscale-1p3 me-1" role="img"><use href="#icon_plus"></use></svg> Add New
        //     </button>`);
        // }
    }, [dt, reload])

    useEffect(() => {
        document.getElementById("userModal").addEventListener('shown.bs.modal', function (e) {
            setLoadLocation(true)
            setIntlTel(intlTel_phone('#phone'))
        })

        document.getElementById("userModal").addEventListener('hidden.bs.modal', function (e) {
            initialFormState("userForm", setFormData)
            $(".invalid-custom-feedback").remove();
            $(".react-select").removeClass('is-invalid is-valid');
            setPlatform('Yext')
            setSelectedYextLocation([])
            setSelectedSociLocation([])
        })
        document.getElementById("userPermissionModal").addEventListener('hidden.bs.modal', function (e) {
            initialFormState("userPermissionForm")
            setPermissionData([])
        })

        if (loadLocation) {
            getLocation('groupId');
            getLocation('soci');
            getLocation('yext');
        }
    }, [loadLocation])

    const deleteRecords = (id) => {
        fetchData(`${DELETE_USER}?id=${id}`, 'POST', '', true, false, (res) => {
            document.querySelector('#confModal [data-bs-dismiss="modal"]').click()
            if (res.success) {
                setReload(now())
            }
        });
    }

    const statusHandleChange = (options) => {
        setFormData((prevState) => ({
            ...prevState,
            status: options,
        }));
    }

    const groupTypeHandleChange = (options) => {
        setFormData((prevState) => ({
            ...prevState,
            user_group_id: options,
        }));
    }

    const submitUserForm = (event) => {
        let formdata = new FormData(document.getElementById('userForm'));
        formdata.append('id', editId);

        if (validateForm(event, 'userForm') && validatePhone(intlTel, "#phone")) {
            let phone = document.getElementById("phone").value
            phone  = phone.replaceAll("-","")
            formdata.append('phone', phone)
            setTinyloader(true)
            fetchData(editId ? EDIT_USER : ADD_USER, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res.success) {
                    initialFormState("userForm", setFormData)
                    setSelectedYextLocation([])
                    setSelectedSociLocation([])
                    document.querySelector('#userForm [data-bs-dismiss="modal"]').click();
                    setReload(now());
                }
            });
        }
    }

    const editUser = (id) => {
        setEditId(id)

        setPageLoader1(true)

        fetchData(`${EDIT_USER}?id=${id}`, 'GET', '', true, false, (res) => {
            setPageLoader1(false)

            if (res.records) {
                setFormData({
                    id: id,
                    first_name: res.records.first_name,
                    last_name: res.records.last_name,
                    user_alias: res.records.user_alias,
                    email: res.records.email,
                    phone: res.records.phone,
                    user_group_id: res.records.role,
                    plat_form: res.records.platform,
                    soci_api_token: res.records.soci_api_token,
                    status: res.records.status
                })

                setPlatform(res.records.platform)

                setSelectedYextLocation(res.records.selected_location)
                setSelectedSociLocation(res.records.soci_location)
            }
        }, false, false, false);
    }

    const userLogin = (id) => {
        fetchData(`${DIRECT_LOGIN}?id=${id}`, 'GET', '', true, false, (res) => {
            let adminToken = localStorage.getItem('amazio-token')
            localStorage.setItem('amazio-admin-token', adminToken)
            if (res.records) {
                localStorage.setItem('amazio-token', res.records.accessToken)

                fetchData(GET_AUTH_USER_INFO, 'GET', '', true, false, (res) => {
                    setTinyloader(false);
                    if (res.records && context) {
                        setContext(prevState => ({
                            ...prevState,
                            auth: res.records
                        }))
                        if (res.records.user_group_id === 3) {
                            navigate('/users/select-location')
                        } else {
                            navigate('/admin/choose-location')
                        }

                        localStorage.removeItem('location_view_id')
                        localStorage.removeItem('selected_location_dashboard')
                    }
                });
            }
        });
    }

    /* Start: Permission Module */
    useEffect(() => {
        var ct = 0;

        let addAll = 0;
        let editAll = 0;
        let deleteAll = 0;
        let viewAll = 0;

        let addAllChecked = document.querySelector('[data-check_col="add_all"]');
        let editAllChecked = document.querySelector('[data-check_col="edit_all"]');
        let deleteAllChecked = document.querySelector('[data-check_col="delete_all"]');
        let viewAllChecked = document.querySelector('[data-check_col="view_all"]');
        let allChecked = document.querySelector('[data-select_all="select_all"]');

        ct = setInterval(() => {
            document.querySelectorAll(`#userPermissionTable tbody tr`).forEach((el, k) => {
                let checkAll = 0;
                [...el.querySelectorAll(`.d-none.add_all`)].forEach(ev => addAll += parseInt(ev.value));
                addAllChecked.checked = addAll === (k + 1) ? true : false;

                [...el.querySelectorAll(`.d-none.edit_all`)].forEach(ev => editAll += parseInt(ev.value));
                editAllChecked.checked = editAll === (k + 1) ? true : false;

                [...el.querySelectorAll(`.d-none.delete_all`)].forEach(ev => deleteAll += parseInt(ev.value));
                deleteAllChecked.checked = deleteAll === (k + 1) ? true : false;

                [...el.querySelectorAll(`.d-none.view_all`)].forEach(ev => viewAll += parseInt(ev.value));
                viewAllChecked.checked = viewAll === (k + 1) ? true : false;

                [...el.querySelectorAll(`.d-none`)].forEach(ev => checkAll += parseInt(ev.value));
                document.getElementById(`allcheck_${k + 1}`).checked = checkAll === 4 ? true : false;

                allChecked.checked = (addAllChecked.checked === true) && (editAllChecked.checked === true) && (deleteAllChecked.checked === true) && (viewAllChecked.checked === true) ? true : false
            });
            clearTimeout(ct);
        }, 100)
    }, [refresh])

    const editUserPermission = (id) => {
        setPageLoader1(true)
        fetchData(`${USER_PERMISSION}?id=${id}`, 'GET', '', true, false, (res) => {
            setPageLoader1(false)
            if (res.records) {
                setPermissionData(res.records);
                setUserId(res.records[0].user_id);
                setRefresh(now())
            }
        });
    }

    const updateUserPermission = (userId) => {
        let formData = new FormData(document.getElementById('userPermissionForm'))
        fetchData(`${USER_PERMISSION}?id=${userId}`, 'POST', formData, true, true, (res) => {
            if (res) {
                document.querySelector('#userPermissionModal [data-bs-dismiss="modal"]').click()
                reload === true ? setReload(false) : setReload(true);
            }
        });
    }

    $(document).on('change', '[data-check_row]', function () {
        var check_row = $(this).data('check_row');
        $('[data-row=' + check_row + ']').not(this).prop('checked', this.checked);
        checkcol()
    });

    $(document).on('change', '[data-check_col]', function () {
        var check_col = $(this).data('check_col').split('_');
        $('[data-module=' + check_col[0] + ']').not(this).prop('checked', this.checked);
        checkrow()
    });

    $(document).on('change', '[data-select_all]', function () {
        $('[data-module]').not(this).prop('checked', this.checked);
    });

    $(document).on('change', '[data-module]', function () {
        var row = $(this).data('row');
        var col = $(this).data('module');
        if (this.checked) {
            var checked_col = $('[data-module=' + col + ']:not(:checked)').length;
            var checked_row = $('[data-row=' + row + ']:not(:checked)').length;
            if (checked_row === 0) {
                $('[data-check_row=' + row + ']').prop('checked', true);
            }
            if (checked_col === 0) {
                $('[data-check_col=' + col + '_all]').prop('checked', true);
            }
            var checked_all = $('[data-module]:not(:checked)').length;
            if (checked_all === 0) {
                $('[data-select_all]').prop('checked', true);
            }
        } else {
            $('[data-check_row=' + row + '],[data-check_col=' + col + '_all],[data-select_all]').prop('checked', false);
        }
    });

    const checkcol = () => {
        ($('[data-module=add]:not(:checked)').length === 0) ? $('[data-check_col=add_all]').prop('checked', true) : $('[data-check_col=add_all]').prop('checked', false);
        ($('[data-module=edit]:not(:checked)').length === 0) ? $('[data-check_col=edit_all]').prop('checked', true) : $('[data-check_col=edit_all]').prop('checked', false);
        ($('[data-module=delete]:not(:checked)').length === 0) ? $('[data-check_col=delete_all]').prop('checked', true) : $('[data-check_col=delete_all]').prop('checked', false);
        ($('[data-module=view]:not(:checked)').length === 0) ? $('[data-check_col=view_all]').prop('checked', true) : $('[data-check_col=view_all]').prop('checked', false);
        $('#loader').fadeOut();
        ($('[data-module]:not(:checked)').length === 0) ? $('[data-select_all]').prop('checked', true) : $('[data-select_all]').prop('checked', false);
    };
    const checkrow = () => {
        $('[data-module=row]').each(function (i) {
            ($('[data-row=' + i + ']:not(:checked)').length === 0) ? $('[data-check_row=' + i + ']').prop('checked', true) : $('[data-check_row=' + i + ']').prop('checked', false);
        });
    }

    return (
        <>
            <Elements.ListSection title='Manage Super Admins'>
                <div className="col-sm-12">
                    <div className="card border-0 shadow-sm rounded-10">
                        <div className="card-body">
                            <Datatables dt_name="usersList" dataPageLength="15" />
                        </div>
                    </div>
                </div>
            </Elements.ListSection>

            <Elements.ModalSection title={editId ? 'Edit User' : 'Add User'} modal_id="userModal" size="lg">
                {(pageLoader || pageLoader1) && <div className="text-center py-5">
                    <div className="spinner-border" style={{ width: '3rem', height: '3rem' }} role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    <p className="mt-3">Please wait for response. This may take a while...</p>
                </div>}

                <form className={`needs-validation p-3 ${(pageLoader || pageLoader1) ? 'd-none' : 'd-block'}`} id="userForm" noValidate>
                    <div className="row mb-3">
                        <label htmlFor="plat_form" className="col-md-3 text-md-end col-form-label">Select Platform<strong className="text-danger">*</strong>:</label>
                        <div className="col-md-8">
                            <select className="form-select" id="plat_form" name="plat_form" value={platform} onChange={(e) => setPlatform(e.target.value)} required>
                                <option value="Yext">Yext</option>
                                <option value="Soci">Soci</option>
                                <option value="Both">Both</option>
                            </select>
                            <div className="invalid-feedback">The select platform field is required</div>
                        </div>
                    </div>
                    <div className={`row mb-3 ${platform === 'Yext' || platform === 'Both' ? '' : 'd-none'}`} id="yext">
                        <label htmlFor="location_id" className="col-md-3 text-md-end col-form-label">Yext Location<strong className="text-danger">*</strong>:</label>
                        <div className="col-md-8">
                            <Select
                                placeholder={'-- Select Location --'}
                                value={selectedYextLocation}
                                options={yextLocation}
                                isMulti={true}
                                isClearable={true}
                                closeMenuOnSelect={false}
                                onChange={(e) => { reactSelectValidation(e, "location_id"); setSelectedYextLocation(e ?? []) }}
                                isSearchable
                                name="location_id[]"
                                id="location_id"
                                className="basic-multi-select react-select required"
                                required={platform === 'Yext' || platform === 'Both' ? true : false}
                            />
                            <div className="invalid-feedback">The yext location field is required</div>
                        </div>
                    </div>

                    <div className={`row mb-3 ${platform === 'Soci' || platform === 'Both' ? '' : 'd-none'}`} id="soci">
                        <label htmlFor="soci_location" className="col-md-3 text-md-end col-form-label">Soci Location<strong className="text-danger">*</strong>:</label>
                        <div className="col-md-8">
                            <Select
                                placeholder={'-- Select Location --'}
                                value={selectedSociLocation}
                                options={sociLocation}
                                isMulti={true}
                                isClearable={true}
                                closeMenuOnSelect={false}
                                onChange={(e) => { reactSelectValidation(e, "soci_location"); setSelectedSociLocation(e ?? []) }}
                                isSearchable
                                name="soci_location_id[]"
                                id="soci_location"
                                className="basic-multi-select react-select required"
                                required={platform === 'Soci' || platform === 'Both' ? true : false}
                            />
                            <div className="invalid-feedback">The soci location field is required</div>
                        </div>
                    </div>
                    <Elements.TextInput name="first_name" title="First Name" value={formData.first_name && formData.first_name} required={true} inputSize="col-md-8" labelSize="col-md-3 text-md-end" placeholder="First Name" />
                    <Elements.TextInput name="last_name" title="Last Name" value={formData.last_name && formData.last_name} required={true} inputSize="col-md-8" labelSize="col-md-3 text-md-end" placeholder="Last Name" />
                    <Elements.TextInput name="user_alias" title="User Alias" value={formData.user_alias && formData.user_alias} required={false} inputSize="col-md-8" labelSize="col-md-3 text-md-end" placeholder="User Alias" />
                    <Elements.TextInput inputType="email" name="email" title="Email Address" value={formData.email && formData.email} required={true} inputSize="col-md-8" labelSize="col-md-3 text-md-end" placeholder="Email" />
                    <div className="row mb-3">
                        <label htmlFor="phone" className="col-md-3 text-md-end col-form-label">Phone<strong className="text-danger">*</strong>:</label>
                        <div className="col-md-8 iti-w100 intl_phone">
                            <input type="tel" className="form-control" id="phone" name="phone" data-intl-tel-input-id="0" defaultValue={formData.phone} required />
                            <div className="invalid-feedback">The phone field is required.</div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label htmlFor="user_group_id" className="col-md-3 text-md-end col-form-label">Group Type<strong className="text-danger">*</strong>:</label>
                        <div className="col-md-8">
                            <select className="form-select" id="user_group_id" name="user_group_id" value={formData.user_group_id ? formData.user_group_id : ''} onChange={(e) => groupTypeHandleChange(e.target.value)} required>
                                <option value="">-- Select Group Id --</option>
                                {groupId ? groupId.map((item, key) => {
                                    return (<option value={item.value} key={key}>{item.label}</option>)
                                }) : ''}
                            </select>
                            <div className="invalid-feedback">The group type field is required</div>
                        </div>
                    </div>
                    {editId && <>
                        <Elements.TextInput name="soci_api_token" title="Soci Api Token" value={formData.soci_api_token && formData.soci_api_token} inputSize="col-md-8" labelSize="col-md-3 text-md-end" placeholder="First Name" />
                        <div className="row mb-3">
                            <label htmlFor="user_group_id" className="col-md-3 text-md-end col-form-label">Status<strong className="text-danger">*</strong>:</label>
                            <div className="col-md-8">
                                <select className="form-select" id="status" name="status" value={formData.status ? formData.status : ''} onChange={(e) => statusHandleChange(e.target.value)} required >
                                    <option value="">-- Select Status --</option>
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                </select>
                                <div className="invalid-feedback">The status field is required</div>
                            </div>
                        </div>
                    </>}

                    <div className="row">
                        <div className="col-md-11 text-end">
                            <button type="button" className="btn btn-primary minw-100" onClick={(e) => submitUserForm(e)} disabled={tinyloader}>
                                {tinyloader ?
                                    <div className="spinner-border spinner-border-sm mx-3" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div> : editId ? 'Update' : 'Submit'}
                            </button>
                            <button type="button" className="btn btn-outline-danger px-4 ms-2" data-bs-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                </form>
            </Elements.ModalSection>

            <div className="modal fade" tabIndex="-1" id="userPermissionModal" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content rounded-10 shadow-lg overflow-hidden">
                        <div className="modal-header px-sm-4 bg-light">
                            <h4 className="m-0 fw-bold">User Permission</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" title='Close'></button>
                        </div>
                        <div className="modal-body p-sm-4">
                            {(pageLoader || pageLoader1) && <div className="text-center py-5">
                                <div className="spinner-border" style={{ width: '3rem', height: '3rem' }} role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                <p className="mt-3">Please wait for response. This may take a while...</p>
                            </div>}
                            <form className={`needs-validation ${(pageLoader || pageLoader1) ? 'd-none' : 'd-block'}`} id="userPermissionForm">
                                <div className="row">
                                    <div className="table-responsive">
                                        <table className="table-common" id="userPermissionTable">
                                            <thead>
                                                <tr>
                                                    <th>Permission ID</th>
                                                    <th>Permission Name</th>
                                                    <th>
                                                        <label className="form-check form-check-label">
                                                            <input className="form-check-input" type="checkbox" defaultChecked={false} data-check_col='add_all' data-module='col' name="false" />
                                                            <input className="form-check-input" type="checkbox" defaultChecked={false} data-check_col='add_all' data-module='col' name="false" />
                                                            Add All
                                                        </label>
                                                    </th>
                                                    <th>
                                                        <label className="form-check form-check-label">
                                                            <input className="form-check-input" type="checkbox" defaultChecked={false} data-check_col='edit_all' data-module='col' name="false" />
                                                            Edit All
                                                        </label>
                                                    </th>
                                                    <th>
                                                        <label className="form-check form-check-label">
                                                            <input className="form-check-input" type="checkbox" defaultChecked={false} data-check_col='delete_all' data-module='col' name="false" />
                                                            Delete All
                                                        </label>
                                                    </th>
                                                    <th>
                                                        <label className="form-check form-check-label">
                                                            <input className="form-check-input" type="checkbox" defaultChecked={false} data-check_col='view_all' data-module='col' />
                                                            View All
                                                        </label>
                                                    </th>
                                                    <th>
                                                        <label className="form-check form-check-label">
                                                            <input className="form-check-input" type="checkbox" defaultChecked={false} data-select_all='select_all' name="false" />
                                                            Select All
                                                        </label>
                                                    </th>
                                                </tr>
                                            </thead>
                                            {(permissionData && permissionData.length > 0) && <tbody refresh={refresh}>
                                                {permissionData.map((ele, key) => {
                                                    return (
                                                        <tr role="row" key={key} >
                                                            <td>{key + 1}</td>
                                                            <td>
                                                                <input type="hidden" name={`permission[${key}][permission_id]`} defaultValue={ele.permission_id} />
                                                                <input type="hidden" name={`permission[${key}][user_id]`} defaultValue={ele.user_id} />
                                                                <input type="hidden" name={`permission[${key}][created_by]`} defaultValue={ele.created_by} />
                                                                {ele.permission}</td>
                                                            <td >
                                                                {ele.add[0] === 'yes' && ele.add[1] === 'yes' ? <>
                                                                    <input type="checkbox" className="d-none add_all" value={ele.add[2] === 'yes' ? '1' : '0'} />
                                                                    <input type="hidden" name={`permission[${key}][add]`} value="0" />
                                                                    <input type="checkbox" className="form-check-input" name={`permission[${key}][add]`} defaultChecked={ele.add[2] === 'yes' ? true : false} data-row={key} data-module='add' value="1" />
                                                                </> : <><input type="checkbox" className="d-none add_all" value="1" />N/A</>}
                                                            </td>
                                                            <td>{ele.edit[0] === 'yes' && ele.edit[1] === 'yes' ? <>
                                                                <input type="checkbox" className="d-none edit_all" value={ele.edit[2] === 'yes' ? '1' : '0'} />
                                                                <input type="hidden" name={`permission[${key}][edit]`} value="0" />
                                                                <input type="checkbox" className="form-check-input" name={`permission[${key}][edit]`} defaultChecked={ele.edit[2] === 'yes' ? true : false} data-row={key} data-module='edit' value="1" />
                                                            </> : <><input type="checkbox" className="d-none edit_all" value="1" />N/A</>}
                                                            </td>
                                                            <td>{ele.delete[0] === 'yes' && ele.delete[1] === 'yes' ? <>
                                                                <input type="checkbox" className="d-none delete_all" value={ele.delete[2] === 'yes' ? '1' : '0'} />
                                                                <input type="hidden" name={`permission[${key}][delete]`} value="0" />
                                                                <input type="checkbox" className="form-check-input" name={`permission[${key}][delete]`} defaultChecked={ele.delete[2] === 'yes' ? true : false} data-row={key} data-module='delete' value="1" />
                                                            </> : <><input type="checkbox" className="d-none delete_all" value="1" />N/A</>}
                                                            </td>
                                                            <td>{ele.view[0] === 'yes' && ele.view[1] === 'yes' ? <>
                                                                <input type="checkbox" className="d-none view_all" value={ele.view[2] === 'yes' ? '1' : '0'} />
                                                                <input type="hidden" name={`permission[${key}][view]`} value="0" />
                                                                <input type="checkbox" className="form-check-input" name={`permission[${key}][view]`} defaultChecked={ele.view[2] === 'yes' ? true : false} data-row={key} data-module='view' value="1" />
                                                            </> : <><input type="checkbox" className="d-none view_all" value="1" />N/A</>}
                                                            </td>
                                                            <td>
                                                                <label className="form-check form-check-label">
                                                                    <input type="hidden" name="" value="0" />
                                                                    <input className="form-check-input" type="checkbox" id={`allcheck_${key + 1}`} data-check_row={key} data-module='row' />
                                                                    All
                                                                </label>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                            }
                                        </table>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col text-end">
                                        <button type="button" className="btn btn-outline-danger px-4 ms-2" data-bs-dismiss="modal" aria-label="Close">Close</button>
                                        <button type="button" className="btn btn-primary px-4 ms-2" onClick={() => updateUserPermission(userId)} disabled={tinyloader}>
                                            {tinyloader ?
                                                <div className="spinner-border spinner-border-sm mx-3" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div> : 'Submit'}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <Elements.ConfirmationModal msg="Are you sure to delete this record?" method={() => deleteRecords(deleteId)} />

        </>
    )
}

export default ManageAdmins
