import React, { useState, useEffect } from "react";
import Select from "react-select"
import { downloadPdf, toastNotify } from "../../components/Helper";
import { fetchData, GET_LOCATION_UNIQUE_URL, COMMON_COMPETITIVE, GET_SEARCH_REPORT } from "../../components/Service";
import DateRangeFilter from "../../components/DateRangeFilter";
import CompetitivePieChart from "../../components/CompetitivePieChart";
import CompetitiveLineChart from "../../components/CompetitiveLineChart";

function CompetitiveAnalysis() {
    window.document.title = "Competitive Analysis";
    const [location, setLocation] = useState();
    const [records, setRecords] = useState('0');
    const [report, setReport] = useState('0')
    const [keywords, setKeywords] = useState([]);
    const [formfield, setFormfield] = useState({
        type: 'thirtDay',
        startDate: '',
        endDate: '',
    })

    useEffect(() => {
        fetchData(GET_LOCATION_UNIQUE_URL, 'GET', '', true, false, (res) => {
            if (res.records) {
                setLocation(res.records)
            }
        });
    }, [])

    const getDateRange = (startDate, endDate) => {
        document.getElementById("show_list").classList.add("d-none")
        document.getElementById("date_filter").value = `${startDate} - ${endDate}`
        setFormfield(prevState => ({
            ...prevState, type: '', startDate: startDate, endDate: endDate
        }))
    }

    const refreshData = () => {
        document.querySelector('input[name="daterange"]').value = 'MM DD YYYY - MM DD YYYY'
        document.getElementById("show_list").classList.add("d-none")
        document.getElementById("date_filter").value = 'Last 30 Days'
        setFormfield(prevState => ({
            ...prevState, type: 'thirtDay', startDate: '', endDate: ''
        }))
    }

    const getDateFilterData = (e, type) => {
        document.getElementById("show_list").classList.add("d-none")
        document.getElementById("date_filter").value = e.target.innerText
        setFormfield(prevState => ({
            ...prevState, type: type, startDate: '', endDate: ''
        }))
    }

    const getData = (e) => {
        let formdata = new FormData(document.getElementById('searchForm'));
        let location_selected = formdata.getAll('locations[]')
        if (location_selected.length <= 10) {
            if (location_selected.length > 0 && location_selected[0] !== '') {
                document.querySelectorAll(".shadow-sm").forEach(element => element.classList.add('card-loader'));
                fetchData(`${COMMON_COMPETITIVE}?location=${location_selected}&type=${formfield.type}&startDate=${formfield.startDate}&endDate=${formfield.endDate}`, 'GET', '', true, false, (res) => {
                    setRecords('0')
                    setKeywords([])
                    if (res.records) {
                        document.querySelectorAll(".shadow-sm").forEach(element => element.classList.remove('card-loader'));
                        setRecords(res.records)
                        setKeywords(res.records.local_competitors.keyword_competitor.keyword)
                    } else {
                        document.querySelectorAll(".shadow-sm").forEach(element => element.classList.remove('card-loader'));
                        setRecords('0')
                    }
                }, false, false, false);

                fetchData(`${GET_SEARCH_REPORT}?location=${location_selected}&type=${formfield.type}&startDate=${formfield.startDate}&endDate=${formfield.endDate}`, 'GET', '', true, false, (res) => {
                    if (res.records) {
                        document.querySelectorAll(".shadow-sm").forEach(element => element.classList.remove('card-loader'));
                        setReport(res.records)
                    } else {
                        document.querySelectorAll(".shadow-sm").forEach(element => element.classList.remove('card-loader'));
                        setReport('0')
                    }
                }, false, false, false);
            } else {
                toastNotify({ error: 'Please select atleast one location' })
                setRecords('0')
                setKeywords([])
            }
        } else {
            toastNotify({ error: 'You can\'t select more than 10 location at a time.' })
        }
    }

    const setType = (e) => {
        let list_type = e.target.dataset.list_type ? e.target.dataset.list_type : ''
        let keywords = e.target.innerText !== 'All Keywords' ? e.target.innerText : ''

        let formdata = new FormData(document.getElementById('searchForm'));
        let location_selected = formdata.getAll('locations[]')
        fetchData(`${COMMON_COMPETITIVE}?location=${location_selected}&type=${formfield.type}&startDate=${formfield.startDate}&endDate=${formfield.endDate}&list_type=${list_type}&keywords=${keywords}`, 'GET', '', true, false, (res) => {
            if (res.records) {
                if (e.target.dataset.list_type === 'competitors') {
                    setRecords(prevState => ({ ...prevState, local_competitors: res.records.local_competitors }))
                } else if (e.target.dataset.list_type === 'intelligent_keywords') {
                    setRecords(prevState => ({ ...prevState, intelligent_keywords: res.records.intelligent_keywords }))
                } else if (e.target.dataset.list_type === 'keywords') {
                    setRecords(prevState => ({ ...prevState, share_competitors: res.records.share_competitors }))
                } else {
                    setRecords(res.records)
                    setKeywords(res.records.local_competitors.keyword_competitor.keyword)
                }
            }
        }, false, false, false);
    }

    return (
        <section className="admin-wrapper">
            <div className="container-fluid">
                <div className="row align-items-center justify-content-between">
                    <div className="col-sm-12 text-center text-md-start ms-xl-0 ms-md-4">
                        <h4 className="page-heading">Competitive Analysis</h4>
                    </div>
                    <div className="col-sm-12 my-3">
                        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4">
                            <form className="col" id="searchForm">
                                <Select
                                    placeholder={'-- Select Location --'}
                                    name="locations[]"
                                    isClearable={true}
                                    closeMenuOnSelect={false}
                                    options={location}
                                    isMulti={true}
                                    className="basic-multi-select react-select required"
                                    id="location_id"
                                    required
                                    isSearchable
                                />
                            </form>
                            <div className="col position-relative">
                                <input type="text" id="date_filter" readOnly className="form-control bg-white shadow-none pointer-event-active" defaultValue="Last 30 Days" onFocus={() => document.getElementById("show_list").classList.remove("d-none")} />
                                <ul className="list-group show_list position-absolute bg-white shadow rounded d-none" id="show_list" style={{ listStyle: "none", width: '384px', zIndex: 2 }}>
                                    <li className="list-group-item list-group-item-action">
                                        <DateRangeFilter
                                            daterange="MM DD YYYY - MM DD YYYY"
                                            className="form-control"
                                            maxDate={new Date()}
                                            opens="left"
                                            name='daterange'
                                            reload={refreshData}
                                            disableUpdateInput={false}
                                            getDateRange={getDateRange}
                                        />
                                    </li>
                                    <li role="button" className="list-group-item list-group-item-action" onClick={(e) => getDateFilterData(e, "thirtDay")}>
                                        Last 30 Days
                                    </li>
                                    <li role="button" className="list-group-item list-group-item-action" onClick={(e) => getDateFilterData(e, "lastNinDay")}>
                                        Last 90 Days
                                    </li>
                                    <li role="button" className="list-group-item list-group-item-action" onClick={(e) => getDateFilterData(e, "lastTwelWeek")}>
                                        Last 12 Week
                                    </li>
                                    <li role="button" className="list-group-item list-group-item-action" onClick={(e) => getDateFilterData(e, "lastSixtWeek")}>
                                        Last 16 Week
                                    </li>
                                </ul>
                            </div>
                            <div className="col">
                                <button className="btn btn-primary" type="button" onClick={(e) => getData(e)}>Submit</button>
                            </div>
                            <div className="col text-end">
                                <button type="button" className="btn btn-primary" onClick={() => downloadPdf('downloadData', 'My_PDF_')}>Generate PDF Report</button>
                            </div>
                        </div>
                    </div>
                    <div id="downloadData">
                        <div className="row g-4">
                            <div className="col">
                                <div className="card border-light shadow-sm border-0 h-100">
                                    <h5 className="card-header fw-bold">Your Share of Intelligent search is <strong id="card_header_per">{report && report.intelligent_list_per && report.intelligent_list_per.percentage ? report.intelligent_list_per.percentage : '0'} %</strong></h5>
                                    <div className="card-body">
                                        <div className="circle-data-graph">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-4 col-lg-3">
                                                    <div className="container-box p-5">
                                                        <svg viewBox="0 0 36 36" id="viewboxsvg">
                                                            <path style={{ fill: 'none', stroke: '#eee', strokeWidth: '3' }} d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                            <path style={{ fill: 'none', strokeWidth: '2', stroke: '#1591dd', strokeLinecap: 'round', transition: 'all 1s ease-out' }} id="fillStroke" strokeDasharray="0, 100" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                            <text x="18" y="20.35" style={{
                                                                fill: '#666', fontFamily: 'sans-serif', fontSize: '0.45em', textAlign: 'center',
                                                                textAnchor: 'middle'
                                                            }} id="percentageText">{report && report.intelligent_list_per && report.intelligent_list_per.percentage ? report.intelligent_list_per.percentage : '0'}%</text>
                                                        </svg>
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-md-8 col-lg-9">
                                                    <div className="data-graph p-3">
                                                        <div className="graph-box mt-4 mb-4 border-0 h-100">
                                                            <div className="percentage-bar" id="percentage_bar_list">
                                                                {report && report.intelligent_list && report.intelligent_list.length > 0 ? report.intelligent_list.map((ele, num) => {
                                                                    return (<React.Fragment key={num}>
                                                                        {ele.match_type === 'Listings' ?
                                                                            < div className="bar-green" data-hover="line-green" style={{ height: `${ele.total_match}%` }} data-percentage={`${ele.total_match}%`}></div>
                                                                            : ''}

                                                                        {ele.match_type === 'Local Pack' ?
                                                                            <div className="bar-perpol" data-hover="line-perpol" style={{ height: `${ele.total_match}%` }} data-percentage={`${ele.total_match}%`}></div>
                                                                            : ''}

                                                                        {ele.match_type === 'Location Pages' ?
                                                                            <div className="bar-gray" data-hover="line-gray" style={{ height: `${ele.total_match}%` }} data-percentage={`${ele.total_match}%`}></div>
                                                                            : ''}

                                                                        {ele.match_type === 'Corporate Website' ?
                                                                            <div className="bar-blue" data-hover="line-blue" style={{ height: `${ele.total_match}%` }} data-percentage={`${ele.total_match}%`}></div>
                                                                            : ''}
                                                                    </React.Fragment>)
                                                                }) : ''}
                                                            </div>

                                                            <ul className="graph-listing" id="listing_inte">
                                                                {
                                                                    report && report.intelligent_list && report.intelligent_list.length > 0 ? report.intelligent_list.map((ele, num) => {
                                                                        return (<React.Fragment key={num}>

                                                                            {ele.match_type === 'Listings' ?
                                                                                <li className="line-green">
                                                                                    <p data-percentage={`${ele.total_match}%`}>
                                                                                        <strong>Listing <small>Powered By Amazio Listings</small></strong>
                                                                                        Search results from your locations' listings
                                                                                    </p>
                                                                                </li>
                                                                                : ''}

                                                                            {ele.match_type === 'Local Pack' ?
                                                                                <li className="line-perpol">
                                                                                    <p data-percentage={`${ele.total_match}%`}>
                                                                                        <strong>Local Pack and Knowledge Card<small>Powered by Company</small></strong>
                                                                                        Search results that match your location in the Local Pack or in the Knowledge Card, including paid results
                                                                                    </p>
                                                                                </li>
                                                                                : ''}

                                                                            {ele.match_type === 'Location Pages' ?
                                                                                <li className="line-gray">
                                                                                    <p data-percentage={`${ele.total_match}%`}>
                                                                                        <strong>Location Pages</strong>
                                                                                        Search results that match your location website URL
                                                                                    </p>
                                                                                </li>
                                                                                : ''}

                                                                            {ele.match_type === 'Corporate Website' ?
                                                                                <li className="line-blue">
                                                                                    <p data-percentage={`${ele.total_match}%`}>
                                                                                        <strong>Corporate Website</strong>
                                                                                        Search results that match your corporate website domain
                                                                                    </p>
                                                                                </li>
                                                                                : ''}
                                                                        </React.Fragment>)
                                                                    })
                                                                        :
                                                                        <div className="p-2 text-secondary fs-6 fst-italic">There is no data in the range you have selected.<br />If your account is new, wait a few weeks for data to become available.<br />Otherwise, try changing your selections above.</div>
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row g-4 mt-2">
                            <div className="col">
                                <div className="card border-light shadow-sm border-0 h-100">
                                    <h5 className="card-header fw-bold">Local Competitors</h5>
                                    <div className="card-body">
                                        <div className="no-height-scroll">
                                            <div className="row">
                                                <div className="col-md-3 col-sm-4 border-0 h-100 ">
                                                    <div className="bordersli border-none" style={{ width: '100%', height: '265px', overflow: 'auto' }}>
                                                        <a className="nav-link local_com active sinngleli" data-competitor="" data-toggle="tab" href="javascipt:void(0);" role="tab" style={{ display: 'none' }}>All Keywords</a>
                                                        <ul className="list-group">
                                                            {keywords && keywords.length > 0 ? <li className="list-group-item fw-bold" onClick={(e) => setType(e)} data-list_type="" role="button">All Keywords</li> : ''}
                                                            {
                                                                keywords && keywords.length > 0 ? keywords.map((ele, num) => <li className="list-group-item fw-bold" key={num} onClick={(e) => setType(e)} data-list_type="competitors" role="button">{ele}</li>
                                                                )
                                                                    :
                                                                    ''
                                                            }
                                                        </ul>
                                                    </div>

                                                </div>
                                                <div className="col-md-5 col-sm-8 local-competitor border-0 h-100 ">
                                                    <div className="tab-content">
                                                        <div className="tab-pane active" id="home" role="tabpanel">
                                                            <div className="loader-transparent" id="loader11" style={{ display: 'none' }}><i className="fa fa-spinner fa-spin grid_loader text-muted" style={{ fontSize: '50px' }}></i></div>
                                                            <div className="table-scroll" id="competitor_listing" style={{ height: ' 265px', overflow: 'auto' }}>
                                                                <table className="table table-bordered ">
                                                                    {records && records.local_competitors && records.local_competitors.keywords_competitor_table && records.local_competitors.keywords_competitor_table.length > 0 ?
                                                                        <thead>
                                                                            <tr>
                                                                                <th>S.No.</th>
                                                                                <th>Competitors</th>
                                                                                <th style={{ width: '27%', }}>Local Pack Matches</th>
                                                                            </tr>
                                                                        </thead>
                                                                        : ''
                                                                    }
                                                                    <tbody>
                                                                        {

                                                                            records && records.local_competitors && records.local_competitors.keywords_competitor_table && records.local_competitors.keywords_competitor_table.length > 0 ? records.local_competitors.keywords_competitor_table.map((ele, num) => {
                                                                                return (
                                                                                    <tr key={num}>
                                                                                        <td>{num + 1}</td>
                                                                                        <td>{ele.local_pack_competitor}</td>
                                                                                        <td>{ele.total_matches}</td>
                                                                                    </tr>)
                                                                            })
                                                                                :
                                                                                <div className="p-2 text-secondary fs-6 fst-italic">There is no data in the range you have selected.<br />If your account is new, wait a few weeks for data to become available.<br />Otherwise, try changing your selections above.</div>
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-sm-12 border-0 h-100 ">
                                                    {
                                                        records && records.local_competitors && records.local_competitors.local_competitors_graph && records.local_competitors.local_competitors_graph.length > 0 ? <CompetitivePieChart data={records.local_competitors.local_competitors_graph} /> : ''
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row g-4 mt-2">
                            <div className="col">
                                <div className="card border-light shadow-sm border-0 h-100">
                                    <h5 className="card-header fw-bold">You Share of Intelligent search by keyword</h5>
                                    <div className="card-body">
                                        <div className="no-height-scroll">
                                            <div className="row tab-charts">
                                                <div className="col-md-3 col-sm-4 border-0 h-100 ">
                                                    <div className="bordersli border-none" style={{ width: '100%', height: '265px', overflow: 'auto' }}>
                                                        <a className="nav-link share active sinngleli" data-share="" data-toggle="tab" href="javascipt:void(0);" role="tab" style={{ display: 'none' }}>All Keywords</a>
                                                        <ul className="list-group">
                                                            {keywords && keywords.length > 0 ? <li className="list-group-item fw-bold" onClick={(e) => setType(e)} data-list_type="" role="button">All Keywords</li> : ''}
                                                            {
                                                                keywords && keywords.length > 0 ? keywords.map((ele, num) => <li className="list-group-item fw-bold" key={num}
                                                                    onClick={(e) => setType(e)} data-list_type="intelligent_keywords" role="button">{ele}</li>
                                                                )
                                                                    :
                                                                    ''
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-md-9 col-sm-8 border-0 h-100 ">
                                                    <div className="tab-content">
                                                        {
                                                            records && records.intelligent_keywords && records.intelligent_keywords.length > 0 ? <CompetitiveLineChart data={records.intelligent_keywords} />
                                                                :
                                                                <div className="p-2 text-secondary fs-6 fst-italic">There is no data in the range you have selected.<br />If your account is new, wait a few weeks for data to become available.<br />Otherwise, try changing your selections above.</div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row g-4 mt-2">
                            <div className="col">
                                <div className="card border-light shadow-sm border-0 h-100">
                                    <h5 className="card-header fw-bold">Competitors' Share of Intelligent Search</h5>
                                    <div className="card-body">
                                        <div className="search-share-data no-height-scroll">
                                            <div className="row tab-charts">
                                                <div className="col-sm-3 border-0 h-100 " style={{ textAlign: 'left' }} >
                                                    <div className="bordersli border-none" style={{ width: '100%', height: '265px', overflow: 'auto' }}>
                                                        <a className="nav-link competitor active sinngleli" data-competitorshare="" data-toggle="tab" href="javascipt:void(0);" role="tab" style={{ display: 'none' }}>All Keywords</a>
                                                        <ul className="list-group">
                                                            {keywords && keywords.length > 0 ? <li className="list-group-item fw-bold" onClick={(e) => setType(e)} data-list_type="" role="button">All Keywords</li> : ''}
                                                            {
                                                                keywords && keywords.length > 0 ? keywords.map((ele, num) => <li className="list-group-item fw-bold" key={num} onClick={(e) => setType(e)} data-list_type="keywords" role="button">{ele}</li>
                                                                )
                                                                    : ''
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-sm-9 border-0 h-100 ">
                                                    <div className="tab-content">
                                                        <div className="tab-pane active" id="home1" role="tabpanel">
                                                            <div className="loader-transparent" id="loader13" style={{ display: 'none' }}><i className="fa fa-spinner fa-spin grid_loader text-muted" style={{ fontSize: '50px' }}></i></div>
                                                            <div className="row justify-content-center h-100" id="percentage_search">
                                                                {
                                                                    records && records.share_competitors && records.share_competitors.share_percentage && records.share_competitors.share_percentage.length > 0 ?
                                                                        records.share_competitors.share_percentage.map((ele, num) => {
                                                                            return (<div className="col d-grid border border-secondary border-2 m-2 rounded text-center pt-3 shadow-sm" key={num}>
                                                                                    {ele.competitor === 'My Locations' ?
                                                                                        <>
                                                                                            <p className={ele.share_of_intelligent_search > 0 ? 'text-success fw-bold' : 'text-danger fw-bold'}>{ele.share_of_intelligent_search} %</p>
                                                                                            <p className="text-primary fw-bold">Amazio (You)</p>
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            <p className={ele.share_of_intelligent_search > 0 ? 'text-success fw-bold' : 'text-danger fw-bold'}>{ele.share_of_intelligent_search} %</p>
                                                                                            <p className="text-primary fw-bold">{ele.competitor}</p>
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        })
                                                                        :
                                                                        <div className="p-2 text-secondary fs-6 fst-italic">There is no data in the range you have selected.<br />If your account is new, wait a few weeks for data to become available.<br />Otherwise, try changing your selections above.</div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default CompetitiveAnalysis