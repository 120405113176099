import { createRoot } from 'react-dom/client'
import { toastNotify } from "../../components/Helper";
import { fetchData, SETTINGS_DATA , UPDATE_SETTING} from "../../components/Service";
import * as Elements from "../../components/Elements";
import { useEffect, useState } from 'react';
import DataTables, { redrawDataTable } from '../../components/Datatables';
import { now } from "lodash";
import $ from 'jquery'

function SettingPanel() {
    window.document.title = "Settings";
    const [refresh, setRefresh] = useState(now)

    const clearInput = (e) => {
        var element = e.target.parentElement.children[0]
        if(element.dataset.edit_value === 'false' && element.value !== '') {
            element.dataset.edit_value = true
            element.value = ''
        }
    }

    const editData = (e) => {
        e.target.dataset.edit_value = true
    }

    window.updateRecord = (e) => {
        let value = {};
        var input_data_value = document.querySelectorAll('#wt_datatable_settingTable input[data-edit_value=true]');
        input_data_value.forEach(function (element) {
            value[element.name] = element.value;
        })
        
        if(Object.keys(value).length !== 0) {
            let formdata = { value: value }
            fetchData(UPDATE_SETTING, 'POST', formdata, true, false, (res) => {
                if (res.success) {
                    setRefresh(now())
                }
            });
        } else {
            toastNotify({ error: 'Nothing To Update' })
        }
    }

    const [dt] = useState({
        dt_url: SETTINGS_DATA,
        dt_name: 'settingTable',
        dt_export: false,
        dt_paging: false,
        dt_searching: true,
        dt_column: [
            { data: 'name', name: 'name', title: 'Name', sortable: false, orderable: false },
            { data: 'slug', name: 'slug', title: 'Slug', sortable: false, orderable: false, },
            { data: 'type', name: 'type', title: 'Type', sortable: false, orderable: false, },
            { data: 'value', name: 'value', title: 'Value', sortable: false, orderable: false, class: "text-nowrap w-300" },
        ],
        dt_column_defs: [
            {
                targets: 0,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <div className="input-group input-group-sm">
                            <input type="text" className="form-control" name={records.slug} readOnly={true} disabled={true} defaultValue={records.name} data-edit_name="false" />
                        </div>
                    )
                },
            },
            {
                targets: 1,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <div className="input-group input-group-sm">
                            <input type="text" className="form-control" name={records.slug} readOnly={true} disabled={true} defaultValue={records.slug} data-edit_slug="false" />
                        </div>
                    )
                },
            },
            {
                targets: 2,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <div className="input-group input-group-sm">
                            <input type="text" className="form-control" name={records.slug} readOnly={true} disabled={true} defaultValue={records.type} data-edit_type="false" />
                            
                        </div>
                    )
                },
            },
            {
                targets: 3,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <div className="input-group input-group-sm">
                            <input type="text" className="form-control" name={records.slug} defaultValue={records.value} data-edit_value="false" onInput={(e) => editData(e)} />
                            <button className="btn btn-outline-secondary" type="button" onClick={(e) => clearInput(e)} >
                                <i className="bi bi-x pe-none"></i>
                            </button>
                        </div>
                    )
                },
            }
        ],
        dt_order: [[0, 'asc']]
    });

    useEffect(() => {
        if (dt) {
            redrawDataTable(dt);
        }

        if (!$('.dt-custom-filter button').hasClass('add-new')) {
            $('.dt-custom-filter').append(`<button type="button" class="btn btn-dark px-3 text-nowrap me-2 rounded-0 add-new" onclick="updateRecord()">
                <svg class="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg>Update
            </button>`);
        }
    }, [dt, refresh])

    return (
        <>
            <Elements.ListSection title='Setting Panel'>
                <DataTables dt_name="settingTable" dataPageLength="100" />
            </Elements.ListSection>
        </>
    )
}
export default SettingPanel
