import { PageTitleSection } from "../../../components/Elements";

function Food() {
    window.document.title = "Food";

    return (
        <>
            <PageTitleSection title="Food" subtitle="Hungry consumers find you fast"/>

            <section className="py-5">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-md-12">
                            <p className="">
                                Hungry consumers find you fast! At least, as long as you have the right optimizations for your restaurant's website. With Amazio's Knowledge Engine, your restaurant will be easy to find so your customers can find you anytime their cravings strike.
                            </p>
                            <p className="mt-4 ">
                                Smart devices, AI and voice assistants are all changing the way people make dining decisions. Make sure you show up no matter when, where, or how customers near you are searching.
                            </p>
                            <p className="mt-4 ">
                                Here's what Amazio has to offer businesses in the food industry.
                            </p>
                            <h5 className="fw-bold">1. Invest in responsive technology</h5>
                            <p className="">
                                Your customers use a lot of different devices to search for you, including laptops, tablets, and mobile phones. Having a website with responsive technology will ensure they can see your website easily no matter what device they're on. As a restaurant, this is especially important since most users use mobile search instead of a laptop or desktop to find restaurants near them when they're hungry.
                            </p>
                            <h5 className="mt-4 fw-bold">2. Enjoy third-party integrations</h5>
                            <p className="">
                                There are a lot of great third-party tools for food businesses out there. No matter what you're using, Amazio can integrate with it to ensure you get all the features you want.
                            </p>
                            <h5 className="mt-4 fw-bold">3. Explore management solutions for your restaurant</h5>
                            <p className="">Amazio's management and solutions for food businesses include:</p>
                            <p>Menu syncing</p>
                            <p>Featured messaging</p>
                            <p>Order URLs</p>
                            <p>Attributes</p>
                            <p>Location pages</p>
                            <p>Reviews</p>
                            <h5 className="mt-4 fw-bold">4. Monitor and analyze engagement</h5>
                            <p className="">
                                Knowing how your users are engaging with your website, whether they're using their desktops or mobile devices, is crucial for ensuring your site has the best UX possible. Amazio can monitor and analyze your engagement to ensure your site is fully optimized to meet the needs of your customers.
                            </p>
                            <h5 className="mt-4 fw-bold">5. Empower and personalize franchises</h5>
                            <p className="">
                                You can empower your franchises to add a personal touch with managed services from Amazio. This allows franchisees to adhere to your company guidelines while still having enough room to create a personal touch based on their location, specialties, and special offerings.
                            </p>
                        </div>
                    </div>
                </div>
            </section>   
        </>
    )
}

export default Food