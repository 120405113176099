import React, { useState, useEffect, useContext } from "react";
import Select from 'react-select'
import { Modal } from "bootstrap";
import { Context } from '../../components/Context';
import { validateForm, blobToBase64, srcToBase64, intlTel_phone, validatePhone, srcToBase64v2} from "../../components/Helper";
import { fetchData, GET_LOCATION_UNIQUE_URL, GET_LOCATION_PORT, PREVIEW_CARD_SUBMIT, GET_CONVERT_IMAGE_TO_BASE64} from "../../components/Service";
import PreviewCard from "./PreviewCard";
import Design1 from "./CardDesign/Design1";
import Design2 from "./CardDesign/Design2";
import Design3 from "./CardDesign/Design3";
import Design4 from "./CardDesign/Design4";
import Design5 from "./CardDesign/Design5";
import Design6 from "./CardDesign/Design6";
import Design7 from "./CardDesign/Design7";

function BusinessCard() {
    window.document.title = "Amazio | Business Card";
    const [context] = useContext(Context)
    const [tinyloader, setTinyloader] = useState(false);
    const [tinyloaderII, setTinyloaderII] = useState(false);
    const [newImg, setNewImg] = useState('');
    const [locations, setLocations] = useState('')
    const [flipCard, setFlipCard] = useState('front')
    const [design, setDesign] = useState('Design1')
    const [oderId, setOderId] = useState('')
    const [intlTel, setIntlTel] = useState()
    const [thirdPartyLogo, setThirdPartyLogo] = useState('');
    const [formField, setFormField] = useState({
        part: 'part_one',
        template: '',
        business_logo: '',
        job_title: '',
        name: `${context.auth.first_name} ${context.auth.last_name}`,
        contact_email: context.auth.email,
        pc_url: '',
        phone: '',
        cell_phone: '',
        help_desk_phone: '',
        def_phone: context.auth.phone ? context.auth.phone : '', 
        tag_line: '',
        street: '',
        city: context.auth.city,
        state: context.auth.state,
        zip: context.auth.zip_code,
        font: '',
        logo_bg_color: '#fff',
        text_color: '#000',
        bg_color: '#fff',
        quantity: '500',
        billing_type: 'pc_number',
        pc_number: '',
        cost_center: '',
        phone_pay: '',
        shipping_address: '',
        shipping_state: '',
        shipping_city: '',
        shipping_country: 'US',
        shipping_zip: '',
        pc_name: '',
        card_type: 'both'
    })

    useEffect(() => {
        setIntlTel(intlTel_phone('#phone'))
        document.getElementById("cardModal").addEventListener('hidden.bs.modal', function (e) {
            var formid = document.getElementById("second_step");
            formid.classList.remove("needs-validation");
            formid.classList.remove('was-validated');
            formid.reset();
            setFormField(prevState => ({
                ...prevState, part: 'part_one'
            }))
        });

        fetchData(GET_LOCATION_UNIQUE_URL, 'GET', '', true, false, (res) => {
            if (res.records) {
                setLocations(res.records)
            }
        });
    }, []);

    
    const locationsData = (locationValue) => {
         fetchData(`${GET_LOCATION_PORT}/${locationValue}`, 'GET', '', true, false, (res) => {
            if (res.records) {
                res.records.mapping_image && setThirdPartyLogo(res.records.mapping_image)
                res.records.mapping_image && URLImageSet(res.records.mapping_image)
                document.getElementById("updateImprint").reset();
                var addressSplit = res.records.address.split(',')
                setFormField(prevState => ({
                    ...prevState,
                    pc_url: res.records.pc_url ? res.records.pc_url : 'https://www.companyurl.com',
                    pc_number: res.records.pc_number ? res.records.pc_number : '',
                    street: addressSplit[0],
                    city: addressSplit[1],
                    state: addressSplit[2],
                    zip: addressSplit[3],
                    contact_email: res.records.contact_email && res.records.contact_email[0] ? res.records.contact_email[0] : '',
                    phone: res.records.phone,
                    cell_phone: res.records.mobile_phone,
                    help_desk_phone: res.records.tty_phone,
                    business_logo: res.records.mapping_image,
                    pc_name: res.records.pc_name ? res.records.pc_name : ''
                }))
            } 
        });

        setDesign('Design1')
        var video_error = document.getElementById('video_error')
        video_error.classList.add('text-muted')
        video_error.classList.remove('text-danger')
    };

    const URLImageSet = async (url) => {      
        srcToBase64(url, res => {
           setNewImg(res)
        })
    }
    

    const imageSet = async (e) => {

        var video_error = document.querySelectorAll('#video_error')
        var element = document.querySelector('#business_logo')
        var video_size = element.files[0].size
        if (video_size / 1024 > 2048) {
            video_error.forEach((ele) => {
                ele.classList.remove('text-muted')
                ele.classList.add('text-danger')
            })
            element.value = ''

            if(thirdPartyLogo){
                URLImageSet(thirdPartyLogo)
                setFormField(prevState => ({
                ...prevState, business_logo: thirdPartyLogo
                }));
            }
            
        } else {
            video_error.forEach((ele) => {
                ele.classList.add('text-muted')
                ele.classList.remove('text-danger')
            })

            const imgb64 = await blobToBase64(e.target.files[0]);
            setNewImg(imgb64)
            setFormField(prevState => ({
                ...prevState, business_logo: e.target.files[0]
            }));
        }
    }

    const changeFlipCard = () => {
        setFlipCard(flipCard === 'front' ? 'back' : 'front')
        // document.getElementById(id).classList.toggle('flipCard')
    }

    //Save Card Detail
    const saveCardDetail = async (e, cardPreview = '') => {
        const formData = new FormData();
        for (const [key, value] of Object.entries(formField)) {
            formData.append(key, value)
        }

        if (formField.business_logo === '') {
            srcToBase64(`${window.location.origin}${'/images/CED.png'}`, res => {
                formData.append('business_logo', res)
            })
        }
        if (formField.part === 'part_one') {
            if (validateForm(e, 'updateImprint') && validatePhone(intlTel, '#phone')) {
                let phone = document.getElementById("phone").value
                phone  = phone.replaceAll("-","")
                formData.append('phone', phone)
                setTinyloader(true);
                fetchData(PREVIEW_CARD_SUBMIT, 'POST', formData, true, true, (res) => {
                    if (res.records.success) {
                        setTinyloader(false);
                        var myModal = new Modal(document.getElementById('cardModal'), {
                            keyboard: false
                        })
                        myModal.show();
                        document.getElementById("second_step").classList.add("needs-validation")

                        setFormField(prevState => ({
                            ...prevState, part: 'part_two'
                        }))
                    }
                    setTinyloader(false);
                });
            }
        } else {
            if(cardPreview){
                formData.append('card_preview_image', cardPreview)
            }
            if (validateForm(e, 'second_step')) {
                setTinyloaderII(true);
                fetchData(PREVIEW_CARD_SUBMIT, 'POST', formData, true, true, (res) => {
                    if (res.records) {
                        setTinyloaderII(false);
                        localStorage.setItem("order_id", res.records.order_id);
                        document.getElementById('close_modal').click();
                        setOderId(res.records.order_id)
                        var myModal = new Modal(document.getElementById('orderDoneModal'), {
                            keyboard: false
                        })
                        myModal.show();
                    }
                    setTinyloaderII(false);
                });
            }
        }
    }

    return (
        <>
      
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2 text-center text-md-start ms-xl-0 ms-md-4">
                            <h4 className="page-heading">Business Card</h4>
                        </div>
                    </div>
                    <div className="row px-4 mt-4 justify-content-center justify-content-lg-between">
                        <div className="col-md-12 col-lg-6">
                            {design === 'Design1' && <Design1 newImg={newImg} formField={formField} flipCard={flipCard} changeFlipCard={changeFlipCard} />}
                            {design === 'Design2' && <Design2 newImg={newImg} formField={formField} flipCard={flipCard} changeFlipCard={changeFlipCard} />}
                            {design === 'Design3' && <Design3 newImg={newImg} formField={formField} flipCard={flipCard} changeFlipCard={changeFlipCard} />}
                            {design === 'Design4' && <Design4 newImg={newImg} formField={formField} flipCard={flipCard} changeFlipCard={changeFlipCard} />}
                            {design === 'Design5' && <Design5 newImg={newImg} formField={formField} flipCard={flipCard} changeFlipCard={changeFlipCard} />}
                            {design === 'Design6' && <Design6 newImg={newImg} formField={formField} flipCard={flipCard} changeFlipCard={changeFlipCard} />}
                            {design === 'Design7' && <Design7 newImg={newImg} formField={formField} flipCard={flipCard} changeFlipCard={changeFlipCard} />}
                        </div>
                        <div className="col-12 col-md-12 col-lg-5">
                            <h1 className="fs-4 fw-bolder pb-2">Information</h1>
                            <form className="needs-validation" id="updateImprint" method="post" noValidate>
                                <div className="row">
                                    {locations && locations.length > 0 &&
                                        <div className="col-12 col-md-6 mb-3">
                                            <Select
                                                placeholder="Select location..."
                                                options={locations}
                                                closeMenuOnSelect={true}
                                                name="location"
                                                className="basic-multi-select react-select"
                                                id="location"
                                                onChange={(newValue) => locationsData(newValue.value)}
                                            />
                                        </div>
                                    }
                                    <div className="col-12 col-md-6 mb-3">
                                        <select className="form-select" aria-label="Default select example" id="template" name="template" placeholder="Please Select a location" onChange={e => setDesign(e.target.value)}>
                                            <option value="Design1"> Design 1 </option>
                                            <option value="Design2"> Design 2 </option>
                                            <option value="Design3"> Design 3 </option>
                                            <option value="Design4"> Design 4 </option>
                                            <option value="Design5"> Design 5 </option>
                                            <option value="Design6"> Design 6 </option>
                                            <option value="Design7"> Design 7 </option>
                                        </select>
                                    </div>
                                    <div className={`col-12 col-md-6 mb-3`}>
                                        <input type="text" className="form-control" id="pc_name" name="pc_name" minLength={5} placeholder="Business Name" required defaultValue={formField.pc_name} onKeyUp={(e) => setFormField(prevState => ({
                                            ...prevState, pc_name: e.target.value
                                        }))} />
                                        <div className="invalid-feedback">This pc name field is required.</div>
                                    </div>
                                    <div className="col-12 col-md-6 mb-3">
                                        <select className="form-select" aria-label="Default select example" value={formField.card_type} id="card_type" name="card_type" placeholder="Please Select card type" onChange={e => setFormField(prevState => ({...prevState, card_type: e.target.value}))}>
                                            <option disabled>Select Card type</option>
                                            <option value="both"> Both Side </option>
                                            <option value="front"> Only Front Side</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-6 mb-3">
                                        <input type="file" accept=".jpg,.jpeg,.png,.svg" className="form-control" id="business_logo" name="business_logo" title="Upload Image" onChange={async (e) => imageSet(e)} />
                                        <small className="text-muted pt-2 fw-bold" id="video_error">Please upload file with maximum size 2 Mb.</small>
                                    </div>

                                    <div className="col-12 col-md-6 mb-3">
                                        <input type="text" className="form-control" id="job_title" name="job_title" placeholder="Job Title" onKeyUp={(e) => setFormField(prevState => ({
                                            ...prevState, job_title: e.target.value
                                        }))} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-6 mb-3">
                                        <input type="text" className="form-control" id="name" name="name" placeholder="Name" defaultValue={formField.name} onKeyUp={(e) => setFormField(prevState => ({
                                            ...prevState, name: e.target.value
                                        }))} />
                                    </div>
                                    <div className="col-12 col-md-6 mb-3">
                                        <input type="email" className="form-control" id="contact_email" name="contact_email" placeholder="Email" defaultValue={formField.contact_email} onKeyUp={(e) => setFormField(prevState => ({
                                            ...prevState, contact_email: e.target.value
                                        }))} />
                                        <div className="invalid-feedback">Enter a valid email address.</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-6 mb-3">
                                        <input type="url" className="form-control" id="pc_url" name="pc_url" defaultValue={formField.pc_url} onKeyUp={(e) => setFormField(prevState => ({
                                            ...prevState, pc_url: e.target.value
                                        }))} placeholder="PC Url" />
                                        <div className="invalid-feedback">Enter pc url like e.g. https://example.com.</div>
                                    </div>
                                    <div className="col-12 col-md-6 mb-3 intl_phone">
                                        <input type="tel" className="form-control" id="phone" name="phone" placeholder="Phone" defaultValue={formField.phone ? `${formField.phone}` : `+1${formField.def_phone}`} onKeyUp={(e) => setFormField(prevState => ({
                                            ...prevState, phone: e.target.value
                                        }))} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-6 mb-3 intl_phone">
                                        <input type="tel" className="form-control" id="cell_phone" name="cell_phone" defaultValue={formField.cell_phone} onKeyUp={(e) => setFormField(prevState => ({
                                            ...prevState, cell_phone: e.target.value
                                        }))} placeholder="Cell Phone" />
                                    </div>
                                    <div className="col-12 col-md-6 mb-3 intl_phone">
                                        <input type="tel" className="form-control" id="help_desk_phone" name="help_desk_phone" placeholder="Help Desk Phone" defaultValue={formField.help_desk_phone} onKeyUp={(e) => setFormField(prevState => ({
                                            ...prevState, help_desk_phone: e.target.value
                                        }))} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <input type="text" className="form-control" id="tag_line" name="tag_line" placeholder="Tag Line" onKeyUp={(e) => setFormField(prevState => ({
                                            ...prevState, tag_line: e.target.value
                                        }))} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <input type="text" className="form-control" id="street" name="street" placeholder="Enter Street" defaultValue={formField.street} onKeyUp={(e) => setFormField(prevState => ({
                                            ...prevState, street: e.target.value
                                        }))} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-sm-6 mb-3">
                                        <input type="text" className="form-control" id="city" name="city" placeholder="Enter City" defaultValue={formField.city} onKeyUp={(e) => setFormField(prevState => ({
                                            ...prevState, city: e.target.value
                                        }))} />
                                    </div>
                                    <div className="col-12 col-sm-6 mb-3">
                                        <input type="text" className="form-control" id="state" name="state" placeholder="Enter State" defaultValue={formField.state} onKeyUp={(e) => setFormField(prevState => ({
                                            ...prevState, state: e.target.value
                                        }))} />
                                    </div>
                                </div>
                                <div className="mb-md-3 row">
                                    <div className="col-12 col-sm-6 mb-3">
                                        <input type="text" className="form-control" id="zip" placeholder="Enter Zip" minLength={4} maxLength={9} defaultValue={formField.zip} onKeyUp={(e) => setFormField(prevState => ({
                                            ...prevState, zip: e.target.value
                                        }))} />
                                    </div>
                                    <div className="col-12 col-sm-6 mb-3">
                                        <select className="form-select" aria-label="Default select example" id="font" name="font" placeholder="Please Select a Font" onChange={(e) => setFormField(prevState => ({
                                            ...prevState, font: e.target.value
                                        }))}>
                                            <option value="">Select Font</option>
                                            <option value="Arial">Arial</option>
                                            <option value="Verdana">Verdana</option>
                                            <option value="Averia Sans Libre">Averia Sans Libre</option>
                                            <option value="Righteous">Righteous</option>
                                            <option value="Sancreek">Sancreek</option>
                                            <option value="Alegreya SC">Alegreya SC</option>
                                            <option value="Merienda One">Merienda One</option>
                                            <option value="Aldrich">Aldrich</option>
                                            <option value="Nothing You Could Do">Nothing You Could Do</option>
                                        </select>
                                    </div>
                                </div>
                                <div>
                                    <h5 className="fw-bold">Colors</h5>
                                </div>
                                <div className="mb-3 row align-items-center">
                                    <div className="col-12 col-sm-6">
                                        <p className="fw-bold">Select logo BG</p>
                                        <ul className="list-inline list-unstyled">
                                            <li className="wh-30 rounded-circle bg-dark-blue list-inline-item" onClick={(e) => setFormField(prevState => ({
                                                ...prevState, logo_bg_color: '#001e3c'
                                            }))} ></li>
                                            <li className="wh-30 rounded-circle bg-dpurple list-inline-item" onClick={(e) => setFormField(prevState => ({
                                                ...prevState, logo_bg_color: '#3e0562'
                                            }))}></li>
                                            <li className="wh-30 rounded-circle bg-yellow list-inline-item" onClick={(e) => setFormField(prevState => ({
                                                ...prevState, logo_bg_color: '#ffc107'
                                            }))}></li>
                                            <li className="wh-30 rounded-circle bg-pink list-inline-item" onClick={(e) => setFormField(prevState => ({
                                                ...prevState, logo_bg_color: '#e91e63'
                                            }))}></li>
                                            <li className="list-inline-item more-colors rounded-circle text-center wh-30">
                                                <label className="small">
                                                    More
                                                    <input type="color" placeholder="More" className="wh-30 rounded-circle" onChange={(e) => setFormField(prevState => ({
                                                        ...prevState, logo_bg_color: e.target.value
                                                    }))} />
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <p className="fw-bold">Select Text Color</p>
                                        <ul className="list-inline list-unstyled">
                                            <li className="wh-30 rounded-circle bg-lpurple list-inline-item" onClick={(e) => setFormField(prevState => ({
                                                ...prevState, text_color: '#8080ff'
                                            }))} ></li>
                                            <li className="wh-30 rounded-circle bg-lgreen list-inline-item" onClick={(e) => setFormField(prevState => ({
                                                ...prevState, text_color: '#e0e07e'
                                            }))}></li>
                                            <li className="wh-30 rounded-circle bg-yellow list-inline-item" onClick={(e) => setFormField(prevState => ({
                                                ...prevState, text_color: '#ffc107'
                                            }))}></li>
                                            <li className="wh-30 rounded-circle bg-pink list-inline-item" onClick={(e) => setFormField(prevState => ({
                                                ...prevState, text_color: '#e91e63'
                                            }))}></li>
                                            <li className="list-inline-item more-colors rounded-circle text-center wh-30">
                                                <label className="small">
                                                    More
                                                    <input type="color" placeholder="More" className="wh-30 rounded-circle" onChange={(e) => setFormField(prevState => ({
                                                        ...prevState, text_color: e.target.value
                                                    }))} />
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <p className="fw-bold">Select BG Color</p>
                                        <ul className="list-inline list-unstyled">
                                            <li className="wh-30 rounded-circle bg-dark-blue list-inline-item" onClick={(e) => setFormField(prevState => ({
                                                ...prevState, bg_color: '#001e3c'
                                            }))}></li>
                                            <li className="wh-30 rounded-circle bg-dpurple list-inline-item" onClick={(e) => setFormField(prevState => ({
                                                ...prevState, bg_color: '#3e0562'
                                            }))}></li>
                                            <li className="wh-30 rounded-circle bg-yellow list-inline-item" onClick={(e) => setFormField(prevState => ({
                                                ...prevState, bg_color: '#ffc107'
                                            }))}></li>
                                            <li className="wh-30 rounded-circle bg-pink list-inline-item" onClick={(e) => setFormField(prevState => ({
                                                ...prevState, bg_color: '#e91e63'
                                            }))}></li>
                                            <li className="list-inline-item more-colors rounded-circle text-center wh-30">
                                                <label className="small">
                                                    More
                                                    <input type="color" placeholder="More" className="wh-30 rounded-circle" onChange={(e) => setFormField(prevState => ({
                                                        ...prevState, bg_color: e.target.value
                                                    }))} />
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <button type="button" className="btn btn-transparent cursor-pointer btn-pageof me-3" onClick={(e) => setFormField(prevState => ({
                                            ...prevState,
                                            bg_color: '#fff',
                                            logo_bg_color: '#fff',
                                            text_color: '#000'
                                        }))}>
                                            No Fill
                                        </button>
                                        <button type="button" className="btn btn-dark cursor-pointer" onClick={(e) =>saveCardDetail(e)} disabled={tinyloader} >
                                            {
                                                !tinyloader ? 'Next'
                                                    :
                                                    <div className="spinner-border spinner-border-sm mx-3" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                            }
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <div className="modal fade" tabIndex="-1" id="cardModal">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title fw-bold">Preview Business Card</h5>
                            <div>
                                <button type="button" className="btn btn-outline-danger me-2" id="close_modal" data-bs-dismiss="modal">Close</button>
                            </div>
                        </div>
                        <div className="modal-body">
                            {
                                <PreviewCard
                                    design={design}
                                    data={formField}
                                    saveCardDetail={saveCardDetail}
                                    setFormField={(e) => setFormField(e)}
                                    newImg={newImg}
                                    tinyloaderII={tinyloaderII}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" tabIndex="-1" id="orderDoneModal">
                <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                        <div className="modal-body">
                            <p>Thank you, Your Card Detail save with Order Id: <b>{oderId}</b></p>
                            <button type="button" className="btn btn-sm btn-success fw-semibold ms-auto float-end" data-bs-dismiss="modal">Ok</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BusinessCard