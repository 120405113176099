import React, { useState, useEffect } from "react";
import { createRoot } from 'react-dom/client'
import { USER_ACTIVITY_LOG } from "../../components/Service";
import * as Elements from "../../components/Elements";
import Datatables, { redrawDataTable } from '../../components/Datatables';
import { __echoText } from "../../components/Helper";

export default function UserActivity() {

    window.document.title = "User Activity";

    const [dt] = useState({
        dt_url: USER_ACTIVITY_LOG,
        dt_name: 'userActivity',
        dt_export: true,
        dt_order: [[0, 'desc']],
        dt_column: [
            { data: 'id', name: 'id', title: 'S.N', class: "text-nowrap minw-130px" },
            { data: 'first_name', name: 'users.first_name', title: 'User' },
            { data: 'action', name: 'action', title: 'action' },
            { data: 'login_time', name: 'login_time', title: 'Login Time (UTC)' },
            { data: 'logout_time', name: 'logout_time', title: 'Logout Time (UTC)' },
            { data: 'user_group_id', name: 'users.user_group_id', title: 'User Type' },
            { data: 'created_at', name: 'created_at', title: 'Date', class: "text-nowrap text-center", sortable: false, searchable: false, orderable: false }
        ],
        dt_column_defs: [
            {
                targets: 1,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>{__echoText(records.first_name)}</>)
                },
            },
            {
                targets: 2,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>{__echoText(records.action)}</>)
                },
            },
            {
                targets: 3,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>{__echoText(records.login_time)}</>)
                },
            },
            {
                targets: 4,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>{__echoText(records.logout_time)}</>)
                },
            },
            {
                targets: 5,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>{__echoText(records.user_group_id)}</>)
                },
            },
        ]
    });

    useEffect(() => {
        if (dt) {
            redrawDataTable(dt);
        }
    }, [dt]);

    return (
        <Elements.ListSection title="User Activity">
            <div className="col-sm-12">
                <div className="card border-0 shadow-sm rounded-10">
                    <div className="card-body">
                        <Datatables dt_name="userActivity" dataPageLength="15" />
                    </div>
                </div>
            </div>
        </Elements.ListSection>
    )
}