import { useEffect, useState } from "react";
import DataTables, { redrawDataTable } from "../../components/Datatables";
import * as Elements from "../../components/Elements";
import { ACTIVE_LOCATION } from "../../components/Service";

const ActiveLocation = () => {
    window.document.title = "Active Location";
    
    const [dt] = useState({
        dt_url: ACTIVE_LOCATION,
        dt_name: 'gmbList',
        dt_export: true,
        dt_column: [
            { data: 'location_alias', name: 'active_locations.location_alias', title: 'PC Number' },
            { data: 'location_name', name: 'active_locations.location_name', title: 'Business Name', class: "text-nowrap minw-130px" },
            { data: 'location_address', name: 'active_locations.location_address', title: 'Address', class: "text-nowrap minw-130px" },
            { data: 'city', name: 'active_locations.city', title: 'City', class: "text-nowrap minw-130px" },
            { data: 'state', name: 'active_locations.state', title: 'State', class: "text-truncate " },
            { data: 'zip_code', name: 'active_locations.zip_code', title: 'Zip Code', class: "text-truncate " },
        ]
    });

    useEffect(() => {
        if (dt) {
            redrawDataTable(dt);
        }
    }, [dt])

    return (
        <>
            <Elements.ListSection title='Active Location'>
                <div className="col-sm-12">
                    <div className="card border-0 shadow-sm rounded-10">
                        <div className="card-body">
                            <DataTables dt_name="gmbList" dataPageLength="15" />
                        </div>
                    </div>
                </div>
            </Elements.ListSection>
        </>
    );
}

export default ActiveLocation;