import React, { useEffect, useState } from 'react';
import { ButtonSpinner } from '../../components/Elements';
import { validateForm } from '../../components/Helper';
import { fetchData, UPDATE_ADMIN_PROFILE } from '../../components/Service';

function MyProfile(){
    window.document.title = "My Profile";

    const [tinyloader, setTinyloader] = useState(false);
    const [data, setData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
    })

    const updateProfile = (e) => {    
        let form = document.getElementById('profileForm')
        let data = new FormData(form);

        if(validateForm(e)) {
            setTinyloader(true);
            fetchData(UPDATE_ADMIN_PROFILE, 'POST', data, true, true, (res) => {
                setTinyloader(false);
                if (res.success) {
                    form.classList.remove('was-validated')
                    form.reset()
                } 
            });
        };
    }

    useEffect(() => {
        fetchData(UPDATE_ADMIN_PROFILE, 'GET', '', true, false, (res) => {
            if (res.records) {
                setData({
                    first_name: res.records.first_name,
                    last_name: res.records.last_name,
                    email: res.records.email,
                    phone: res.records.phone,
                })
            }
        });
    }, [])

    return(
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2 text-center text-md-start ms-xl-0 ms-md-4">
                            <h4 className="page-heading text-center">My Profile</h4>
                        </div>
                    </div>

                    <form className="row needs-validation" id="profileForm" noValidate>
                        <div className="col-lg-8 offset-lg-2">
                            <div className="row mx-0 bg-white rounded p-4 shadow-sm">
                                <div className="row mb-3">
                                    <label htmlFor="first_name" className="col-md-3 text-md-end col-form-label">First Name<strong className="text-danger">*</strong>:</label>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control" name="first_name" id="first_name" placeholder="First Name" autoComplete="Off" spellCheck={false} defaultValue={data.first_name} required />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="last_name" className="col-md-3 text-md-end col-form-label">Last Name<strong className="text-danger">*</strong>:</label>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control" name="last_name" id="last_name" placeholder="Last Name" autoComplete="Off" spellCheck={false} defaultValue={data.last_name} required />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="email_address" className="col-md-3 text-md-end col-form-label">Email Address<strong className="text-danger">*</strong>:</label>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control" name="email_address*" id="email_address" placeholder="Email Address" readOnly autoComplete="Off" defaultValue={data.email} spellCheck={false} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="phone" className="col-md-3 text-md-end col-form-label">Phone<strong className="text-danger">*</strong>:</label>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control" name="phone" placeholder="Phone" id="phone" autoComplete="Off" spellCheck={false} defaultValue={data.phone} required />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-11 text-end">
                                        <button type="button" className="btn btn-primary fw-medium" onClick={(e) => updateProfile(e)} disabled={tinyloader}>
                                            <ButtonSpinner load={tinyloader} btnName="Save" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}

export default MyProfile