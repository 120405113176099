import React, { useEffect, useState } from 'react';
import { fetchData, CONTACT_US } from "../../components/Service"
import { validateForm, scrollbarSetToTop } from "../../components/Helper"

function ContactUs() {
    window.document.title = "Amazio | Contact Us";
    const [tinyloader, setTinyloader] = useState(false);

    const saveContact = (e) => {
        let formData = new FormData(document.getElementById('contactForm'));
        var msg_error = document.getElementById('msg-error');
        if (validateForm(e, 'contactForm')) {
             
            if(formData.get('g-recaptcha-response')){
                msg_error.classList.add('d-none');
                setTinyloader(true)
                fetchData(CONTACT_US, 'POST', formData, true, true, (res) => {
                    if (res.success) {
                        let formId = document.getElementById('contactForm');
                        formId.classList.remove('was-validated');
                        formId.reset() 
                        setTinyloader(false)
                    }
                })
            }else{
                msg_error.classList.remove('d-none');
            }
        }
    }

    useEffect(() => {
        const script = document.createElement("script"); script.async = true;
        script.src = 'https://www.google.com/recaptcha/api.js';
        document.body.appendChild(script);
        scrollbarSetToTop();
    }, []);

    return (
        <>
            <section className="py-5" style={{ background: 'url(images/contactus2.jpg)', backgroundSize: 'cover' }}>
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-sm-7">
                            <h1 className="fw-bolder">What can we do to Amaze You?</h1>
                        </div>
                        <div className="col-8 col-sm-5 pt-4">
                            <img src="images/design-co.png" className="w-100" alt="contact" />
                        </div>
                    </div>
                </div>
            </section>
            
            <section className="py-5 bg-light">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 py-3">
                            <div className="row g-4">
                                <div className="col-6">
                                    <address className="bg-white shadow-sm m-0 minh-170 rounded overflow-hidden">
                                        <div className="bg-dark-blue text-center text-white p-2 fw-semibold">USA Office</div>
                                        <div className="d-flex p-3 align-items-start">
                                            <i className="bi bi-geo-alt fs-3 me-2 text-info"></i>
                                            <div className="text-common">1303 W Walnut Hill Ln, Ste 360 Irving TX 75038. USA</div>
                                        </div>
                                    </address>
                                </div>
                                <div className="col-6">
                                    <address className="bg-white shadow-sm m-0 minh-170 rounded overflow-hidden">
                                        <div className="bg-dark-blue text-center text-white p-2 fw-semibold">Mexico Office</div>
                                        <div className="d-flex p-3 align-items-start">
                                            <i className="bi bi-geo-alt fs-3 me-2 text-info"></i>
                                            <div className="text-common">
                                                Av. Prolongacion Americas 1600, Second Floor Col. Country Club<br />
                                                Guadalajara, Jalisco - 4461<br />
                                                Mexico
                                            </div>
                                        </div>
                                    </address>
                                </div>
                                <div className="col-6">
                                    <address className="bg-white shadow-sm m-0 minh-170 rounded overflow-hidden">
                                        <div className="bg-dark-blue text-center text-white p-2 fw-semibold">Canada Office</div>
                                        <div className="d-flex p-3 align-items-start">
                                            <i className="bi bi-geo-alt fs-3 me-2 text-info"></i>
                                            <div className="text-common">
                                                2233 Argentia Rd, Ste 302, 303<br />
                                                Mississauga, ON L5N 6A6
                                            </div>
                                        </div>
                                    </address>
                                </div>
                                <div className="col-6">
                                    <address className="bg-white shadow-sm m-0 minh-170 rounded overflow-hidden">
                                        <div className="bg-dark-blue text-center text-white p-2 fw-semibold">India Office</div>
                                        <div className="d-flex p-3 align-items-start">
                                            <i className="bi bi-geo-alt fs-3 text-info me-2"></i>
                                            <div className="text-common">
                                                203 MSR Legend<br />
                                                Poranki<br />
                                                AP, India 521137
                                            </div>
                                        </div>
                                    </address>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 py-3">
                            <form className="bg-white rounded shadow-sm p-3 p-lg-5 needs-validation" action="#" method="post" id="contactForm" noValidate
                            >
                                <h2 className="fw-bold fs-3 mb-3">Drop Us a line</h2>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control form-bottomline pe-5 ps-1" placeholder="name@example.com" name="name" id="name" required/>
                                    <label htmlFor="floatingInput" className="fw-semibold ps-0">Name<span className="text-danger w-100">*</span></label>
                                    <i className="bi bi-person-fill bi-1p3 pe-none end-0 position-absolute top-50 me-2 opacity-50"></i>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="email" className="form-control form-bottomline pe-5 ps-1" placeholder="name@example.com" name="email" id="email" required/>
                                    <label htmlFor="floatingInput" className="fw-semibold ps-0">Email<span className="text-danger">*</span></label>
                                    <i className="bi bi-envelope-fill bi-1p2 pe-none end-0 position-absolute top-50 me-2 opacity-50"></i>
                                </div>
                                <div className="form-floating mb-3">
                                    <textarea type="text" className="form-control form-bottomline minh-120 ps-1 pe-5" maxLength={350} placeholder="name@example.com" name="message" id="message" required></textarea>
                                    <label htmlFor="floatingInput" className="fw-semibold ps-0">Message<span className="text-danger">*</span></label>
                                    <i className="bi bi-pencil-fill bi-1p2 pe-none end-0 position-absolute top-0 mt-4 me-2 opacity-50"></i>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div className="">
                                        <div className="captcha_wrapper">
                                            <div className="g-recaptcha" id="g_recaptcha_contact" data-sitekey="6Lexnr8UAAAAAApT7UhSkNFBKTbkya4gnijVi9jb"> 
                                            </div>
                                            <span className="msg-error text-danger d-none" id="msg-error">Robot verification failed, please try again.</span>
                                        </div>
                                    </div>
                                    <button type="button" className="btn btn-primary px-4 h-25" onClick={(e) => saveContact(e)} disabled={tinyloader} id="submit-contact">
                                        {
                                            !tinyloader ? 'Submit'
                                                :
                                                <div className="spinner-border spinner-border-sm mx-3" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                        }
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default ContactUs