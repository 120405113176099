import React, { useContext } from "react";
import { Context } from "../../components/Context";

function Manage() {
    window.document.title = "Manage";

    const [context] = useContext(Context);

    const handleClick = () => {
        if (context && context.auth && context.auth.soci_api_token) {
            window.open(`https://www.socialmedia.amazio.com/admin/?api_key=${context.auth.soci_api_token}`);
        } else {
            window.open(`https://www.socialmedia.amazio.com`);
        }
    };

    return (
        <>
            <section className="admin-wrapper">
                <div className="px-4 py-5 px-md-5 text-center text-lg-start" style={{ backgroundColor: 'hsl(0, 0%, 96%)' }}>
                    <div className="container">
                        <div className="row gx-lg-5 align-items-center">
                            <div className="col-lg-6 mb-5 mb-lg-0">
                                <h1 className="my-5 display-3 fw-bold ls-tight">
                                    Social Media Manager
                                </h1>
                            </div>

                            <div className="col-lg-6 mb-5 mb-lg-0">
                                <div className="card">
                                    <div className="card-body py-5 px-md-5 text-center">
                                        <button className="btn btn-primary btn-block mb-4" onClick={handleClick}>Go to Socialmedia Amazio</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Manage