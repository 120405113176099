import React from "react";
import { Link } from "react-router-dom";

function Imprints() {
    window.document.title = "User Imprints";

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2 text-center text-md-start ms-xl-0 ms-md-4">
                            <h4 className="page-heading">Select Category</h4>
                        </div>
                    </div>
                    <div className="row pt-4">
                        <div className="col-md-3">
                            <Link to='../ced-portal' className="border rounded d-block bg-white text-decoration-none mb-5">
                                <div className="ratio ratio-16x9">
                                    <img alt="objpos-top" className="objfit-cover objpos-top" src="images/flyer-all_phase.jpg"/>
                                </div>
                                <h4 className="text-blue py-3 border-top ps-3 fs-4 fw-medium">CED Custom Portal</h4>
                            </Link>
                        </div>
                        <div className="col-md-3">
                            <Link to='../arclight' className="border rounded d-block bg-white text-decoration-none mb-5">
                                <div className="ratio ratio-16x9">
                                    <img alt="objpos-top" className="objfit-cover objpos-top" src="images/pdfview.jpg"/>
                                </div>
                                <h4 className="text-blue py-3 border-top ps-3 fs-4 fw-medium">Arclight</h4>
                            </Link>
                        </div>
                        <div className="col-md-3">
                            <Link to='../business-card' className="border rounded d-block bg-white text-decoration-none mb-5">
                                <div className="ratio ratio-16x9">
                                    <img alt="objpos-top" className="objfit-cover objpos-top" src="images/business-card.jpg"/>
                                </div>
                                <h4 className="text-blue py-3 border-top ps-3 fs-4 fw-medium">Business cards</h4>
                            </Link>
                        </div>
                        <div className="col-md-3">
                            <Link to='../flyer' className="border rounded d-block bg-white text-decoration-none mb-5">
                                <div className="ratio ratio-16x9">
                                    <img alt="objpos-top" className="objfit-cover objpos-top" src="images/vmi-flyer.png"/>
                                </div>
                                <h4 className="text-blue py-3 border-top ps-3 fs-4 fw-medium">VMI Flyer</h4>
                            </Link>
                        </div>
                        <div className="col-md-3">
                            <a href="images/QuickBooks Flyer.pdf" download="QuickBooks Flyer.pdf" className="border rounded d-block bg-white text-decoration-none mb-5">
                                <div className="ratio ratio-16x9">
                                    <img alt="objpos-top" className="objfit-cover objpos-top" src="images/quickbook-flyer.png"/>
                                </div>
                                <h4 className="text-blue py-3 border-top ps-3 fs-4 fw-medium">Quickbook Flyer</h4>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Imprints